<template>
  <div class="setJump_page">
    <div v-if="visible" class="modal_2">
      <!-- <div class="modal_2"> -->
      <div class="add add_pof">
        <div class="add_form add_pof">

          <div class="add_form_title">
            选择内部链接
            <a-icon @click="handleCancel" class="add_form_close" type="close" />
          </div>

          <div class="searchBox">
            <a-input-search placeholder="请输入名称" v-model="keywordStr" enter-button @search="onSearch" />
            <a-button @click="showModal2" type="primary">
              新增页面
            </a-button>
          </div>

          <div class="list_box">
            <a-table :scroll="{ x: 600, y: 255 }" :loading="loading" :pagination="ipagination" @row-click="columnsClick"
              @change="handlePageChange" :rowKey="record=>record.id" :columns="columns" :data-source="data">

              <span slot="status" slot-scope="text, record">
                <a-radio @click="columnsClick(record)" :checked='jumpForm.meeting_view_id==record.id'> </a-radio>
              </span>

              <span class="meeting_name text2" slot="meeting_name" slot-scope="text, record">
                <span class="">{{ record.title?record.title: '未填' }} </span>
              </span>

              <span class="begin_time text2" slot="content" slot-scope="text, record">
                <span class="">{{ record.content?record.content: '未填' }} </span>
              </span>

            </a-table>
          </div>

          <div class="share_bot">
            <a-button @click="handleCancel">取消</a-button>
            <a-button @click='handleOk' type="primary">
              确定
            </a-button>
          </div>

        </div>
      </div>
    </div>

    <addModal @getList='onSearch' ref="addModal"></addModal>
  </div>
</template>

<script>
import { setViewList, getViewList } from '@/api/meetApi.js';
import addModal from '@/views/menuList/components/Modal.vue';

const columns = [
  {
    title: "",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    key: "status",
    align: "left",
  },
  {
    title: "页面名称",
    dataIndex: "meeting_name",
    scopedSlots: { customRender: "meeting_name" },
    key: "meeting_name",
    align: "left",
  },
  {
    title: "类型",
    dataIndex: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    key: "begin_time",
    align: "left",
  },
  {
    title: "内容",
    dataIndex: "content",
    scopedSlots: { customRender: "content" },
    key: "content",
    align: "left",
  },
];

export default {
  components: { addModal },
  name: "setJump",
  data() {
    return {
      itemList: [],
      visible: false,
      confirmLoading: false,
      visible2: false,
      confirmLoading2: false,
      jumpForm: {
        next_meeting_view_title: '',
        meeting_view_id: '',
        url: ''
      },
      // 
      // 
      // 
      loading: false,
      data: [],
      columns,
      keywordStr: '',
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    };
  },
  methods: {
    //表格改变时触发
    handlePageChange(page) {
      console.log(page);
      this.ipagination = page;
      this.getPageList({ page: page.current, per_page: page.pageSize, keyword: this.keywordStr, meeting_id: this.$route.query.meeting_id });
    },
    columnsClick(record) {
      this.jumpForm.next_meeting_view_title = record.title
      this.jumpForm.meeting_view_id = record.id;
    },
    close(e) {
      this.checkMenu_click = false
      this.showNum = false
      this.share_F = 1
    },
    onSearch() {
      this.getPageList({ page: 1, per_page: this.ipagination.pageSize, keyword: this.keywordStr, meeting_id: this.$route.query.meeting_id });
    },
    async getPageList(sendData) {
      let data = await getViewList(sendData)
      this.itemList = data.payload.data
      this.ipagination.total = data.payload.page.total
      this.data = data.payload.data
      console.log(data)
    },
    showModal(data) {
      console.log(data)
      this.jumpForm.meeting_view_id = data.meeting_view_id
      this.jumpForm.url = data.url
      this.getPageList({ meeting_id: this.$route.query.meeting_id })
      this.visible = true;
    },
    handleOk(e) {
      this.$emit('jumpForm', this.jumpForm)
      this.visible = false;
      this.confirmLoading = false;
      this.$message.success('设置成功！')
      this.clear()
    },
    handleCancel(e) {
      var _this = this;
      _this.visible = false;

    },

    showModal2() {
      this.$refs.addModal.showModal();
    },
    clear() {
      this.jumpForm = {
        next_meeting_view_title: '',
        meeting_view_id: '',
        url: ''
      }
    },
    handleCancel2(e) {
      var _this = this;
      _this.visible2 = false;
      _this.clear();
      this.visible2 = false;
    },
  },
};
</script>
<style lang="less" scoped>
.jumpWhere {
  display: flex;
  line-height: 32px;
  margin-bottom: 12px;
  span {
    // margin-right: 10px;
  }
  input {
    flex: 1;
  }
}
.searchBox {
  height: 32px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.searchBox .ant-input-search {
  width: 240px;
}
.list_box {
  padding: 0 20px;
  max-height: 365px;
}
:deep(.ant-calendar-picker) {
  width: 100%;
}

.jumpWhere {
  input {
    border-radius: 4px 0 0 4px;
  }
  button {
    border-radius: 0 4px 4px 0;
  }
}
.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 1000px;
  height: 550px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}

.h625 {
  height: 625px;
}
.add_form_title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px 0px 20px 0px;
  margin: 0 20px;
  flex: 1;
}

.add_formBoxTwo {
  padding: 21px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.add_formBox_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f2;
  padding-bottom: 20px;
}
.add_formBox_name {
  text-align: right;
  padding-right: 16px;
  width: 100px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.add_formBox_inp {
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  flex: 1;
  margin: 0 10px 0 0px;
}
.begin_time,
.meeting_name {
  max-width: 400px;
  width: 60%;
}
.add_formBox_btn {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  margin-right: 16px;
  color: #1890ff;
}
.add_formBox_btn:last-child {
  margin-right: 20px;
}
.share_bot button {
  margin-left: 12px;
}
.share_bot {
  border-top: 1px solid #e3e4e8;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
:deep(.ant-table-thead) {
  height: 48px;
  tr {
    th {
      background: #f3f6f9;
      height: 48px;
      padding: 0 16px;
    }
  }
}
:deep(.ant-table-tbody) {
  height: 48px;
  tr {
    td {
      height: 59px;
    }
  }
}
.setJump_page :deep(.title) {
  display: none;
}
</style>