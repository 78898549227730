<template>
  <div v-if="swiperIf" :key="index">
    <div class="swiper-container" :id='"swiper-container"+index'>
      <div class="swiper-wrapper">
        <div v-for="(item,index) in options.data.menuArr" :key="index" class="swiper-slide swiper-slide1">
          <img :src="item.image" alt="">
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>

      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div> -->
      <!-- <div class="swiper-button-next"></div> -->
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入

export default {
  name: "Swiper",
  props: ["index"],
  data() {
    return {
      swiperIf: true,
      form: {},
    };
  },
  computed: {
    ...mapGetters(["workPlace"]),
    ...mapGetters(["okForm"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.swiper.data };
    },
  },
  created() {
    this.form = this.options;
  },
  mounted() {
    this.beginSwiper();
  },
  //
  //
  // 监听okForm,处理数据变化时swiper不刷新以及刷新异常问题
  watch: {
    okForm: {
      handler(newVal, oldVal) {
        var _this = this;
        this.form = this.options;
        this["swiper-container" + _this.index].destroy(false);
        this.$nextTick(() => {
          _this.beginSwiper();
          _this.$forceUpdate();
        });
      },
      deep: true,
    },
  },

  methods: {
    beginSwiper() {
      let _this = this;
      this["swiper-container" + _this.index] = new Swiper(
        "#swiper-container" + _this.index,
        {
          autoplay: {
            delay: _this.options.data.autoTimer,
          }, //可选选项，自动滑动
          loop: true,
          preventClicks: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            type: _this.options.data.paginationStyle,
          },
          navigation: {
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1",
          },
          observer: true,
          observeParents: true,
        }
      );
    },
  },
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
}
.swiper-slide {
  text-align: center;
  /* line-height: 400px; */
  min-height: 100px;
  color: #b0b0b0;
}
img {
  width: 100%;
}
</style> 