<template>
  <div v-if="options&&options.typeof=='单选框'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-radio-group :disabled="options.disabled" v-model="formwork[index].options.value">
        <a-radio v-for="(item, index) in options.optionsArray" :key="index" :label="item" :value="item">
          {{ item }}
        </a-radio>
        <a-radio v-if="options.optionsArray && options.optionsArray.length === 0" :label="3">
          请于表单工作区完善表单选项
        </a-radio>
      </a-radio-group>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getSelectOptionData } from "@/api/form.js";
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  props: ["index"],
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"])
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() { 
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.radioOptions };
    },
    optionRules: {
      get() {
        let ruleArray = [];
        if (this.options.require) {
          ruleArray.splice(0, 0, {
            required: true,
            message: this.options.requireMsg
              ? this.options.requireMsg
              : "该内容不能为空",
            trigger: "change"
          });
        }
        if (this.options.reg !== "") {
          ruleArray.push({
            validator: (rule, value, callback) => {
              if (!eval(this.options.reg).test(value)) {
                callback(new Error(this.options.regMsg || ""));
                return;
              }
              callback();
            },
            trigger: "change"
          });
        }
        return ruleArray;
      }
    },
    optionsSelectType: {
      get() {
        return this.options.optionsType;
      }
    },
    optionsOptions: {
      get() {
        return this.options.optionsZd;
      }
    }
  },
  watch: {
    optionRules() {
      this.keyValue += 1;
    },
    optionsSelectType() {
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: "optionsArray",
        value: []
      });
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: "optionsZd",
        value: ""
      });
    },
    async optionsOptions() {
      if (!this.options.optionsType && this.options.optionsArray.length === 0) {
        let optionsArr = await getSelectOptionData(this.options.optionsZd);
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "optionsArray",
          value: optionsArr
        });
      } else if (this.options.optionsType) {
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "optionsArray",
          value: []
        });
      }
    }
  }
};
</script> 