<template>
  <div class="setForm">
    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">表单设置</span></span>
    </p>

    <div v-show='btnTypeNum==1' class="msg_box">
      <div class="login_R_btnBox">
        <a-button @click="getType(item,index)" v-for="(item,index) in btnList" :key="index"
          :type="checkType==item.name?'primary':''" :ghost='checkType==item.name'>
          {{item.label}}
        </a-button>
      </div>
      <inputFormWork :checkTypeIndex='checkTypeIndex' key="Inpute" v-if='checkType=="Inpute"' />
      <textareaFormWork :checkTypeIndex='checkTypeIndex' key="Textarea" v-if='checkType=="Textarea"' />
      <radioFormWork :checkTypeIndex='checkTypeIndex' key="Radio" v-if='checkType=="Radio"' />
      <checkBoxFormWork :checkTypeIndex='checkTypeIndex' key="CheckBox" v-if='checkType=="CheckBox"' />
      <selectFormWork :checkTypeIndex='checkTypeIndex' key="Selecte" v-if='checkType=="Selecte"' />
      <timeFormWork :checkTypeIndex='checkTypeIndex' key="TimePicker" v-if='checkType=="TimePicker"' />
      <cityFormWork :checkTypeIndex='checkTypeIndex' key="City" v-if='checkType=="City"' />
      <articleFormWork :checkTypeIndex='checkTypeIndex' key="Article" v-if='checkType=="Article"' />
      <upload_fileFormWork :checkTypeIndex='checkTypeIndex' key="upload_file" v-if='checkType=="upload_file"' />
    </div>
    <div v-show='btnTypeNum==2' class="msg_box pd24">
      <p class="imgList_p">
        <span style="margin-right:12px">表单标志<span class="imgList_p_log">（不显示）</span></span>
        <span style="flex:1">
          <a-input @input="chnageVuex" v-model="form.form_mark" placeholder="请输入" />
        </span>
      </p>
      <p class="imgList_p">
        <span style="margin-right:12px">表单标题</span>
        <span style="flex:1">
          <a-input @input="chnageVuex" v-model="form.form_title" placeholder="请输入" />
        </span>
      </p>
      <p class="imgList_p">
        <span style="margin-right:12px">按钮文字</span>
        <span style="flex:1">
          <a-input @input="chnageVuex" v-model="form.button_text" placeholder="请输入" />
        </span>
      </p>
      <pageCss :propCss="form.data.pageCss" @giveCss='giveCss'></pageCss>
    </div>

    <!-- <a-button @click="closePage" class="showSetForm" type="primary">
      编辑表单
    </a-button>

    <pageCss :propCss="form.data.pageCss" @giveCss='giveCss'></pageCss>

    <div v-if="showForm" class="setForm_content">
      <div class="setForm_page">
        <FormWorker v-if="showForm" @closeNo="closeNo" @closeForm='closeForm'></FormWorker>
      </div>
    </div> -->

  </div>
</template>

<script>
import pageCss from './pageCss.vue'
import Vue from "vue";
import FormWorker from '@/views/form/FormWorker.vue'
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["index"],
  components: { FormWorker, pageCss },
  data() {
    return {
      btnTypeNum: 1,
      showForm: false,
      checkType: '',
      checkTypeIndex: '',
      btnList: [
        {
          type: "inputWork",
          name: "Inpute",
          label: "单行文本",
          icon: "account-book"
        },
        {
          type: "textareaWork",
          name: "Textarea",
          label: "多行文本",
          icon: "account-book"
        },
        {
          type: "radioWork",
          name: "Radio",
          label: "单选框",
          icon: "dashboard"
        },
        {
          type: "checkBoxWork",
          name: "CheckBox",
          label: "多选框",
          icon: "dashboard"
        },
        {
          type: "selectWork",
          name: "Selecte",
          label: "下拉框",
          icon: "deployment-unit"
        },
        {
          type: "timePickerWork",
          name: "TimePicker",
          label: "日期",
          icon: "calendar"
        },
        {
          type: "cityWork",
          name: "City",
          label: "城市",
          icon: "setting"
        },
        {
          type: "articleWork",
          name: "Article",
          label: "图文",
          icon: "dashboard"
        },
        {
          type: "upload_fileWork",
          name: "upload_file",
          label: "文件上传",
          icon: "dashboard"
        },
      ],
    }
  },
  created() {
    const requireComponent1 = require.context(
      "../form_two",
      false,
      /\.vue$/
    );
    requireComponent1.keys().forEach(fileName => {
      let config1 = requireComponent1(fileName);
      let fimeNameChange1 = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName1 =
        fimeNameChange1.charAt(0).toLowerCase() +
        fimeNameChange1.slice(1) +
        "Work";
      Vue.component(componentName1, config1.default || config1);
    });

    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    // if (this.form.data.menuArr[0]) {
    //   this.form.data.menuArr[0].showBg = true
    //   this.checkTypeIndex = 0

    //   this.chnageVuex();
    // }
  },
  computed: {
    ...mapGetters(["okForm",
      "inputOptions",
      "selectOptions",
      'textareaOptions',
      "radioOptions",
      "sliderOptions",
      "checkBoxOptions",
      "timeOptions",
      'articleOptions',
      'cityOptions',
      "formwork",
      'upload_fileOptions',
      "switchOptions",
      "inputNumberOptions"]),
  },
  watch: {
    okForm: {
      handler(newValue, oldValue) {
        newValue[this.index].options.data.menuArr.forEach((item, index) => {
          if (item.showBg) {
            this.checkType = item.name
            this.checkTypeIndex = index
            this.$forceUpdate()
          }
        })
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    closePage() {
      this.showForm = true
    },
    chnageVuex() {
      console.log(this.form.button_text)
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
      this.$emit("changeWindow");
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },
    closeForm() {
      this.form.data.menuArr = this.$store.state.formwork.formwork;
      this.chnageVuex();
      this.showForm = !this.showForm
      // this.$store.state.formwork.formwork = [] 
    },
    closeNo() {
      this.showForm = false
    },
    btnTypeFn(num) {
      this.btnTypeNum = num;
    },
    getType(item, index) {
      this.checkType = item.name;
      let newOption = {};
      switch (this.checkType) {
        case "Inpute":
          newOption = JSON.parse(JSON.stringify(this.inputOptions));
          break;
        case "Textarea":
          newOption = JSON.parse(JSON.stringify(this.textareaOptions));
          break;
        case "Selecte":
          newOption = JSON.parse(JSON.stringify(this.selectOptions));
          break;
        case "Slider":
          newOption = JSON.parse(JSON.stringify(this.sliderOptions));
          break;
        case "Radio":
          newOption = JSON.parse(JSON.stringify(this.radioOptions));
          break;
        case "TimePicker":
          newOption = JSON.parse(JSON.stringify(this.timeOptions));
          break;
        case "Switche":
          newOption = JSON.parse(JSON.stringify(this.switchOptions));
          break;
        case "CheckBox":
          newOption = JSON.parse(JSON.stringify(this.checkBoxOptions));
          break;
        case "Article":
          newOption = JSON.parse(JSON.stringify(this.articleOptions));
          break;
        case "City":
          newOption = JSON.parse(JSON.stringify(this.cityOptions));
          break;
        case "upload_file":
          newOption = JSON.parse(JSON.stringify(this.upload_fileOptions));
          break;
      }
      this.form.data.menuArr.forEach(item => {
        item.showBg = false
      })
      item.showBg = true
      this.$set(item, "options", JSON.parse(JSON.stringify(newOption)));
      this.form.data.menuArr.push(JSON.parse(JSON.stringify(item)));
      this.chnageVuex();
    },
  }
}
</script>

<style lang="less" scoped>
.pd24 {
  padding: 24px;
  padding-top: 9px;
}
.imgList_p_log {
  font-weight: 400;
  font-size: 12px;
  color: #7f7f7f;
}
.login_R_btnBox {
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;
  padding-right: 16px;
  margin-top: 24px;
  button {
    padding: 0;
    width: calc(33% - 8px);
    font-weight: 500;
    font-size: 12px;
    margin-right: 8px;
    font-size: 12px;
    background: rgba(34, 34, 34, 0.02);
    height: 36px;
    margin-bottom: 8px;
  }
}
.imgList_p {
  display: flex;
  line-height: 32px;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  margin-bottom: 12px;
  flex-direction: column;
}
.setForm {
  // padding: 24px;
}
.msg_box {
  // padding: 24px;
}
.showSetForm {
  margin-bottom: 24px;
}
.setForm_content {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.setForm_page {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80%;
  margin: auto;
  height: 80%;
  overflow: auto;
  background-color: #fff;
  z-index: 9999;
}
</style>  
<style>
.setForm_page .form-work-left {
  padding-left: 24px;
}
.setForm_page .closePage {
  display: initial;
}
.setForm_page .noShow {
  display: none;
}
</style>