import request from '@/utils/request'

/**
 * 登录接口
 * @param {*} data 请求对象 需要 mobile 和 password
 * @returns Promise
 */
export function login(data) {
  return request({
    method: 'post',
    url: '/user/login',
    data
  })
}

 