<template>
  <div v-if="options&&options.typeof=='slider'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-slider v-model="formwork[index].options.value" :vertical="options.vertical" :height="options.height + 'px'"
        :min="options.min" :max="options.max" :show-tooltip="options.showTooltip" :disabed="options.disabed"
        :step="options.step" :show-stops="options.showStops" :show-input="options.showInput"
        :range="options.range"></a-slider>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() { 
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.slider };
    }
  },
  props: ["index"]
};
</script> 