<template>
  <div class="editor">
    <TinymceEditor v-model="myValue" :init="completeSetting" />
  </div>
</template>

<script>
import tinymce from "tinymce/tinymce";
import TinymceEditor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
// import "tinymce/icons/default/icons";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/preview";
import "tinymce/plugins/table";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/preview";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize";

export default {
  name: "Editor",
  components: {
    TinymceEditor,
  },
  props: {
    form: { type: Object },
    value: {
      type: String,
      default: "",
    },
    setting: {
      type: Object,
      default: () => { },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSetting: {
        language_url:
          process.env.NODE_ENV == "development"
            ? "tinymce/langs/zh-Hans.js"
            : "/setmeet/tinymce/langs/zh-Hans.js", // 语言包的路径搭配线上后端使用需要加上/setmeet
        language: "zh-Hans", //语言 
        body_class: 'my_class',
        content_style: "img,.my_class {overflow-y: scroll !important; width: unset !important;height: unset !important;max-width: 100% !important;}",
        skin_url:
          process.env.NODE_ENV == "development"
            ? "tinymce/skins/ui/oxide"
            : "/setmeet/tinymce/skins/ui/oxide", // skin路径
        branding: false, //是否禁用“Powered by TinyMCE”
        menubar: false, //顶部菜单栏显示
        content_css:
          process.env.NODE_ENV == "development"
            ? "tinymce/skins/content/default/content.css"
            : "/setmeet/tinymce/skins/content/default/content.css",
        min_height: 250,
        selector: "textarea",
        plugins:
          "autolink autoresize fullscreen hr image imagetools insertdatetime link lists media preview table wordcount code searchreplace",
        toolbar:
          `code undo redo restoredraft  cut copy paste pastetext  forecolor backcolor bold italic underline strikethrough 
          link codesample  alignleft aligncenter alignright alignjustify outdent indent   styleselect  bullist 
          numlist  blockquote subscript fontsizeselect superscript removeformat   table image media charmap hr pagebreak
          insertdatetime code fullscreen preview`,
        fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
        branding: false,
        menubar: false,
        toolbar_mode: "sliding",
        // images_upload_url: "http://localhost:8080/api/meeting/upload/file",
        // 使用自定义上传4
        images_upload_handler: function (blobInfo, succFun, failFun) {
          var xhr, formData;
          var file = blobInfo.blob();//转化为易于理解的file对象
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;


          let uploadStr = window.location.origin + '/apis/setmeet/meeting/upload/file'
          xhr.open('POST', uploadStr);
          xhr.onload = function () {
            var json;
            if (xhr.status != 200) {
              failFun('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
            if (!json || typeof json.payload.file_url != 'string') {
              failFun('Invalid JSON: ' + xhr.responseText);
              return;
            }
            succFun(json.payload.file_url);
          };
          formData = new FormData();
          formData.append('file', file, file.name);//此处与源文档不一样
          xhr.send(formData);
        },
        insertdatetime_formats: [
          "%Y年%m月%d日",
          "%H点%M分%S秒",
          "%Y-%m-%d",
          "%H:%M:%S",
        ],
      },
      myValue: this.form.data.desc,
    };
  },
  computed: {
    completeSetting() {
      return Object.assign(this.defaultSetting, this.setting);
    },
  },
  watch: {
    myValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.myValue = newValue;
    },
  },
  mounted() {
    tinymce.init({});
    console.log(this.myValue)
    this.$emit("input", this.myValue);// 因为使用的是watch监听然后提交给父组件，点开直接关闭会导致原有的内容空白。所以这里需要手动触发一下。
    console.log(process.env.NODE_ENV);
  },
};
</script>
<style>
.editor {
  height: 100%;
}
.tox-tinymce {
  height: 100% !important;
  min-height: 200px;
}
.my_class {
  background-color: red;
  width: unset !important;
  height: unset !important;
  width: 100% !important;
}
</style>