<template>
  <div class="swiperForm">
    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check:btnType==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check:btnType==2}">样式调整</span></span>
    </p>

    <div v-show="btnType==1" class="imgList">

      <div class="swiper_attention">
        <p>添加图片：建议不超过10张，可拖拽调整顺序</p>
        <p>建议宽度：请保持每张图片宽高比一致。</p>
      </div>

      <draggable class="draggableBox" animation="1000" @end="endEventFN" v-model="form.data.menuArr">
        <div v-for="(item,index) in form.data.menuArr" :key="index" :id="index+'choiceImg'" class="choiceImg">
          <div @click="close(item,index)" class="imgBox_close close">
            <img src="@/assets/auto_img/icon_delete@2x.png" alt="">
          </div>
          <div class="upBox">
            <upload :key="index+'img'" :id="index+'img'" class="upBox_up" :ref="'upBox_up'+index" :height='"130px"'
              :width='"100%"' @uploadOk='uploadOk(index,$event)' :image='item.image'></upload>
          </div>
          <p class="set_jump">
            <span>链接</span>
            <a-radio-group v-model="item.is_outside_url" :default-value="1">
              <a-radio :value="1">
                自定义
              </a-radio>
              <a-radio :value="2">
                内部链接
              </a-radio>
            </a-radio-group>
          </p>
          <div class="set_jump_form">
            <div class="set_jump_my" v-show="item.is_outside_url==1">
              <img v-if="item.url" @click="clearItemUrl(item)" src="@/assets/auto_img/删除_delete@2x.png" alt="">

              <a-popover v-if="item.url" placement="topLeft" title="打开链接">
                <template slot="content">
                  <div style="width:150px;" class="text"> <a :href="item.url" target="_blank"
                      rel="noopener noreferrer">{{item.url}}</a>
                  </div>
                </template>
                <a-textarea @change="chnageIs_outside_url(index)" v-model="item.url" placeholder="请输入自定义链接"
                  :auto-size="{ minRows: 2, maxRows: 6 }" />
              </a-popover>

              <a-textarea v-if="!item.url" @change="chnageIs_outside_url(index)" v-model="item.url"
                placeholder="请输入自定义链接" :auto-size="{ minRows: 2, maxRows: 6 }" />

            </div>
            <div class="set_jump_other" v-show="item.is_outside_url==2">

              <a-popover v-if="item.meeting_view_id" placement="topLeft" title="打开链接">
                <template slot="content">
                  <div style="width:150px;" class="text"> <a :href="item.url" target="_blank"
                      rel="noopener noreferrer">{{item.url}}</a>
                  </div>
                </template>
                <div class="set_jump_btn pointer" @click="setJumpFn(index)" type="link">
                  <span class="text"
                    v-if="item.next_meeting_view_title">{{item.next_meeting_view_title||item.url}}</span>
                  <span v-if="!item.next_meeting_view_title">选择链接</span>
                </div>
              </a-popover>

              <div v-if="!item.meeting_view_id" class="set_jump_btn pointer" @click="setJumpFn(index)" type="link">
                <span class="text" v-if="item.next_meeting_view_title">{{item.next_meeting_view_title||item.url}}</span>
                <span v-if="!item.next_meeting_view_title">选择链接</span>
              </div>

            </div>
          </div>
        </div>
      </draggable>

      <a-button size="large" @click='addBanner' class="addBanner" block type="link">
        添加轮播图
      </a-button>

    </div>
    <div v-show="btnType==2" class="cssChange">
      <div>
        <p>圆点样式</p>
        <a-radio-group v-model="form.data.paginationStyle" @change="paginationStyleFn()">
          <a-radio :value="'bullets'">
            样式一
          </a-radio>
          <a-radio :value="'fraction'">
            样式二
          </a-radio>
          <a-radio :value="'progressbar'">
            样式三
          </a-radio>
        </a-radio-group>
      </div>
      <div>
        <p>轮播间隔</p>
        <a-radio-group v-model="form.data.autoTimer" @change="autoTimerFn()">
          <a-radio :value="2000">
            2s
          </a-radio>
          <a-radio :value="3000">
            3s
          </a-radio>
          <a-radio :value="5000">
            5s
          </a-radio>
        </a-radio-group>
      </div>
    </div>

    <setJump @jumpForm='jumpForm' ref="setJump" />
  </div>
</template>

<script>

import draggable from "vuedraggable";
import upload from "@/components/upload.vue";
import setJump from "./setJump.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { setJump, upload, draggable },
  name: "swiperForm",
  props: ["index"],
  data() {
    return {
      is_outside_url: 1,
      setJumpNum: '',
      btnType: "1",
      form: {},
      imgList: []
    };
  },
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    this.imgList = JSON.parse(JSON.stringify(this.form.data.menuArr))
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    addBanner() {
      this.form.data.menuArr.push({
        image: "",
        title: "企业门户标题",
        subtitle: "这里填写企业门户副标题",
        desc: "这里可以填写相关的表述，简单介绍企业门户内容",
        is_outside_url: 1,
        contentShow: false,
        link: {
          show: false,
          is_outside_url: 1,
          src: "",
          text: "查看",
        },
        imageLink: "",
        imageHeight: 140,
        imageWidth: 375,
        center: false,
        is_outside_url: 1,
      });
      this.chnageVuex();
    },
    btnTypeFn(num) {
      this.btnType = num;
    },
    endEventFN(argument) {
      this.$refs['upBox_up' + argument.newIndex][0].reloadFn()
      this.$refs['upBox_up' + argument.oldIndex][0].reloadFn()
      this.chnageVuex();
    },
    paginationStyleFn(str) {
      // this.form.data.paginationStyle = str;
      this.chnageVuex();
    },
    autoTimerFn(str) {
      // this.form.data.autoTimer = str;
      this.chnageVuex();
    },
    close(item, index) {
      if (this.form.data.menuArr.length == 1) {
        return this.$message.error("至少保留一条数据！");
      }
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
    },

    clearItemUrl(item) {
      item.url = ''
      item.next_meeting_view_title = ''
      item.meeting_view_id = ''
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.menuArr[Number(this.setJumpNum)].next_meeting_view_title = data.next_meeting_view_title
      this.form.data.menuArr[Number(this.setJumpNum)].meeting_view_id = data.meeting_view_id
      this.form.data.menuArr[Number(this.setJumpNum)].url = 'https://www.cndmcloud.com/home/#/?view_id=' + data.meeting_view_id
      this.setJumpNum = ''
      this.chnageVuex();
    },
    chnageIs_outside_url(index) {
      this.form.data.menuArr[index].next_meeting_view_title = ''
      this.form.data.menuArr[index].meeting_view_id = ''
      this.chnageVuex();
    },
    setJumpFn(index) {
      this.setJumpNum = index
      this.$refs.setJump.showModal(this.form.data.menuArr[Number(this.setJumpNum)]);
    },
    uploadOk(index, value) {
      console.log(this.form);
      this.form.data.menuArr[index].image_id = value.payload.id;
      this.form.data.menuArr[index].image = value.payload.path;
      console.log(this.form.data.menuArr[index])
      this.chnageVuex();
    },
  },
};
</script>

<style lang="less" scoped>
.swiperForm {
  height: calc(100% - 50px);
}
.a-button-group {
  width: 100%;
  padding: 0 24px;
}

.imgList .choiceImg .set_jump_my {
  position: relative;
  textarea {
    padding-right: 20px;
    text-align: justify;
  }
  img {
    z-index: 2;
    position: absolute;
    width: 16px;
    right: 8px;
    bottom: 0;
    top: 0;
    margin: auto;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }
}
.imgList {
  .swiper_attention {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #86909d;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    p {
      margin-bottom: 10px;
    }
  }
  padding: 24px;
  .choiceImg {
    padding: 12px;
    margin-top: 12px;
    min-height: 100px;
    border-radius: 2px;
    border: 1px solid #e5e6eb;
    position: relative;
    .set_jump {
      display: flex;
      justify-content: space-between;
      .ant-radio-group {
        position: relative;
        right: -12px;
      }
    }
    .set_jump_form {
      margin-top: 10px;
    }
    .set_jump_btn {
      width: 100%;
      height: 36px;
      background: #f8f9fc;
      border-radius: 4px;
      border: 1px solid #e5e6eb;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
    }
    img {
      min-width: 100%;
      min-height: 100px;
      cursor: pointer;
    }
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: -10px;
      top: -10px;
      display: none;
      img {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        cursor: pointer;
      }
    }
    p {
      margin-top: 10px !important;
    }
  }
  .choiceImg:hover {
    .close {
      display: block;
    }
  }
}
.addBanner {
  margin-top: 20px;
  border: 1px dashed #e5e6eb;
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 17px;
  text-align: center;
  font-style: normal;
}
.noImg {
  padding-top: 20px;
  height: 120px;
  text-align: center;
  background-color: #f4f6fb;
}
.cssChange {
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  div {
    margin-bottom: 11px;
  }
  p {
    margin-bottom: 8px !important;
    span {
      margin-right: 15px;
    }
  }
}
.check2 {
  background-color: #1890ff !important;
  color: white;
}
// .upBox {
//   position: relative;
//   height: 120px;
//   min-height: 120px;
//   // height: 150px;
// }
// .upBox_up {
//   // position: absolute;
//   width: 100%;
//   height: 100%;
//   z-index: 9;
// }

.ant-input-number {
  width: 60px;
}
</style>
 