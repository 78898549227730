<template>
  <div class="jumpTo">
    <a-popover>
      <div slot="content" class="">
        <a-button disabled type="link">
          {{item.next_meeting_view_title}}
        </a-button>
        <!-- <a-button @click="jumpToPage(1)" type="link">
          跳转到...
        </a-button>  -->
      </div>
      <a-button :disabled="!item.url&&!item.meeting_view_id" @click="jumpToPage(1)" type="link">
        跳转
      </a-button>
    </a-popover>
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    jumpToPage(data) {
      window.open(window.location.origin + `/#/workPlace?view_id=${this.item.meeting_view_id}&meeting_id=${this.$route.query.meeting_id}`)
      return
      this.$confirm({
        title: 'Are you sure delete this task?',
        content: 'Some descriptions',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk() {
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }
}
</script>

<style>
</style>