 const state = {
     web_router: []
 };

 const mutations = {
    set_web_router: (state, data) => {
         state.token = data;
     },
 };

 const actions = {
     async setWeb_router({
         commit
     }, data) {
         await commit('set_web_router', data);
     },
 };

 export default {
     namespaced: true,
     state,
     mutations,
     actions,
 };