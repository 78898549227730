// 路由权限处理

import router from '@/router';
import store from '@/store';

import {
  getSession
} from '@/utils/prototype.js';

const whiteList = ['/login', '/404', '/help'];

router.beforeEach(async (to, from, next) => {
  store.state.web_router.web_router = []
  const token = store.state.loginInfo.token || getSession('token');
  const userinfo = store.state.loginInfo.userinfo || getSession('userinfo');

  if (token) {
    store.state.loginInfo.token = getSession('token');
    store.state.loginInfo.userinfo = getSession('userinfo');
    if (to.path == '/login') {
      next('/');
    } else {
      next();
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next('/login');
    }
  }
});