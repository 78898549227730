<template>
  <a-form :form="form" v-bind="layout" labelAlign="left" style="padding: 20px">
    <a-form-item label="标题">
      <a-input v-model="form.label" @change="changeForm(form.label, 'label')" />
    </a-form-item>
    <a-form-item label="标签宽度">
      <a-input v-model="form.labelWidth" placeholder="请输入数字" @change="changeForm(form.labelWidth, 'labelWidth')" />
    </a-form-item>
    <a-form-item label="最大值">
      <a-input v-model="form.max" @change="changeForm(form.max, 'max')" />
    </a-form-item>
    <a-form-item label="最小值">
      <a-input v-model="form.min" @change="changeForm(form.min, 'min')" />
    </a-form-item> 
  </a-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      layout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
      }
    };
  },
  created() {
    this.form = {
      ...(this.formwork[this.index]
        ? JSON.parse(JSON.stringify(this.formwork[this.index].options))
        : {})
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"])
  },
  watch: {
    index(newval) {
      this.form = {
        ...(this.formwork[newval]
          ? JSON.parse(JSON.stringify(this.formwork[newval].options))
          : {})
      };
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"]),
    changeForm(value, label) {
      if (label === "require" && !value) {
        this.form.requireMsg = "";
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "requireMsg",
          value: ""
        });
      }
      if (label === "height") {
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: label,
          value: value + "px"
        });
      }
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: label,
        value: value
      });
    }
  }
};
</script>

<style scoped>
.component {
  margin-bottom: 10px;
  padding: 20px;
}
.active {
  background-color: #d3adf7;
  border-radius: 4px;
  position: relative;
}
</style>
