<template>
  <div class="login_R_Form">
    <Editor :key="checkTypeIndex+'edit'" @input='input' :form="{ data: { desc: options.value } }" />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Editor from '@/components/Editor.vue';
export default {
  components: { Editor },
  props: ['checkTypeIndex'],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() {
      let reOp = this.formwork[this.checkTypeIndex] ? this.formwork[this.checkTypeIndex].options : { options: this.formwork.articleOptions };      // return JSON.parse(JSON.stringify(reOp));
      return reOp
    },
  },
  methods: {
    input(val) {
      console.log(val, 'val')
      this.options.value = val
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="less" scoped>
.login_R_Form {
  margin: 10px 24px;
  border-top: 1px solid #eff0f2;
  padding-top: 20px;
  height: 600px;
  min-height: 600px;
  .editor {
    height: 100%;
  }
  .login_R_FormItem {
    margin-bottom: 20px;
  }
  .R_FormItem_name {
    font-weight: 500;
    font-size: 12px;
    color: #222222;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    margin-bottom: 12px;
  }
}
</style> 