import request from '@/utils/request'

/**
 * 登录接口
 * @param {*} data 请求对象 需要 mobile 和 password
 * @returns Promise
 */
export function formsearchall(data) {
    return request({
        method: 'post',
        url: '/user/login',
        data
    })
}


/**
 * 登录接口
 * @param {*} data 请求对象 需要 mobile 和 password
 * @returns Promise
 */
export function formsave(data) {
    return request({
        method: 'post',
        url: '/user/login',
        data
    })
}


/**
 * 登录接口
 * @param {*} data 请求对象 需要 mobile 和 password
 * @returns Promise
 */
export function getSelectOptionData(data) {
    return request({
        method: 'post',
        url: '/user/login',
        data
    })
}

 