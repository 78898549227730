<template>
  <div class="tuwen">

    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">样式调整</span></span>
    </p>

    <div v-show="btnTypeNum==1" class="imgList">

      <div v-for="(item,index) in video_list" :key="item.id" class="video_list">
        <a-button class="close" @click="closeAll(index)" shape="circle" icon="close" />
        <p class="list_p">
          <span>云展台：</span>
          <span class="imgBox"> 
            {{item.title}}
          </span>
        </p>
        <span v-for="(i,index2) in item.live_list" :key="i.id" class="ietm">
          <a-button class="close2" @click="close(index,index2)" shape="circle" icon="close" />
          {{i.title}}
        </span>
      </div>

    </div>
    <div v-show="btnTypeNum==2" class="imgList">
      <p>
        <pageCss :propCss="form.data.pageCss" @giveCss='giveCss' />
      </p>

    </div>

  </div>
</template>

<script>

import pageCss from './pageCss.vue'
import { getVideoList } from '@/api/meetApi.js';
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, pageCss },
  name: "shipinhejiForm",
  data() {
    return { video_list: [], btnTypeNum: 1, form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    this.getRicheng()
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });

      this.$emit("changeWindow");
    },
    changeBtn(num) {
      this.form.data.showType = num;
      this.chnageVuex();
    },
    btnTypeFn(num) {
      this.btnTypeNum = num;
      this.chnageVuex();
    },
    autoTimerFn(num) {
      this.form.data.borderStyle = num;
      this.chnageVuex();
    }, 
        closeAll(index) {
      this.video_list.splice(index, 1);
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    close(index, index2) {
      this.form.data.menuArr[index].live_list.splice(index2, 1);
      this.chnageVuex();
    },
    async getRicheng() {

      if (this.form.data.menuArr[0]) {
        this.video_list = this.form.data.menuArr
        return this.chnageVuex();
      }

      this.form.data.menuArr = []
      let data = await getVideoList({ meeting_id: this.$route.query.meeting_id, type: this.form.data.type, schedule: 0 })
      console.log(this.data)
      this.video_list = data.payload.data
      this.video_list.forEach(item => {
        this.form.data.menuArr.push(item)
      });
      this.chnageVuex();
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },
  },

};
</script>

<style lang="less" scoped>
.w70 {
  width: 70px;
}
.padding {
  padding: 24px;
}
.check2 {
  border: 1px solid #1890ff !important;
  color: #1890ff;
  i {
    color: #1890ff !important;
  }
}
.lineBtn {
  width: 100%;
  button {
    font-weight: 800;
    margin-left: 0 !important;
    i {
      color: #ccc;
    }
  }
}
.imgList {
  padding: 24px;
  position: relative;
}
.imgList_p {
  display: flex;
  margin-top: 15px;
  line-height: 32px;
  margin-bottom: 25px;
}
.imgList_p span:nth-child(4) {
  margin-right: 2px;
}
.toBig {
  transform: scale(1.2, 1.2);
}
.imgList_title {
  margin-right: 15px;
}
.video_list {
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  margin: 10px 0;
  border: 1px solid #ccc;
  padding: 8px;
  .ietm {
    border: 1px solid #ccc;
    padding: 0 8px;
    margin-bottom: 10px;
    position: relative;
  }
  .ietm:last-child {
    margin-bottom: 0;
  }
}
.list_p {
  display: flex; 
}
.imgBox {
  // width: 90px;
}
.list_p span:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.inp_color {
  width: 50px;
}
.video_list:hover {
  .close {
    display: block;
  }
  .close2 {
    display: none;
  }
}
.ietm:hover {
  .close2 {
    display: block;
  }
  .close {
    display: none;
  }
}
.close,
.close2 {
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: #ccc;
  display: none;
}
</style> 