var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"表单保存","destroyOnClose":true,"zIndex":_vm.zindex,"centered":""},on:{"ok":_vm.submitForm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('a-form-model',_vm._b({ref:"modelForm",attrs:{"model":_vm.modelForm,"labelAlign":"left"}},'a-form-model',{
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    },false),[_c('a-form-model-item',{attrs:{"label":"项目名称","prop":"name","rules":[
        {
          required: true,
          message: '项目名称不能为空',
          trigger: 'blur'
        }
      ]}},[_c('a-input',{model:{value:(_vm.modelForm.name),callback:function ($$v) {_vm.$set(_vm.modelForm, "name", $$v)},expression:"modelForm.name"}})],1),_c('a-form-model-item',{attrs:{"label":"表单提交路径","prop":"url","rules":[
        {
          required: true,
          message: '表单提交路径不能为空',
          trigger: 'blur'
        }
      ]}},[_c('a-input',{model:{value:(_vm.modelForm.url),callback:function ($$v) {_vm.$set(_vm.modelForm, "url", $$v)},expression:"modelForm.url"}})],1),_c('a-form-model-item',{attrs:{"label":"是否需要验证","prop":"valid"}},[_c('a-switch',{model:{value:(_vm.modelForm.valid),callback:function ($$v) {_vm.$set(_vm.modelForm, "valid", $$v)},expression:"modelForm.valid"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }