var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"masklayer"},[(_vm.options.data.showType==1)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox list1",style:('padding-bottom:' + _vm.options.data.pageCss.page_css_interval + 'px'),attrs:{"flex":"100%"}},[_c('div',{staticClass:"list_img"},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),1):_vm._e(),(_vm.options.data.showType==2&&_vm.options.data.menuArr.length!=2)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox list2",style:('padding:' + _vm.options.data.pageCss.page_css_interval + 'px'),attrs:{"flex":"50%"}},[_c('div',{staticClass:"list_img"},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),1):_vm._e(),(_vm.options.data.showType==2&&_vm.options.data.menuArr.length==2)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox list2",style:('padding:0 ' + _vm.options.data.pageCss.page_css_interval + 'px'),attrs:{"flex":"50%"}},[_c('div',{staticClass:"list_img"},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),1):_vm._e(),(_vm.options.data.showType==3)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox list3",style:('padding:' + _vm.options.data.pageCss.page_css_interval + 'px'),attrs:{"flex":"33.33333%"}},[_c('div',{staticClass:"list_img"},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),1):_vm._e(),(_vm.options.data.showType==4)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox list4",style:('padding:' + _vm.options.data.pageCss.page_css_interval + 'px'),attrs:{"flex":"25%"}},[_c('div',{staticClass:"list_img"},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),1):_vm._e(),(_vm.options.data.showType==5)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox list4",style:('padding:' + _vm.options.data.pageCss.page_css_interval + 'px'),attrs:{"flex":"20%"}},[_c('div',{staticClass:"list_img"},[_c('img',{attrs:{"src":item.src,"alt":""}})])])}),1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }