<template>
  <div class="pageCss">
    <p>
      <span class="pageCss_title">间隔：<span style="opacity:0">巨</span></span>
      <a-button-group>
        <a-input-number @change='changeAllData' v-model="pageCss.page_css_interval" :min="0" :max="100" />
      </a-button-group>
      <span class="pageCss_title">圆角：<span style="opacity:0">巨</span></span>
      <a-button-group>
        <a-input-number @change='changeAllData' v-model="pageCss.page_css_radius" :min="0" :max="100" />
      </a-button-group>
    </p>

    <p>
      <span class="pageCss_title">内边距：</span>
      <a-button-group>
        <a-input-number @change='changeAllData' placeholder="上" v-model="baseCss.paddingA" :min="0" :max="100" />
        <a-input-number @change='changeAllData' placeholder="右" v-model="baseCss.paddingB" :min="0" :max="100" />
        <a-input-number @change='changeAllData' placeholder="下" v-model="baseCss.paddingC" :min="0" :max="100" />
        <a-input-number @change='changeAllData' placeholder="左" v-model="baseCss.paddingD" :min="0" :max="100" />
      </a-button-group>
    </p>

    <p>
      <span class="pageCss_title">外边距：</span>
      <a-button-group>
        <a-input-number @change='changeAllData' placeholder="上" v-model="baseCss.marginA" :min="0" :max="100" />
        <a-input-number @change='changeAllData' placeholder="右" v-model="baseCss.marginB" :min="0" :max="100" />
        <a-input-number @change='changeAllData' placeholder="下" v-model="baseCss.marginC" :min="0" :max="100" />
        <a-input-number @change='changeAllData' placeholder="左" v-model="baseCss.marginD" :min="0" :max="100" />
      </a-button-group>
    </p>

    <p>
      <span class="pageCss_title">背景色：</span>
      <a-button-group>
        <a-input id="color" @change='changeBGC' class="inp_color" v-model="pageCss.page_css_bgc" type="color" />
      </a-button-group>
    </p>

    <p>
      <span class="pageCss_title">背景色透明度：</span>
      <a-button-group>
        <a-slider id="slider" :default-value="deValue" @change="sliderOninput" :min="0" :max="1" :step="0.1" />
      </a-button-group>
    </p>

    <p>
      <span class="pageCss_title">自定义css：</span>
      <a-button-group>
        <a-textarea @change='changeAllData' v-model="pageCss.myCss"
          placeholder="background: #FFFFFF;border-radius: 0 0 8px 8px;" :rows="4" />
      </a-button-group>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    propCss: {
      type: Object,
      default: () => ({
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",
        myCss: '',
      })
    }
  },
  data() {
    return {
      baseCss: {
        paddingA: '',
        paddingB: '',
        paddingC: '',
        paddingD: '',
        marginA: '',
        marginB: '',
        marginC: '',
        marginD: '',
      },
      pageCss: {
        page_css_margin: '',
        page_css_padding: '',
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: '#ffffff',
        myCss: '',
      },
      deValue: 1,
    }
  },
  created() {
    if (this.propCss.page_css_padding) {
      this.baseCss.paddingA = this.getNum(this.propCss.page_css_padding)[0]
      this.baseCss.paddingB = this.getNum(this.propCss.page_css_padding)[1]
      this.baseCss.paddingC = this.getNum(this.propCss.page_css_padding)[2]
      this.baseCss.paddingD = this.getNum(this.propCss.page_css_padding)[3]
    }
    if (this.propCss.page_css_margin) {
      this.baseCss.marginA = this.getNum(this.propCss.page_css_margin)[0]
      this.baseCss.marginB = this.getNum(this.propCss.page_css_margin)[1]
      this.baseCss.marginC = this.getNum(this.propCss.page_css_margin)[2]
      this.baseCss.marginD = this.getNum(this.propCss.page_css_margin)[3]
    }
    if (this.propCss.textBgc) {
      this.deValue = Number(this.getNum(this.propCss.textBgc)[3])
    }
    // this.pageCss.page_css_interval = this.propCss.page_css_interval
    // this.pageCss.page_css_radius = this.propCss.page_css_radius
    // this.pageCss.page_css_bgc = this.propCss.page_css_bgc
    // this.pageCss.myCss = this.propCss.myCss
    this.pageCss = this.propCss
  },
  methods: {
    getNum(str) {
      return str.match(/\d+(\.\d+)?/g)
    },
    giveCss() {
      this.$emit('giveCss', this.pageCss)
    },
    sliderOninput(val) {
      let rgb = this.hexToRgb(this.pageCss.page_css_bgc);
      this.pageCss.textBgc = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + val + ")"
      this.changeAllData()
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    changeBGC() {
      let rgb = this.hexToRgb(this.pageCss.page_css_bgc);
      this.pageCss.textBgc = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + 1 + ")"
      this.changeAllData()
    },
    changeAllData() {
      if (this.pageCss.myCss) {
        this.pageCss.myCss = this.pageCss.myCss.replace(/\n/g, ""); // 将换行符替换为'' 
      }
      this.pageCss.page_css_margin = Number(this.baseCss.marginA) + 'px ' + Number(this.baseCss.marginB) + 'px ' + Number(this.baseCss.marginC) + 'px ' + Number(this.baseCss.marginD) + 'px'
      this.pageCss.page_css_padding = Number(this.baseCss.paddingA) + 'px ' + Number(this.baseCss.paddingB) + 'px ' + Number(this.baseCss.paddingC) + 'px ' + Number(this.baseCss.paddingD) + 'px'
      this.giveCss()
      console.log(this.pageCss)
    }
  }
}
</script>

<style>
.pageCss p {
  margin-bottom: 12px;
}
.pageCss .attcion {
  display: block;
  font-size: 12px;
  color: #ccc;
}
.pageCss .inp_color {
  width: 50px;
}

.pageCss .ant-input-number {
  width: 50px;
  margin-right: 10px;
}
.pageCss .ant-slider {
  width: 100%;
}
.pageCss .ant-btn-group {
  width: 100%;
}
.pageCss ::placeholder {
  color: #ccc;
}

.pageCss_title {
  display: block;
  margin: 12px 0;
}
#color{
  border-radius: 0;
}
</style>
<style>
.pageCss .ant-input-number {
  width: 60px;
  margin-right: 10px;
}

.pageCss .ant-input-number-handler-wrap {
  opacity: 1;
  background: #f5f7fa;
}
.pageCss .ant-input-number {
  border-radius: 0;
}
textarea.ant-input {
  height: auto !important;
}
</style>