import axios from 'axios';
import {
  message
} from 'ant-design-vue';
import store from '@/store';
// import { getTime } from './auth'
import router from '@/router';

const service = axios.create({
  baseURL: '/apis/setmeet',
  timeout: 5000,
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // // 在发送请求之前做些什么
    // const token = store.getters.token
    // const time = getTime()
    // // 如果有token，而且token没有超过过期时间
    // if (token && Date.now() - time < 1000 * 60 * 60 * 2) {
    //   config.headers.Authorization = 'Bearer ' + token
    // }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {

    if (response.data.status == -1) {
      message.error(response.data.payload.msg);
      store.dispatch("loginInfo/logOut");
      return response.data;
    }

    if (response.data.status == 1) {
      return response.data;
    } else {
      message.error(response.data.payload.msg);
      return Promise.reject(response.data.payload.msg);
    }

  },
  function (error) {
    if (error.response && error.response.data) {
      message.error(error.response.data.info);
    } else {
      message.error('网络错误')
      return Promise.reject(error)
    }

    // 对响应错误做点什么
    if (error.response.data.code === 10002) {
      // // 代表token过期
      // store.dispatch('user/logout')
      // message.error('登录信息失效')
      // router.push('/login')
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;