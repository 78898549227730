var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"masklayer"},[_c('div',{staticClass:"home_Tab",style:('height:' + _vm.options.data.pageCss.tabHeight+'px;border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss)},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('div',{key:index,staticClass:"home_Tab_item",class:{checkTab: item.checkTab},on:{"click":function($event){return _vm.chengeCheck(item)}}},[(item.src&&item.src_1)?_c('span',[(item.src&&!item.checkTab)?_c('img',{staticClass:"home_Tab_itemBg",attrs:{"src":item.src,"alt":""}}):_vm._e(),(item.src_1&&item.checkTab)?_c('img',{staticClass:"home_Tab_itemBg",attrs:{"src":item.src_1,"alt":""}}):_vm._e()]):_vm._e(),(!item.src||!item.src_1)?_c('span',[_c('span',[_vm._v(_vm._s(item.name))]),(item.checkTab)?_c('img',{staticClass:"home_Tab_itemImg",attrs:{"src":require("@/assets/auto_img/指向@2x.png"),"alt":""}}):_vm._e()]):_vm._e()])}),0),_vm._l((_vm.options.data.menuArr),function(item,index){return _c('div',{key:index,staticClass:"home_Tab_list"},[(item.checkTab&&item.table_data.type=='articleBox')?_c('div',{staticClass:"articleBox_item"},[(item.table_data.options.data.desc!='')?_c('div',{staticClass:"articleBox_item_richText",style:('border-radius:' + _vm.options.data.menuArr[0].table_data.options.data.pageCss.page_css_radius + 
  'px;padding:' + _vm.options.data.menuArr[0].table_data.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.menuArr[0].table_data.options.data.pageCss.page_css_margin + 
  ';background-color:' + ''+';'+_vm.options.data.menuArr[0].table_data.options.data.pageCss.myCss),domProps:{"innerHTML":_vm._s(item.table_data.options.data.desc)}}):_vm._e()]):_vm._e(),(item.checkTab&&item.table_data.type=='second_videoList')?_c('div',[_c('div',{staticClass:"second_videoList_list"},[_c('home_Tab_live',{attrs:{"ListshowType":_vm.options.data.pageCss.showType,"basePageCss":_vm.options.data.menuArr[0].table_data.options.data.pageCss,"data":item.table_data.options.data}})],1)]):_vm._e(),(item.checkTab&&item.table_data.type=='shipinheji')?_c('div',[_c('div',{staticClass:"shipinheji_list"},[_c('home_Tab_video',{attrs:{"ListshowType":_vm.options.data.pageCss.showType,"basePageCss":_vm.options.data.menuArr[0].table_data.options.data.pageCss,"data":item.table_data.options.data}})],1)]):_vm._e(),(item.checkTab&&item.table_data.type=='second_articleList')?_c('div',[_c('div',{staticClass:"article_list"},[_c('home_Tab_article',{attrs:{"ListshowType":_vm.options.data.pageCss.showType,"basePageCss":_vm.options.data.menuArr[0].table_data.options.data.pageCss,"data":item.table_data.options.data}})],1)]):_vm._e()])}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }