<template>
  <a-form :form="form" v-bind="layout" labelAlign="left" style="padding: 20px"> 
    <a-form-item label="标题">
      <a-input v-model="form.label" @change="changeForm(form.label, 'label')" />
    </a-form-item>
    <a-form-item label="标签宽度">
      <a-input
        v-model="form.labelWidth" placeholder="请输入数字"
        @change="changeForm(form.labelWidth, 'labelWidth')"
      />
    </a-form-item> 
    <a-form-item label="是否只读">
      <a-switch
        v-model="form.disabled"
        @change="value => changeForm(value, 'disabled')"
      />
    </a-form-item> 
    <a-form-item label="绑定值类型">
      <a-select
        :disabled="timeType === 'daterange' || timeType === 'monthrange'"
        default-value=""
        @change="value => changeForm(value, 'valueType')"
        style="width: 120px"
      >
        <a-select-option
          :value="
            timeType === 'year'
              ? 'yyyy'
              : timeType === 'month'
              ? 'yyyy-MM'
              : 'yyyy-MM-DD'
          "
        >
          YYYY-MM-DD
        </a-select-option>
        <a-select-option
          v-for="item in valueTypes"
          :value="item.value"
          :key="item.value"
        >
          {{ item.text }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="是否需要验证">
      <a-switch
        v-model="form.require"
        @change="value => changeForm(value, 'require')"
      />
    </a-form-item>
    <a-form-item label="判空提示文字" v-if="form.require">
      <a-input
        v-model="form.requireMsg"
        @change="changeForm(form.requireMsg, 'requireMsg')"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      form: {},
      visible: false,
      layout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
      }, 
      valueTypes: [
        {
          text: "date对象",
          value: ""
        }
      ],
      timeType: ""
    };
  },
  created() {
    this.form = {
      ...(this.formwork[this.index]
        ? JSON.parse(JSON.stringify(this.formwork[this.index].options))
        : {})
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"])
  },
  watch: {
    index(newval) {
      this.form = {
        ...(this.formwork[newval]
          ? JSON.parse(JSON.stringify(this.formwork[newval].options))
          : {})
      };
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"]),
    onClose() {
      this.visible = false;
    },
    changeFormJSON(value, label) {
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: label,
        value: value
      });
      this.visible = false;
    },
    changeForm(value, label) {
      if (label === "timeType") {
        this.timeType = value;
      }
      if (label === "require" && !value) {
        this.form.requireMsg = "";
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "requireMsg",
          value: ""
        });
      }
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: label,
        value: value
      });
    }
  }
};
</script>

<style scoped></style>
