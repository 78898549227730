<template>
  <!-- 左侧手机模板——列表页 -->
  <div class="doctor_base_contentL">
    <div ref='changeListStyle' class="DBL_list">

      <div class="DBL_list_inp">
        <a-input ref="userNameInput" placeholder="请输入医生姓名搜索">
          <a-icon slot="prefix" type="search" />
        </a-input>
      </div>

      <div class="DBL_list_All">

        <div v-show="baseData.is_enable_tab==1&&baseData&&baseData.menu_tab&&baseData.menu_tab[0]" class="DBL_list_TAB">
          <div v-for="(item,index) in baseData.menu_tab" :key="index" class="DBL_list_TAB_item">
            <span :class="{ckecked: index==0}" class="DBL_list_TABL_title">{{item.menu_title}}</span>
            <span v-show="index==0" class="DBL_list_TABL_line"></span>
          </div>
        </div>

        <div class="DBL_list_Base_RBig">
          <div :class="{DBL_list_BaseSelect: list=='A'}" v-for="(key,list) in dataList" :key="list">{{list}}</div>
        </div>
        <div v-show="key[0]" v-for="(key,list) in dataList" :key="list" class="DBL_list_Base">
          <div class="DBL_list_BaseBig">{{list}}</div>

          <div v-for="speaker in key" :key="speaker.id" class="DBL_list_item">
            <div class="DBL_list_itemL">
              <img width="100%" height="100%" src="@/assets/noImg.webp" alt="">
            </div>
            <div class="DBL_list_itemR">
              <div class="DBL_list_itemRT">{{speaker.name}}</div>
              <div class="DBL_list_itemRB">{{speaker.hospital_name}}</div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <slot></slot>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Article",
  props: ["index"],
  data() {
    return {
      baseData: {
        "meeting_task_config_id": 1,
        "meeting_id": 1,
        "is_enable_tab": 1,
        "is_enable_list_style": 1,
        "list_style": "3",
        "list_style_img": 31,
        "list_style_img_path": "http://192.168.3.56:9520/storage/setmeet/2024-07/MRaq1xPQpSPify5b3ebL8CkqQZWYOVsVhOfkIHiD.jpg",
        "is_enable_detail_style": "1",
        "detail_style": "3",
        "detail_style_img": 31,
        "detail_style_img_path": "http://192.168.3.56:9520/storage/setmeet/2024-07/MRaq1xPQpSPify5b3ebL8CkqQZWYOVsVhOfkIHiD.jpg",
        "is_date_navigation": 2,
        "personal_background": 1,
        "personal_background_path": "http://192.168.3.56:9520/storage/setmeet/2024-06/zcCmaD3XfbEeD7RoNFDUAdhlBavkuDuuEdh7XksX.jpg",
        "menu_tab": [
          {
            "menu_title": "讲者",
            "identity": [
              1,
              2
            ],
            "sort": 1
          },
          {
            "menu_title": "主持点评",
            "identity": [
              1,
              2,
              3
            ],
            "sort": 2
          }
        ]
      },
      dataList: {
        "A": [
          {
            "id": 228,
            "name": "安志康",
            "hospital_name": "上海市浦东新区公利医院",
            "head_img": "/uploads/070e101b64e5ab2c147faf977889baf1.jpeg",
            "name_Initial": "C",
            "sort": 0
          },
          {
            "id": 251,
            "name": "安达龙",
            "hospital_name": "复旦大学附属肿瘤医院",
            "head_img": "/uploads/ebe05369e67bb82f7c7f04a002efb07d.jpg",
            "name_Initial": "C",
            "sort": 0
          },
        ],
        "B": [
          {
            "id": 228,
            "name": "包志康",
            "hospital_name": "上海市浦东新区公利医院",
            "head_img": "/uploads/070e101b64e5ab2c147faf977889baf1.jpeg",
            "name_Initial": "C",
            "sort": 0
          },
          {
            "id": 251,
            "name": "包达龙",
            "hospital_name": "复旦大学附属肿瘤医院",
            "head_img": "/uploads/ebe05369e67bb82f7c7f04a002efb07d.jpg",
            "name_Initial": "C",
            "sort": 0
          },
        ],
        "C": [
          {
            "id": 228,
            "name": "蔡志康",
            "hospital_name": "上海市浦东新区公利医院",
            "head_img": "/uploads/070e101b64e5ab2c147faf977889baf1.jpeg",
            "name_Initial": "C",
            "sort": 0
          },
          {
            "id": 251,
            "name": "曹达龙",
            "hospital_name": "复旦大学附属肿瘤医院",
            "head_img": "/uploads/ebe05369e67bb82f7c7f04a002efb07d.jpg",
            "name_Initial": "C",
            "sort": 0
          },
        ],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        L: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: [],
      },
    };
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.articleBox.data };
    },
  },
};
</script>

<style lang="less" scoped>
.DBL_list_TAB {
  height: 48px;
  display: flex;
  .DBL_list_TAB_item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .ckecked {
      color: #1890ff;
    }
    .DBL_list_TABL_line {
      display: inline-block;
      width: 20px;
      border-radius: 2px;
      height: 2px;
      background-color: #1890ff;
      position: absolute;
      width: 20px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 10px;
    }
  }
}
.doctor_base_contentL {
  margin-right: 40px;
  width: 100%;
  min-height: 667px;
  border: 1px solid #e5e6eb;
  border-radius: 8px;
}
.doctor_base_contentR {
  .DBR_data_itemT_inp,
  .ant-input {
    width: 120px;
    color: #222222;
  }
  :deep(.ant-input) {
    color: #222222;
  }
  .ant-checkbox-wrapper {
    color: #222222;
  }
  .DBR_data_item {
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #585858;
    height: 112px;
    background: #f9fafc;
    border-radius: 2px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }
  .DBR_data_item:last-child {
    margin-bottom: 0;
  }
  .DBR_data_title {
    margin-right: 12px;
  }
  .DBR_data_must {
    color: red;
  }
  .DBR_data_itemT {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .DBR_data_myimg {
    width: 160px;
    height: 90px;
  }
  .DBR_data {
    .DBR_data_item {
      display: flex;
    }
    padding-bottom: 24px;
    .DBR_data_add {
      width: 160px;
      margin-bottom: 16px;
      height: 40px;
      font-weight: 400;
      font-size: 12px;
      color: #222222;
      line-height: 17px;
      border-radius: 4px;
      border: 1px solid #e5e6eb;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  flex: 1;
  max-width: 560px;
  .DBR_B {
    border-bottom: 1px solid #eff0f2;
  }
  .DBR_title_boxBase {
    border-bottom: 1px solid #eff0f2;
  }
  .bottom_none {
    border-bottom: 0px solid #000;
  }
  .DBR_title_box {
    padding: 24px 0;
    display: flex;
    align-items: center;
  }
  .DBR_title_boxL {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
  }
  .DBR_title_boxC {
    flex: 1;
    font-weight: 400;
    font-size: 13px;
    color: #86909d;
    margin-left: 12px;
  }
}
.DBL_list {
  // background: url("~@/assets/kv.jpg");
  background-size: 100% 100%;
  padding: 10px 12px;
  .DBL_list_inp {
    :deep(.ant-input) {
      background: #ffffff;
      border-radius: 20px;
    }
  }
  .DBL_list_All {
    background-color: #fff;
    .DBL_list_Base_RBig {
      position: absolute;
      height: 90%;
      right: -12px;
      top: 5%;
      font-weight: 400;
      font-size: 10px;
      color: #a9a9a9;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .DBL_list_BaseSelect {
      color: #232323;
    }
    position: relative;
    border: 1px solid #f1f3f4;
    border-radius: 12px;
    margin: 10px 0;
  }
  .DBL_list_Base {
    .DBL_list_BaseBig {
      position: absolute;
      left: 16px;
      top: 2px;
      font-weight: 400;
      font-size: 12px;
      color: #a9a9a9;
    }
    position: relative;
    height: 100%;
    padding: 0 16px;
    .DBL_list_item {
      .DBL_list_itemL {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        overflow: hidden;
        margin-right: 12px;
      }
      .DBL_list_itemR {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .DBL_list_itemRT {
        font-weight: 600;
        font-size: 14px;
        color: #232323;
      }
      .DBL_list_itemRB {
        font-weight: 400;
        font-size: 12px;
        color: #888888;
      }
      display: flex;
      padding: 17px 0;
      height: 88px;
      border-bottom: 1px solid #f1f3f4;
    }
  }
}
</style>