<template>
  <div class="tuwen">
    <div>
      <p>LOGO</p>
      <div class="tuwen_upload">
        <upload :image="form.data.logo" @uploadOk='uploadOk'></upload>
      </div>
    </div>
    <p @click="setJumpFn">内容编辑</p>
    <a-textarea class="tuwen_textarea" v-model="form.data.desc" @change='chnageVuex' placeholder="建议20字内"
      :auto-size="{ minRows: 3, maxRows: 50 }" />
    <setJump @jumpForm='jumpForm' ref="setJump" />
  </div>
</template>

<script>
import setJump from './setJump.vue';
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, setJump },
  name: "dibudaohangForm",
  data() {
    return { form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
    },
    uploadOk(data) {
      this.form.data.image_id = data.payload.id;
      this.form.data.logo = data.payload.path
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.meeting_view_id = data.meeting_view_id
      this.form.data.url = data.url
      this.chnageVuex();
    },
    setJumpFn() {
      // return console.log(this.form.data)
      this.$refs.setJump.showModal(this.form.data)
    }
  },
};
</script>

<style lang="less" scoped>
.tuwen {
  padding: 24px;
}
.tuwen_upload {
  width: 85px;
  min-height: 85px;
  margin: 10px 0;
}
.tuwen_textarea {
  width: 80%;
  margin-top: 10px;
}
</style> 