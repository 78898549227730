<template>
  <div class="layout_top">

    <div class="layout_top_MBX">
      <span v-for="(item,index) in web_router" :class="{last_router:index==(web_router.length-1)}" :key="index">
        <span>{{item}}</span>
        <span v-if="web_router[index+1]" class="layout_top_MBXItem">/</span>
      </span>
    </div>

    <div class="flex1"></div>

    <div class="searchBox cursor">
      <span @click='showInp' class="spanBox">
        <span class="iconfont icon-sousuo"></span>
      </span>
      <span :class="showInpNum?'span_inp':'span_inp2'" class="spanBox"><a-input placeholder="search"></a-input></span>
    </div>

    <div class="showBig cursor">
      <span @click="click" class="iconfont icon-shouqi" :class="isFullscreen ? 'icon-fangda' : 'icon-shouqi'"></span>
    </div>

    <a-dropdown>
      <div class="logAdmin cursor">
        <span>
          <img v-if="!userinfo.avatar_path" height="28px" width="28px" src="@/assets/默认用户.png" alt="" />
          <img v-if="userinfo.avatar_path" height="28px" width="28px" :src="userinfo.avatar_path" alt="" />
        </span>
        <span><span class="iconfont icon-xiajiantoucu"></span></span>
      </div>

      <template #overlay>
        <a-menu>
          <a-menu-item>
            <a @click="goMy" href="javascript:;">主页</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="showModal" href="javascript:;">退出</a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <a-modal title="提示" :visible="visible" cancelText='取消' okText='确认' :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel">
      <p>{{ ModalText }}</p>
    </a-modal>
  </div>
</template>

<script> 
import { mapGetters, mapActions } from "vuex";
export default {
  name: 'layout_top',
  data() {
    return {
      isFullscreen: true,
      showInpNum: false,
      confirmLoading: false,
      ModalText: "确认退出吗？",
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["web_router", "userinfo"]),
  },
  methods: {
    click() {
      this.isFullscreen = !this.isFullscreen;
      var element = document.documentElement;
      if (this.isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
    },
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      //
      //
      // 退出请求
      this.confirmLoading = true;
      await this.$store.dispatch("loginInfo/logOut");
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    showInp() {
      this.showInpNum = !this.showInpNum
    },
    goMy() {
      this.$router.push('/user')
    }
  },
};
</script>

<style lang="less" scoped>
.layout_top {
  position: fixed;
  top: 0;
  height: 50px;
  width: calc(100% - 180px);
  z-index: 2;
  background-color: #fff;
  margin-bottom: 12px;
  border-bottom: 1px solid #d8dce5;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 0 3px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 0 20px;
}
.layout_top_MBX {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #585858;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.last_router {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.layout_top_MBXItem {
  margin: 0 4px;
}
.flex1 {
  flex: 1;
}
.logAdmin {
  padding: 0 10px;
  display: flex;
  span {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      margin-right: 10px;
    }
  }
  // padding: 11px 0;
  // line-height: 50px;
}
.logAdmin img {
  border-radius: 50%;
  overflow: hidden;
}
.cursor {
  cursor: pointer;
}
.showBig {
  height: 50px;
  text-align: center;
  line-height: 50px;
  padding: 0 6px;
}
.showBig .iconfont {
  font-size: 24px;
}
.searchBox {
  display: flex;
  padding: 0 6px;
  .iconfont {
    font-size: 22px;
    font-weight: 800;
  }
}
.searchBox .spanBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: all 0.5s;
}
.span_inp {
  width: 200px;
  transition: all 0.5s;
  margin-left: 10px;
}

.span_inp2 {
  width: 0;
  transition: all 0.5s;
}
</style>
