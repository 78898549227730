<template>
  <div class="baseFormBox">
    <div v-html="options.value" class="video_baseFormBox"> 
    </div>
    <slot></slot>
  </div>

</template>

<script> 
import { mapGetters } from "vuex";
export default {
  props: ["index"],
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() {
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.articleOptions };
    },
  },
};
</script> 