<template>
  <div class="tuwen">
    <div>

      <p class="setting_title">设置</p>

      <div v-if="!form.data.pageCss.img_scroll" class="setting_base">
        <p class="setting_item">文件类型</p>
        <a-radio-group @change='changeAllData' v-model="form.data.pageCss.alignment">
          <a-radio :value="'center'">
            无限制
          </a-radio>
          <a-radio :value="'left'">
            图片
          </a-radio>
          <a-radio :value="'right'">
            视频
          </a-radio>
          <a-radio :value="'right'">
            文件
          </a-radio>
        </a-radio-group>
      </div>

      <div v-if="!form.data.pageCss.img_scroll" class="setting_base">
        <p class="setting_item">文件大小</p>
        <a-input v-model="form.data.pageCss.alignment" type='number' placeholder="请输入" suffix="m" />
      </div>

      <div v-if="!form.data.pageCss.img_scroll" class="setting_base">
        <p class="setting_item">文件数量</p>
        <a-input v-model="form.data.pageCss.alignment" type='number' placeholder="请输入"  />
      </div>
  
    </div> 
  </div>
</template>

<script> 
import upload from "@/components/upload.vue";
import pageCss from './pageCss.vue'
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, pageCss },
  name: "home_dengfen",
  data() {
    return {
      deValue: 0,
      form: {},
      baseCss: {
        paddingA: '',
        paddingB: '',
        paddingC: '',
        paddingD: '',
        marginA: '',
        marginB: '',
        marginC: '',
        marginD: '',
      },
    };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    this.initBaseCss()
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
      this.$emit("changeWindow");
    },
    jumpForm(data) {
      this.form.data.meeting_view_id = data.meeting_view_id
      this.form.data.url = data.url
      this.chnageVuex();
    },
    setJumpFn() {
      // return console.log(this.form.data)
      this.$refs.setJump.showModal(this.form.data)
    },
    uploadOk(data) {
      this.form.data.logo = data.payload.path
      this.form.data.image_id = data.payload.id;
      this.chnageVuex();
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },
    getNum(str) {
      return str.match(/\d+(\.\d+)?/g)
    },
    changeImg_w_det(data) {
      this.form.data.pageCss.img_w_det = Number(this.form.data.pageCss.img_w_det) + data;
    },
    initBaseCss() {
      let propCss = this.form.data.pageCss
      if (propCss.page_css_padding) {
        this.baseCss.paddingA = this.getNum(propCss.page_css_padding)[0]
        this.baseCss.paddingB = this.getNum(propCss.page_css_padding)[1]
        this.baseCss.paddingC = this.getNum(propCss.page_css_padding)[2]
        this.baseCss.paddingD = this.getNum(propCss.page_css_padding)[3]
      }
      if (propCss.page_css_margin) {
        this.baseCss.marginA = this.getNum(propCss.page_css_margin)[0]
        this.baseCss.marginB = this.getNum(propCss.page_css_margin)[1]
        this.baseCss.marginC = this.getNum(propCss.page_css_margin)[2]
        this.baseCss.marginD = this.getNum(propCss.page_css_margin)[3]
      }
      if (propCss.textBgc) {
        this.deValue = Number(this.getNum(propCss.textBgc)[3])
      }

      // this.pageCss.page_css_interval = propCss.page_css_interval
      // this.pageCss.page_css_radius = propCss.page_css_radius
      // this.pageCss.page_css_bgc = propCss.page_css_bgc
      // this.pageCss.myCss = propCss.myCss
    },
    sliderOninput(val) {
      let rgb = this.hexToRgb(this.form.data.pageCss.page_css_bgc);
      this.form.data.pageCss.textBgc = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + val + ")"
      this.changeAllData()
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    changeAllData() {
      console.log(this.form.data.pageCss.myCss)
      if (this.form.data.pageCss.myCss) {
        this.form.data.pageCss.myCss = this.form.data.pageCss.myCss.replace(/\n/g, ""); // 将换行符替换为'' 
      }
      this.form.data.pageCss.page_css_margin = Number(this.baseCss.marginA) + 'px ' + Number(this.baseCss.marginB) + 'px ' + Number(this.baseCss.marginC) + 'px ' + Number(this.baseCss.marginD) + 'px'
      this.form.data.pageCss.page_css_padding = Number(this.baseCss.paddingA) + 'px ' + Number(this.baseCss.paddingB) + 'px ' + Number(this.baseCss.paddingC) + 'px ' + Number(this.baseCss.paddingD) + 'px'
      this.chnageVuex();
    },



    clearItemUrl(item) {
      item.url = ''
      item.next_meeting_view_title = ''
      item.meeting_view_id = ''
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.next_meeting_view_title = data.next_meeting_view_title
      this.form.data.meeting_view_id = data.meeting_view_id
      this.form.data.url = 'https://www.cndmcloud.com/home/#/?view_id=' + data.meeting_view_id
      this.chnageVuex();
    },
    chnageIs_outside_url(index) {
      this.form.data.next_meeting_view_title = ''
      this.form.data.meeting_view_id = ''
      this.chnageVuex();
    },
    setJumpFn(index) {
      this.$refs.setJump.showModal(this.form.data);
    },
  },
};
</script>

<style lang="less" scoped>
.tuwen {
  padding: 21px;
}
.tuwen_upload {
  width: 92px;
  min-height: 85px;
  margin: 10px 0;
}
.tuwen_textarea {
  width: 80%;
  margin-top: 10px;
}
.cssChange {
  display: flex;
  line-height: 36px;
  span {
    flex: 1;
  }
}
.inp_color {
  width: 50px;
}
.ant-input-number {
  width: 60px;
}
.mb12 {
  margin-bottom: 12px;
}

.setting_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin: 20px 0;
}
.setting_item {
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}
.setting_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.setting_base {
  margin-bottom: 15px;
  font-size: 14px;
  color: #222222;
}
.setting_base .ant-radio-wrapper {
  font-size: 14px;
  color: #222222;
  margin-right: 4px;
}
.width_W {
  width: 148px;
  height: 36px;
  display: flex;
  margin-top: 10px;
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
}
#slider {
  width: 100%;
}
.width_W_item {
  width: 36px;
  background: #f5f7fa;
  border: 1px solid #e5e6eb;
  border-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.width_W_itemT {
  border-right: 1px solid #e5e6eb;
  border-left: 0 solid #000;
}
.width_W_inp {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: 1px solid #e5e6eb;
  padding: 0% 10px;
}
.width_W_inp:focus {
  outline: none; /* 去掉聚焦时的轮廓 */
  box-shadow: none; /* 可选：去掉某些浏览器可能显示的阴影 */
}
.width_W input[type="number"]::-webkit-inner-spin-button,
.width_W input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 用于Mozilla内核浏览器（如Firefox） */
.width_W input[type="number"] {
  -moz-appearance: textfield;
}
.choiceImg {
  padding: 12px;
  min-height: 100px;
  border-radius: 2px;
  border: 1px solid #e5e6eb;
  position: relative;
  .set_jump {
    display: flex;
    justify-content: space-between;
    .ant-radio-group {
      position: relative;
      right: -12px;
    }
  }
  .set_jump_form {
    margin-top: 10px;
  }
  .set_jump_btn {
    height: 36px;
    background: #f8f9fc;
    border-radius: 4px;
    border: 1px solid #e5e6eb;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    min-width: 100%;
    min-height: 100px;
    cursor: pointer;
  }
  .close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -10px;
    top: -10px;
    display: none;
    img {
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      cursor: pointer;
    }
  }
  p {
    margin-top: 10px !important;
  }
}
.choiceImg:hover {
  .close {
    display: block;
  }
}
.set_jump_my {
  position: relative;
  textarea {
    padding-right: 20px;
    text-align: justify;
  }
  img {
    z-index: 2;
    position: absolute;
    width: 16px;
    right: 8px;
    bottom: 0;
    top: 0;
    margin: auto;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }
}
.setting_base .ant-input-number {
  margin-right: 8px;
  width: 60px;
}
:deep(.ant-input-number-handler-wrap) {
  opacity: 1;
  background: #f5f7fa;
}
.ant-input-number {
  border-radius: 0;
}
</style> 