<template>
  <div class="setJump_page">
    <div v-if="visible" class="modal_2">
      <div class="add add_pof">
        <div class="add_form add_pof">

          <div class="add_form_title">
            <span v-if="type==1">选择直播</span>
            <span v-if="type==2">选择视频</span>
            <a-icon @click="handleCancel" class="add_form_close" type="close" />
          </div>

          <div class="searchBox">
            <a-input-search placeholder="请输入名称" v-model="keywordStr" enter-button @search="onSearch" />
            <div style="flex:1"></div>
            <a-button class="showModal2" v-if="type==1" @click="showModal2">新增直播</a-button>
            <a-button class="showModal2" v-if="type==2" @click="showModal2">新增视频</a-button>
            <a-button class="showModal2_reload" type="link" @click="reload_showModal"><img width="14px"
                src="@/assets/auto_img/icon_刷新@2x.png" alt="">刷新</a-button>
          </div>

          <div class="list_box">
            <a-table :scroll="{ x: 600, y: 255 }" :loading="loading" :pagination="ipagination"
              :row-selection="rowSelection" @row-click="columnsClick" @change="handlePageChange"
              :rowKey="record=>record.live_id" :columns="columns" :data-source="dataList">

              <span class="meeting_name text2" slot="meeting_title" slot-scope="text, record">
                <span class="">{{ record.title?record.title: '未填' }} </span>
              </span>

              <span slot="type" slot-scope="text, record">
                <span v-if="record.type==1" class="page_type">{{'直播'}}</span>
                <span v-if="record.type==3" class="page_type">{{'议程'}}</span>
                <span v-if="record.type==2" class="page_type">{{'视频'}}</span>
                <span v-if="record.type==4" class="page_type">{{'外链'}}</span>
              </span>

              <span class="begin_time text2" slot="begin_time" slot-scope="text, record">
                <span v-if="!record.begin_time" class=""> 未填 </span>
                <span v-if="record.begin_time" class="">{{record.begin_time|time('YYYY-MM-DD HH:mm')}} </span>
              </span>

              <span class="begin_time text2" slot="video_status" slot-scope="text, record">
                <span class="status status_wks" v-if="record.status==0"><img src="@/assets/auto_img/wks.png" alt="">
                  未开始</span>
                <span class="status status_zbz" v-if="record.status==1"><img src="@/assets/auto_img/zbz.png" alt="">
                  直播中</span>
                <span class="status status_yjs" v-if="record.status==2"><img src="@/assets/auto_img/yjs.png" alt="">
                  已结束</span>
              </span>

              <span class="begin_time text2" slot="meeting_name" slot-scope="text, record">
                <span v-if="!record.meeting_name" class=""> 未填 </span>
                <span v-if="record.meeting_name" class="">【{{record.meeting_name}}】</span>
              </span>

            </a-table>
          </div>

          <div class="share_bot">
            <a-button @click="handleCancel">取消</a-button>
            <a-button @click='handleOk' type="primary">
              确定
            </a-button>
          </div>

        </div>
      </div>
    </div>

    <addModal @addList='reload_showModal' ref="addModal"></addModal>
  </div>
</template>

<script>
import { setViewList, getViewList, modular_videoList } from '@/api/meetApi.js';
import addModal from '@/views/videoList/components/Modal.vue';

const columns = [
  {
    title: "直播名称",
    dataIndex: "meeting_title",
    scopedSlots: { customRender: "meeting_title" },
    key: "meeting_title",
    align: "left",
    width: 200,
  },
  {
    title: "类型",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    key: "type",
    align: "left",
  },
  {
    title: "开始时间",
    dataIndex: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    key: "begin_time",
    align: "left",
  },
  {
    title: "状态",
    dataIndex: "video_status",
    scopedSlots: { customRender: "video_status" },
    key: "video_status",
    align: "left",
  },
  {
    title: "所属分类",
    dataIndex: "meeting_name",
    scopedSlots: { customRender: "meeting_name" },
    key: "meeting_name",
    align: "left",
  },
];

export default {
  components: { addModal },
  name: "setJump",
  data() {
    return {
      live_id: this.$route.query.id,
      type: '',
      itemList: [],
      visible: false,
      confirmLoading: false,
      visible2: false,
      confirmLoading2: false,
      jumpForm: {
        next_meeting_view_title: '',
        meeting_view_id: '',
        url: ''
      },
      selectDed: [],
      selectNew: [],
      // 
      // 
      // 
      loading: false,
      dataList: [],
      columns,
      keywordStr: '',
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    };
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // this.selectNew处理页数切换时会导致上一页数据丢失
          this.selectNew = [...this.selectNew, ...selectedRows]
          // this.selectNew根据live_id去重
          this.selectNew = this.selectNew.filter((item, index) => {
            return this.selectNew.findIndex(item2 => item2.live_id == item.live_id) === index;
          });
          console.log(this.selectNew)
        },
        getCheckboxProps: function (record) {
          console.log(record, this.live_id)
          return {
            props: {
              disabled: record.disabled,
              defaultChecked: record.selectDedFN == 1, // Column configuration not to be checked
              name: record.name,
            }
          }
        },
      };
    },
  },
  methods: {
    //表格改变时触发
    handlePageChange(page) {
      console.log(page);
      this.ipagination = page;
      this.getPageList({ type: this.type, page: page.current, per_page: page.pageSize, keyword: this.keywordStr, meeting_id: this.$route.query.meeting_id });
    },
    columnsClick(record) {
      this.jumpForm.next_meeting_view_title = record.title
      this.jumpForm.meeting_view_id = record.id;
    },
    close(e) {
      this.checkMenu_click = false
      this.showNum = false
      this.share_F = 1
    },
    onSearch() {
      this.getPageList({ type: this.type, page: 1, per_page: this.ipagination.pageSize, keyword: this.keywordStr, meeting_id: this.$route.query.meeting_id });
    },
    async getPageList(sendData) {
      let data = await modular_videoList(sendData)
      this.ipagination.total = data.payload.page.total
      this.itemList = data.payload.data
      this.dataList = data.payload.data
      console.log(this.dataList)
      console.log(this.selectDed)
      let _this = this
      _this.dataList.forEach(element => {
        if (element.live_id == _this.live_id) {
          element.disabled = true
        }
        _this.selectDed.forEach(element2 => {
          if (element.live_id == element2.live_id) {
            element.selectDedFN = 1
            element.id = element2.id
          }
        })
      });
      console.log(this.dataList)

    },
    reload_showModal() {
      this.keywordStr = ''
      this.getPageList({ meeting_id: this.$route.query.meeting_id, type: this.type })
    },
    showModal(data, type) {
      this.ipagination.current = 1
      // 1普通直播，2，视频，3议程 4外链
      if (type == 2) {
        this.columns = [
          {
            title: "视频名称",
            dataIndex: "meeting_title",
            scopedSlots: { customRender: "meeting_title" },
            key: "meeting_title",
            align: "left",
            width: 200,
          },
          {
            title: "创建时间",
            dataIndex: "begin_time",
            scopedSlots: { customRender: "begin_time" },
            key: "begin_time",
            align: "left",
          },
          {
            title: "所属分类",
            dataIndex: "meeting_name",
            scopedSlots: { customRender: "meeting_name" },
            key: "meeting_name",
            align: "left",
          },
        ];
      }
      this.selectDed = data
      this.selectNew = data
      console.log(data)
      this.keywordStr = ''
      this.type = type
      this.getPageList({ meeting_id: this.$route.query.meeting_id, type: this.type })
      this.visible = true;
    },
    handleOk(e) {
      this.$emit('giveVideo', this.selectNew)
      this.visible = false;
      this.confirmLoading = false;
      this.$message.success('设置成功！')
      this.clear()
    },
    handleCancel(e) {
      var _this = this;
      _this.visible = false;

    },

    showModal2() {
      this.$refs.addModal.showModal();

    },
    clear() {
      this.jumpForm = {
        next_meeting_view_title: '',
        meeting_view_id: '',
        url: ''
      }
    },
    handleCancel2(e) {
      var _this = this;
      _this.visible2 = false;
      _this.clear();
      this.visible2 = false;
    },
  },
};
</script>
<style lang="less" scoped>
.jumpWhere {
  display: flex;
  line-height: 32px;
  margin-bottom: 12px;
  span {
    // margin-right: 10px;
  }
  input {
    flex: 1;
  }
}
.searchBox {
  height: 32px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  // justify-content: space-between;
}
.showModal2 {
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  color: #1890ff;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  border: 1px solid rgba(25, 144, 255, 0.5);
}
.showModal2_reload {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  img {
    margin-right: 5px;
  }
}
.searchBox .ant-input-search {
  width: 240px;
}
.list_box {
  padding: 0 20px;
  min-height: 365px;
  max-height: 365px;
}
:deep(.ant-calendar-picker) {
  width: 100%;
}

.jumpWhere {
  input {
    border-radius: 4px 0 0 4px;
  }
  button {
    border-radius: 0 4px 4px 0;
  }
}
.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 1000px;
  height: 550px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}

.h625 {
  height: 625px;
}
.add_form_title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px 0px 20px 0px;
  margin: 0 20px;
  flex: 1;
}

.add_formBoxTwo {
  padding: 21px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.add_formBox_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f2;
  padding-bottom: 20px;
}
.add_formBox_name {
  text-align: right;
  padding-right: 16px;
  width: 100px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.add_formBox_inp {
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  flex: 1;
  margin: 0 10px 0 0px;
}
.begin_time,
.meeting_name {
  max-width: 400px;
  width: 100%;
}
.add_formBox_btn {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  margin-right: 16px;
  color: #1890ff;
}
.add_formBox_btn:last-child {
  margin-right: 20px;
}
.share_bot button {
  margin-left: 12px;
}
.share_bot {
  border-top: 1px solid #e3e4e8;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
:deep(.ant-table-thead) {
  height: 48px;
  tr {
    th {
      background: #f3f6f9;
      height: 48px;
      padding: 0 16px;
    }
  }
}
:deep(.ant-table-tbody) {
  height: 48px;
  tr {
    td {
      height: 59px;
    }
  }
}
.setJump_page :deep(.title) {
  display: none;
}
.status {
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.status_wks {
  border: 1px solid #2bb840;
  color: #2bb840;
}
.status_zbz {
  border: 1px solid #1890ff;
  color: #1890ff;
}
.status_yjs {
  border: 1px solid #eb5d44;
  color: #eb5d44;
}
</style>