<template>
  <div>
    <div class="title">
      <span class="meet_title"><img width="24px" src="@/assets/list_titleImg (3).png" alt=""> 图文列表</span>
      <div style="flex:1"></div>
      <a-input-search v-model="onSearchDate" class="onSearchBox" placeholder="请输入门户名称搜索" enter-button
        @search="onSearch" />
      <a-button @click="showModal" type="primary"> 新增 </a-button>
    </div>

    <div v-if="visible" class="add add_pof">
      <div class="add_form add_pof">
        <div class="add_form_title">
          创建直播
          <a-icon @click="close" class="add_form_close" type="close" />
        </div>

        <div class="add_formBox">

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>图文名称</div>
            <div class="add_formBox_inp">
              <a-input v-model="ruleForm.title" placeholder="请输入" :maxLength='100'
                :suffix="ruleForm.title.length+'/100'" />
            </div>
          </div>

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>发布时间</div>
            <div class="add_formBox_inp">
              <span class="iconfont icon-31shijian"></span>
              <a-date-picker class="add_formBox_time" format="YYYY-MM-DD HH:mm:ss" valueFormat='X'
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="开始时间"
                v-model="ruleForm.publish_time" />
            </div>
          </div>

        </div>

        <div class="add_formBox_sendBox">
          <a-button @click="close" class="add_formBox_sendBox_qx">取消</a-button>
          <a-button @click="handleOk" type="primary">
            确定
          </a-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { createDocument, getDocumentList, getDocumentDet } from '@/api/meetApi.js';
import moment from "moment";
export default {
  data() {
    return {
      onSearchDate: '',
      meetList: [],
      visible: false,
      confirmLoading: false,
      ruleForm: {},
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
    };
  },
  created() {
    this.ruleForm = {
      title: "",
      publish_time: ''
    }
  },
  methods: {
    moment,
    onSearch() {
      if (!this.onSearchDate) {
        // return this.$message.error("请输入搜索内容！");
      }
      this.$emit("keyword", this.onSearchDate);
    },
    change(data) {
      this.ruleForm = JSON.parse(JSON.stringify(data));
      this.showModal();
    },
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      // console.log(this.ruleForm);
      //
      //
      //
      // 此处调用添加/编辑接口
      // 完成后使用this.$emit调用父方法重新获取列表
      if (!this.ruleForm.title || !this.ruleForm.publish_time) {
        return this.$message.error('请全部填写完整！')
      }
      if (this.$route.query.meeting_id) {
        this.ruleForm.meeting_id = this.$route.query.meeting_id
      }
      let data = await createDocument(this.ruleForm)
      this.ruleForm.status = 1;
      this.$emit("addList", JSON.parse(JSON.stringify(this.ruleForm)));
      this.confirmLoading = false;
      this.clear();
      this.visible = false;
      this.$message.success('创建成功！')
    },
    handleCancel(e) {
      var _this = this;
      for (const key in this.ruleForm) {
        if (this.ruleForm[key]) {
          return this.$confirm({
            title: "确定退出吗！",
            content: "退出不会保留修改内容！",
            okText: "确定",
            okType: "danger",
            cancelText: "取消",
            onOk() {
              _this.clear();
              _this.visible = false;
            },
            onCancel() { },
          });
        }
      }
      this.visible = false;
    },
    clear() {
      for (const key in this.ruleForm) {
        if (key == 'menuList') {
          this.ruleForm[key] = []
        } else {
          this.ruleForm[key] = "";
        }
      }

      this.ruleForm.is_external_url = 2
      this.ruleForm.is_display = 1
      this.ruleForm.is_sign_in = 2
      this.ruleForm.view = 1
      this.ruleForm.status = 0
      this.ruleForm.is_schedule = 1
      this.ruleForm.menuList = [{
        title: '会议议程',
        type: 'synopsis',
        richText: ''
      }]

    },
    close(e) {
      this.clear()
      this.visible = false
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  margin-bottom: 24px;
  .onSearchBox {
    :deep(.ant-btn-primary) {
      background: #f5f7fa;
      border: 1px solid #e5e6eb;
      color: #7e8081;
      box-shadow: unset;
    }
    width: 312px;
    margin-right: 24px;
  }
  .meet_title {
    img {
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
    display: flex;
    align-items: center;
  }
  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
    text-align: left;
    font-style: normal;
  }
}
:deep(.ant-calendar-picker) {
  width: 100%;
}
:deep(.ant-calendar-picker-icon) {
  // top: 30%;
}

.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 500px;
  height: 300px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}
.add_form_title {
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    cursor: pointer;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px;
  padding-bottom: 0;
}
.add_formBox_name {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  margin-bottom: 10px;
}
.add_formBox_require {
  color: red;
  margin-right: 5px;
}
.add_formBox_item {
  margin-bottom: 20px;
}
.add_formBox_sendBox {
  flex: 1;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.add_formBox_time {
  width: 100%;
}
.add_formBox_inp {
  position: relative;
  .iconfont {
    position: absolute;
    color: #d9d9d9;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}

.add_form_close:hover {
  color: #000;
  font-weight: 600;
}

.add_formBox_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.add_formBox_radio {
  position: relative;
  right: -16px;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
</style>