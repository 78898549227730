<template>
  <div class="jiugonggeBox">
    <p class="jiugonggeBox_logo"><img :src="options.data.logo" alt=""></p>
    <p class="jiugonggeBox_txt text3">{{options.data.desc}}</p>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "jiugonggeBox",
  props: ["index"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.dibudaohang.data };
    },
  },
};
</script>

<style lang="less" scoped>
p {
  margin-bottom: 0;
}
.jiugonggeBox {  
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.jiugonggeBox_logo {
  max-width: 70%;
  text-align: center;
  img {
    width: 100%;
  }
}
.jiugonggeBox_txt {
  width: 100%;
  word-break: break-all;
}
</style>