<template>
  <div v-if="options&&options.typeof=='开关'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-switch v-model="formwork[index].options.value" :width="options.width"
        :active-icon-class="options.activeIconClass" :inactive-icon-class="options.inactiveIconClass"
        :active-value="options.activeValue" :inactive-value="options.inactiveValue" :active-color="options.activeColor"
        :inactive-color="options.inactiveColor" :active-text="options.activeText" :inactive-text="options.inactiveText"
        :name="options.name" :disabled="options.disabled" :validate-event="options.validateEvent">
      </a-switch>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"]),
    options() { 
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.switchOptions };
    },
    optionRules: {
      get() {
        let ruleArray = [];
        if (this.options.require) {
          ruleArray.splice(0, 0, {
            required: true,
            message: this.options.requireMsg
              ? this.options.requireMsg
              : "该内容不能为空",
            trigger: "change"
          });
        }
        if (this.options.reg !== "") {
          ruleArray.push({
            validator: (rule, value, callback) => {
              if (!eval(this.options.reg).test(value)) {
                callback(new Error(this.options.regMsg || ""));
                return;
              }
              callback();
            },
            trigger: "change"
          });
        }
        return ruleArray;
      }
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"])
  },
  watch: {
    optionRules() {
      this.keyValue += 1;
    }
  }
};
</script> 