<template>
  <div :key="index" class="videoBox">
    <img width="100%" :src="options.data.logo" alt="">
    <div class="videoIcon">
      <img width="40px" src="../../assets/play@2x.png" alt="">
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "videoBox",
  props: ["index"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.pageTitle.data };
    },
  },
  created() {
    console.log(this.okForm);
  },
  mounted() { },
  methods: {},
};
</script>

<style lang="less" scoped>
.videoBox {
  width: 100%;
  position: relative;
}
.videoIcon {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  text-align: center;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>