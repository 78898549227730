<template>
  <div>
    <div class="title">
      <span>会议页面</span>
      <a-button @click="showModal" type="primary"> 新增 </a-button>
    </div>

    <div v-if="visible" class="add add_pof">
      <div class="add_form add_pof">
        <div class="add_form_title">
          设置
          <a-icon @click="handleCancel" class="add_form_close" type="close" />
        </div>

        <div class="add_formBox">

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>页面内容</div>
            <div class="add_formBox_inp">
              <a-input class="suf_pr72" v-model="ruleForm.content " placeholder="请输入" :maxLength='100'
                :suffix="ruleForm.content.length+'/100'" />
            </div>
          </div>

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>页面名称</div>
            <div class="add_formBox_inp">
              <a-input class="suf_pr72" v-model="ruleForm.title" placeholder="请输入" :maxLength='100'
                :suffix="ruleForm.title.length+'/100'" />
            </div>
          </div>

          <div class="add_formBox_item add_formBox_flex">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>页面类型</div>
            <div class="add_formBox_inp">
              <a-radio-group class="add_formBox_radio" v-model="ruleForm.type" name="radioGroup" :default-value="1">
                <a-radio :value="1">
                  主页
                </a-radio>
                <a-radio :value="2">
                  内页
                </a-radio>
              </a-radio-group>
            </div>
          </div>

        </div>

        <div class="add_formBox_sendBox">
          <a-button @click="handleCancel" class="add_formBox_sendBox_qx">取消</a-button>
          <a-button @click="handleOk" type="primary">
            保存
          </a-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { setViewList } from "@/api/meetApi";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      ruleForm: {
        content: '',
        title: "",
        begin_time: null,
        end_time: null,
        type: "",
        meeting_id: this.$route.query.meeting_id,
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
    };
  },
  methods: {
    async change(data) {
      await this.clear();
      this.ruleForm = JSON.parse(JSON.stringify(data));
      this.ruleForm.meeting_id = this.$route.query.meeting_id;
      this.showModal();
    },
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      this.ruleForm.meeting_id = this.$route.query.meeting_id
      if (!this.ruleForm.title || !this.ruleForm.content || !this.ruleForm.type) {
        return this.$message.error("请全部填写完整！");
      }

      this.confirmLoading = true;
      try {
        let data = await setViewList(this.ruleForm);
        console.log(this.ruleForm);
        this.$emit("getList", { meeting_id: this.ruleForm.meeting_id });
        this.clear();
        this.visible = false;
        this.confirmLoading = false;
        this.$message.success("创建成功！");
        return this.$route.push(0)
      } catch {
        this.confirmLoading = false;
      }
    },
    handleCancel(e) {
      var _this = this;
      _this.visible = false;
      _this.clear();
      // for (const key in this.ruleForm) {
      //   if (this.ruleForm[key]) {
      //     return this.$confirm({
      //       title: "确定退出吗！",
      //       content: "退出不会保留修改内容！",
      //       okText: "确定",
      //       okType: "danger",
      //       cancelText: "取消",
      //       onOk() {
      //         _this.visible = false;
      //         _this.clear();
      //       },
      //       onCancel() { },
      //     });
      //   }
      // }
    },
    clear() {
      for (const key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  span {
    font-size: 24px;
    font-weight: 500px;
  }
}
.pr72px {
  padding-right: 72px;
}
:deep(.ant-calendar-picker) {
  width: 100%;
}
:deep(.ant-calendar-picker-icon) {
  // top: 30%;
}
.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 500px;
  height: 354px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform: translate(0%, -20%);
  bottom: 0;
  top: 0;
}
.add_form_title {
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    cursor: pointer;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px;
  padding-bottom: 0;
}
.add_formBox_name {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  margin-bottom: 10px;
}
.add_formBox_require {
  color: red;
  margin-right: 5px;
}
.add_formBox_item {
  margin-bottom: 20px;
}
.add_formBox_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.add_formBox_radio {
  position: relative;
  right: -16px;
}
.add_formBox_sendBox {
  flex: 1;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.add_formBox_time {
  width: 100%;
}
.add_formBox_inp {
  position: relative;
  .iconfont {
    position: absolute;
    color: #d9d9d9;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}

.add_form_close:hover {
  color: #000;
  font-weight: 600;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
</style>