<template>
  <div class="home">
    <!-- <div class="home_title">
      <div class="home_title_top">欢迎使用数会云</div>
      <div class="home_title_bot">欢迎使用数会云平台介绍</div>
    </div> -->
    <div class="home_data">
      <div class="home_data_left">
        <div class="home_DL_t">

          <div class="home_DLt_MH">
            <div class="home_DLt_MH_title">门户数据</div>
            <div class="home_DLt_MH_list">
              <div class="home_DLt_MH_item">
                <div class="home_DLt_MH_t">总门户数量</div>
                <div class="home_DLt_MH_c">{{HomeData.meetingTotal}}</div>
                <div class="home_DLt_MH_v">今日 +{{HomeData.todayTotal}}</div>
              </div>
              <div class="home_DLt_MH_item">
                <div class="home_DLt_MH_t">当前进行中</div>
                <div class="home_DLt_MH_c">{{HomeData.meetingPadding}}</div>
                <div class="home_DLt_MH_v">今日 +{{HomeData.todayPadding}}</div>
              </div>
              <div class="home_DLt_MH_item">
                <div class="home_DLt_MH_t">未开始</div>
                <div class="home_DLt_MH_c">{{HomeData.meetingNotStart}}</div>
                <div class="home_DLt_MH_v">今日 +{{HomeData.todayNotStart}}</div>
              </div>
            </div>
          </div>

          <div class="home_DLt_KJ">
            <div class="home_DLt_KJ_title">快捷功能</div>
            <div class="home_DLt_KJ_list">
              <div @click='addMHFN' class="home_DLt_KJ_item pointer">
                <div class="home_DLt_KJ_t">
                  <span><img src="@/assets/auto_img/icon_创建门户@2x.png" alt=""></span>
                </div>
                <div class="home_DLt_KJ_v">创建门户</div>
              </div>
              <div @click='addZBFN' class="home_DLt_KJ_item pointer">
                <div class="home_DLt_KJ_t">
                  <span><img src="@/assets/auto_img/icon_创建直播@2x.png" alt=""></span>
                </div>
                <div class="home_DLt_KJ_v">创建直播</div>
              </div>
              <div @click='addSPFN' class="home_DLt_KJ_item pointer">
                <div class="home_DLt_KJ_t">
                  <span><img src="@/assets/auto_img/icon_新建视频@2x.png" alt=""></span>
                </div>
                <div class="home_DLt_KJ_v">新建视频</div>
              </div>
              <div @click='addTWFN' class="home_DLt_KJ_item pointer">
                <div class="home_DLt_KJ_t">
                  <span><img src="@/assets/auto_img/icon_新建图文@2x.png" alt=""></span>
                </div>
                <div class="home_DLt_KJ_v">新建图文</div>
              </div>
            </div>
          </div>

        </div>

        <div class="home_DL_c">
          <div class="home_DLc_title">
            <div class="home_DLcT_l">门户访问量</div>
            <div class="home_DLcT_c">
              <div class="pointer" @click="div_ckFn(1)" :class="{div_ck: div_ckNum==1}">按日</div>
              <div class="pointer" @click="div_ckFn(2)" :class="{div_ck: div_ckNum==2}">按月</div>
              <div class="pointer" @click="div_ckFn(3)" :class="{div_ck: div_ckNum==3}">按年</div>
            </div>
            <div class="home_DLcT_r">
              <a-select class="home_DLcT_Select" v-model="div_ck_select" @change='div_ck_selectFn' placeholder="请选择">
                <a-select-option v-for="item in meetListData" :key="item.id" :value="item.id">
                  {{item.meeting_name||'未命名表单'}}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div ref="home_DLc_chart" class="home_DLc_chart"></div>
        </div>
        <div class="home_DL_b">
          <div class="home_DL_b_title">近期直播</div>
          <a-table class="p24" :scroll="{ x: 1200 }" :loading="loading" @change="video_handleTableChange"
            :pagination="video_pagination" :rowKey='record=>record.live_id' :columns="video_columns"
            :data-source="video_data">

            <span class="menu_name_box" slot="name" slot-scope="text, record">
              <img v-if="record.img" width="150px" height="85px" :src="record.img" alt="">
              <span class="menu_name">{{record.name}}</span>
            </span>

            <span slot="status" slot-scope="text, record">
              <span class="status status_wks" v-if="record.status==0"><img src="@/assets/auto_img/wks.png" alt="">
                未开始</span>
              <span class="status status_zbz" v-if="record.status==1"><img src="@/assets/auto_img/zbz.png" alt="">
                直播中</span>
              <span class="status status_yjs" v-if="record.status==2"><img src="@/assets/auto_img/yjs.png" alt="">
                已结束</span>
            </span>

            <span slot="type" slot-scope="text, record">
              <span v-if="record.type==1" class="page_type">直播</span>
              <span v-if="record.type==3" class="page_type">议程</span>
              <span v-if="record.type==2" class="page_type">视频</span>
              <span v-if="record.type==4" class="page_type">外链</span>
            </span>

            <span slot="begin_time" slot-scope="text, record">
              <span>{{ record.begin_time?record.begin_time: '未填'|time('YYYY.MM.DD HH:mm') }} </span>
            </span>

            <span slot="end_time" slot-scope="text, record">
              <span>{{ record.end_time?record.end_time: '未填'|time('YYYY.MM.DD HH:mm') }} </span>
            </span>

            <span slot="action" slot-scope="text, record">
              <a @click='video_workPlace(record)'> 配置 </a>
              <a-divider type="vertical" />
              <a @click="video_delate(text)"> 删除 </a>
            </span>
          </a-table>
        </div>

      </div>

      <div class="home_data_right">

        <div class="home_DR_t">
          <div class="home_DR_t_title">通知/公告</div>
          <div class="home_DR_t_list">
            <div v-for="item in 6" :key="item" class="home_DR_t_item text">
              <span v-if="item == 3" class="spanDen">异常</span>
              <span v-if="item == 1">通知</span>
              <span v-if="item == 2">公告</span>实时直播数据
            </div>
          </div>
        </div>

        <div class="home_DR_c">
          <img width="100%" height="100%" src="@/assets/aa.png" alt="">
        </div>

        <div class="home_DR_t">
          <div class="home_DR_t_title">使用帮助</div>
          <div class="home_DR_t_list">
            <div v-for="item in 6" :key="item" class="home_DR_t_item text"><img src="@/assets/auto_img/txt.png"
                alt="">怎么创建一场直播？怎么创建一场直播？怎么创建一场直播？</div>
          </div>
        </div>

      </div>
    </div>
    <addMH class="addMH" ref='addMH' />
    <addZB class="addZB" ref='addZB' />
    <addTW class="addTW" ref='addTW' />

  </div>
</template>

<script>
import { getMeetList, getHomeData, getHomeEcharts } from "@/api/meetApi";
import addMH from "@/views/menu/components/Modal.vue";
import addZB from "@/views/videoList/components/Modal.vue";
import addTW from "@/views/articleList/components/Modal.vue";
import * as echarts from 'echarts';
import { materialAll } from '@/api/meetApi.js';
const video_columns = [
  {
    title: "直播名称",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
    key: "title",
    width: 350,
    align: "left",
  },
  {
    title: "类型",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    key: "type",
    align: "left",
  },
  {
    title: "开始时间",
    key: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    align: "left",
  },
  {
    title: "结束时间",
    key: "end_time",
    scopedSlots: { customRender: "end_time" },
    align: "left",
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    key: "status",
    align: "left",
  },
  {
    title: "操作",
    key: "action",
    fixed: 'right',
    scopedSlots: { customRender: "action" },
    align: "left",
  },
];
export default {
  components: {
    addMH,
    addZB,
    addTW
  },
  data() {
    return {
      HomeData: {},
      HomeEcharts: {},
      meetListData: [],
      // 
      div_ckNum: 1,
      div_ck_select: '',
      Xdata: [],
      listData: [],
      chartInstance: null,
      // 
      // 
      // 
      loading: false,
      video_data: [],
      video_columns,
      video_pagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    }
  },
  created() {
    this.getVideList()
    this.SET_WEB_ROUTER(['首页'])
    this.getMeetListFn()
    this.getHomeDataFn()
  },
  mounted() {
    // this.initEcharts()
  },
  methods: {
    async getHomeDataFn() {
      let data = await getHomeData();
      this.HomeData = data.payload.meeting_count
    },
    async getHomeEchartsFn(id) {
      let data = await getHomeEcharts(id);
      this.HomeEcharts = data.payload.view
      this.div_ckFn(1)
    },
    addMHFN() {
      this.$refs.addMH.showModal()
    },
    addZBFN() {
      this.$refs.addZB.showModal('直播')
    },
    addSPFN() {
      this.$refs.addZB.showModal('视频')
    },
    addTWFN() {
      this.$refs.addTW.showModal()
    },
    async getMeetListFn() {
      let data = await getMeetList({ page: 1, per_page: 99999, keyword: '' });
      this.meetListData = data.payload.data;
      this.div_ck_select = this.meetListData[0].id
      this.getHomeEchartsFn({ meeting_id: this.meetListData[0].id })

    },
    getNextPowerOfTen(number) {
      // let length = number.toString().length;
      // let num = Math.pow(10, length);
      // if (number < num / 2) {
      //   return num / 2
      // }
      // return num
      let num = number.toString().slice(-2)
      return number + (150 - num)
    },
    initEcharts() {
      // 当数据过来后首先遍历，取出x轴和y轴数据
      // y轴遍历出最大数字 Math.max(..._this.listData)。然后向上取整（MaxNum * 100 / MaxNum）
      // 为了动态显示y轴的刻度，需要动态计算
      let _this = this;
      let MaxNum = this.getNextPowerOfTen(Math.max(..._this.listData))
      console.log(MaxNum, 'MaxNSum')
      this.chartInstance = echarts.init(this.$refs.home_DLc_chart);
      let option = {
        xAxis: {
          type: 'category',
          axisTick: { // 隐藏 x 轴的刻度标尺
            show: false
          },
          splitLine: { // 隐藏 x 轴的分割线
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#ccc',
              width: 1
            }
          },
          // 调整轴标签的样式
          axisLabel: {
            color: '#666', // 标签颜色
            fontSize: 12 // 标签字体大小
          },
          data: _this.Xdata
        },
        grid: {
          x: 50,
          y: 10,
          x2: 0,
          y2: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            // 自定义提示框内容，去除默认的圆点
            let result = '';
            params.forEach(function (item) {
              result += '<span style="font-weight: 400;font-size: 12px;color: #7F7F7F;">' + item.name + '</span><br/>' + '<span style="font-weight: 500;font-size: 14px;color: #222222;">访问人次：' + item.value + '</span>';
            });
            return result;
          }
        },
        yAxis: {
          type: 'value',
          min: 0, // 设置最小值
          max: MaxNum, // 设置最大值
          interval: MaxNum / 5, // 设置每个刻度之间的间隔
          nameGap: 30,
          nameLocation: 'middle',
        },
        series: [
          {
            data: _this.listData,
            type: 'line',
            lineStyle: { // 设置线条颜色
              color: '#2697ff' // 橙红色
            },
            smooth: true
          }
        ]
      };
      this.chartInstance.setOption(option)
    },
    div_ckFn(num) {
      this.div_ckNum = num
      if (num == 1) {
        this.Xdata = this.HomeEcharts[0].data.map(item => item.x_date);
        this.listData = this.HomeEcharts[0].data.map(item => item.y_view);
      }
      if (num == 2) {
        this.listData = this.HomeEcharts[1].data.map(item => item.y_view);
        this.Xdata = this.HomeEcharts[1].data.map(item => item.x_date);
      }
      if (num == 3) {
        this.listData = this.HomeEcharts[2].data.map(item => item.y_view);
        this.Xdata = this.HomeEcharts[2].data.map(item => item.x_date);
      }
      this.maxDate = Math.max(...this.listData);
      // 销毁图表实例
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }
      this.initEcharts()
    },
    div_ck_selectFn() {
      this.getHomeEchartsFn({ meeting_id: this.div_ck_select })
    },
    video_handleTableChange(data) {
      this.video_pagination.current = data.current
      this.getVideList()
    },
    video_workPlace(data) {
      this.$router.push("/videoSet?id=" + data.live_id);
    },
    video_delate(msg) {
      var _this = this;

      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let data = await delateVideo({
            live_id: msg.id
          })
          this.$message.success('删除成功！')
          _this.getVideList()
        },
        onCancel() { },
      });
    },
    goUrl(id) {
      this.$router.push('/videoSet?id=' + id)
    },
    async getVideList() {
      this.loading = true;

      let data = await materialAll({})
      this.video_data = data.payload.data
      // this.video_pagination.current = data.payload.page.current_page
      this.video_pagination.total = data.payload.page.total
      this.loading = false;

    },
  }
};
</script>

<style lang="less" scoped>
.home_title {
  padding-left: 24px;
  .home_title_top {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #222222;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    margin-top: 4px;
  }
  .home_title_bot {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    margin-bottom: 16px;
    margin-top: 4px;
  }
}
.home_data {
  display: flex;
  min-height: 500px;
  .home_data_left {
    width: 928px;
    margin-right: 12px;
  }
  .home_DL_t {
    display: flex;
    height: 186px;
    .home_DLt_MH {
      height: 186px;
      width: 458px;
      margin-right: 12px;
      background-color: #fff;
      border-radius: 4px;
      padding: 16px 24px;
    }
    .home_DLt_MH_list {
      display: flex;
      margin-top: 26px;
    }
    .home_DLt_MH_item {
      flex: 1;
    }
    .home_DLt_MH_title {
      font-size: 16px;
      color: #222222;
      font-weight: 600;
      line-height: 22px;
    }
    .home_DLt_MH_t {
      font-weight: 400;
      font-size: 14px;
      color: #86909d;
      line-height: 20px;
    }
    .home_DLt_MH_c {
      font-weight: bold;
      font-size: 28px;
      color: #222222;
      line-height: 34px;
      margin: 16px 0 8px 0;
    }
    .home_DLt_MH_v {
      font-weight: 400;
      font-size: 12px;
      color: #1890ff;
      line-height: 18px;
    }
    .home_DLt_KJ {
      height: 186px;
      background-color: #fff;
      padding: 16px 24px;
      border-radius: 4px;
      width: 458px;
    }
    .home_DLt_KJ_list {
      display: flex;
      margin-top: 36px;
    }
    .home_DLt_KJ_item {
      flex: 1;
    }
    .home_DLt_KJ_title {
      font-size: 16px;
      color: #222222;
      font-weight: 600;
      line-height: 22px;
    }
    .home_DLt_KJ_t {
      font-weight: 400;
      font-size: 14px;
      color: #86909d;
      text-align: center;
      line-height: 20px;
      span {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 6px;
        border: 1px solid #c0daf6;
        background: rgba(24, 144, 255, 0.1);
      }
      img {
        width: 24px;
        height: 24px;
        margin-top: 12px;
      }
    }
    .home_DLt_KJ_v {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
      text-align: center;
    }
  }
  .home_DL_c {
    padding: 16px 24px;
    margin-top: 12px;
    border-radius: 4px;
    width: 100%;
    height: 240px;
    background-color: #fff;
    .home_DLc_title {
      display: flex;
      margin-bottom: 24px;
      align-items: center;
      .home_DLcT_l {
        font-weight: 600;
        font-size: 16px;
        color: #222222;
        line-height: 22px;
      }
      .home_DLcT_c {
        display: flex;
        margin-left: 16px;
        div {
          width: 72px;
          height: 20px;
          border: 1px solid #e5e6eb;
          font-weight: 400;
          font-size: 12px;
          color: #222222;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div:nth-child(1) {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
        div:nth-child(3) {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
        .div_ck {
          color: #1890ff;
          border: 1px solid #1890ff;
        }
      }
      .home_DLcT_r {
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;
        display: flex;
      }
      .home_DLcT_Select {
        width: 320px;
        height: 30px;
      }
      :deep(.ant-select-selection) {
        height: 30px !important;
      }
      :deep(.ant-select-selection__rendered) {
        line-height: 30px !important;
      }
    }
    .home_DLc_chart {
      height: 150px;
    }
  }
  .home_DL_b {
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    margin-top: 12px;
    padding: 16px 24px;
    .home_DL_b_title {
      font-weight: 600;
      font-size: 16px;
      color: #222222;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
  .home_data_right {
    width: 296px;
  }
  .home_DR_t {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: #fff;
    height: 288px;
    padding: 16px 24px;
    .home_DR_t_title {
      font-weight: 600;
      font-size: 16px;
      color: #222222;
      border-bottom: 1px solid #eff0f2;
      line-height: 22px;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
    .home_DR_t_list {
    }
    .home_DR_t_item {
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      margin-bottom: 16px;
      span {
        width: 30px;
        height: 14px;
        display: inline-block;
        font-weight: 400;
        font-size: 11px;
        color: #1890ff;
        background: rgba(24, 144, 255, 0.1);
        border-radius: 1px;
        border: 1px solid rgba(24, 144, 255, 0.2);
        text-align: center;
        line-height: 14px;
        margin-right: 8px;
        position: relative;
        top: -1px;
      }
      .spanErr {
      }
      .spanDen {
        background: rgba(235, 93, 68, 0.1);
        color: #eb5d44;
        border-radius: 1px;
        border: 1px solid rgba(235, 93, 68, 0.2);
      }
      img {
        width: 10.58px;
        margin-right: 6px;
        position: relative;
        top: -1px;
      }
    }
  }
  .home_DR_c {
    margin-bottom: 12px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    height: 120px;
  }
  .home_DR_v {
    margin-bottom: 12px;
    background-color: #fff;
    width: 100%;
    border-radius: 4px;
    height: 288px;
  }
}

.status {
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.status_wks {
  border: 1px solid #2bb840;
  color: #2bb840;
}
.status_zbz {
  border: 1px solid #1890ff;
  color: #1890ff;
}
.status_yjs {
  border: 1px solid #eb5d44;
  color: #eb5d44;
}
.home {
  padding: 0 12px;
  width: 1260px;
  margin: auto;
  padding-bottom: 12px;
}

.addMH :deep(.title) {
  display: none;
}
.addZB :deep(.title) {
  display: none;
}
.addTW :deep(.title) {
  display: none;
}
</style>
<style>
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 8px;
  overflow-wrap: break-word;
}
</style>