<template>
  <div v-if="options&&options.typeof=='select'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-select v-model="formwork[index].options.value" :maxlength="options.multiple" :disabled="options.disabled"
        :clearable="options.clearable" :filterable="options.filterable" :placeholder="options.placeholder"
        :multiple="options.multiple">

        <a-select-option v-for="item in options.optionsArray" :key="'item'+item" :value="''+item">
          {{item}}
        </a-select-option>
      </a-select>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"]),
    options() { 
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.select };
    },
  },
};
</script> 