<template>
  <a-modal
    v-model="show"
    title="表单保存"
    :destroyOnClose="true"
    :zIndex="zindex"
    centered
    @ok="submitForm"
  >
    <a-form-model
      ref="modelForm"
      :model="modelForm"
      labelAlign="left"
      v-bind="{
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      }"
    >
      <a-form-model-item
        label="项目名称"
        prop="name"
        :rules="[
          {
            required: true,
            message: '项目名称不能为空',
            trigger: 'blur'
          }
        ]"
      >
        <a-input v-model="modelForm.name" />
      </a-form-model-item>
      <a-form-model-item
        label="表单提交路径"
        prop="url"
        :rules="[
          {
            required: true,
            message: '表单提交路径不能为空',
            trigger: 'blur'
          }
        ]"
      >
        <a-input v-model="modelForm.url" />
      </a-form-model-item>
      <a-form-model-item label="是否需要验证" prop="valid">
        <a-switch v-model="modelForm.valid" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { formsave }  from "@/api/form.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      modelForm: {},
      zindex: 1002
    };
  },
  props: ["modelFormDialog"],
  methods: {
    submitForm() {
      this.$refs.modelForm.validate(async valid => {
        if (valid) {
          this.modelForm.data = JSON.parse(JSON.stringify(this.formwork));
          let res = await formsave(this.modelForm);
          if (res.status === 0) {
            this.$message.success(res.msg);
            this.$emit("cancleMode", false);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    }
  },
  computed: {
    ...mapGetters(["formwork"]),
    show: {
      get() {
        return this.modelFormDialog;
      },
      set() {
        this.$emit("cancleMode", false);
      }
    }
  }
};
</script>

<style scoped></style>
