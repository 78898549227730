<template>
  <div class="tuwen">
    <div class="tuwen_title">
      <p>
        <span>选择模板</span>
      </p>
      <div class="DF_Base">
        <div class="DF_item">
          <div :class="{check2: form.data.pageCss.showType==1}" @click="changeBtn(1)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/videolist (3).png" alt="">
            <img class="DF_item_check" v-if="form.data.pageCss.showType==1" width="20px"
              src="@/assets/auto_img/等分 (4).png" alt="">
          </div>
          <div class="DF_item_title">缩略图列表</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.pageCss.showType==2}" @click="changeBtn(2)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/videolist (1).png" alt="">
            <img class="DF_item_check" v-if="form.data.pageCss.showType==2" width="20px"
              src="@/assets/auto_img/等分 (4).png" alt="">
          </div>
          <div class="DF_item_title">仅标题列表</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.pageCss.showType==3}" @click="changeBtn(3)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/videolist (2).png" alt="">
            <img class="DF_item_check" v-if="form.data.pageCss.showType==3" width="20px"
              src="@/assets/auto_img/等分 (4).png" alt="">
          </div>
          <div class="DF_item_title">大图列表</div>
        </div>
      </div>
    </div>

    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">样式调整</span></span>
    </p>

    <div v-show="btnTypeNum==1" class="imgList">

      <div v-for="(item,index) in form.data.menuArr" :key="index" class="choiceImg_base">
        <div @click="close(item,index)" class="choiceImg_base_close pointer">
          <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
        </div>

        <div class="choiceImg_base_txi">
          <a-input style="height:100%" @change="chnageVuex" v-model="item.name" placeholder="输入标题（后台使用）" />
        </div>

        <div class="list_name_title">
          <div class="list_name_data">

            <div class="list_name_dataItem">
              <div>未选中按钮：</div>
              <div class="list_name_ImgUP">
                <div @click="closeUpload(index)" class="choiceImg_base_close2 pointer">
                  <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
                </div>
                <span v-if="!item.src" class="choiceImg_base_Noimg">
                  <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                  添加图片
                </span>
                <img class="choiceImg_base_Show" v-if="item.src" :src="item.src" alt="">
                <upload class="choiceImg_base_poa" @uploadOk='uploadOk($event, index)' :image='item.src'
                  :height="'19px'" :width='"auto"' />
              </div>
            </div>

            <div class="list_name_dataItem">
              <div>选中按钮：</div>
              <div class="list_name_ImgUP">
                <div @click="closeUpload2(index)" class="choiceImg_base_close2 pointer">
                  <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
                </div>
                <span v-if="!item.src_1" class="choiceImg_base_Noimg">
                  <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                  添加图片
                </span>
                <img class="choiceImg_base_Show" v-if="item.src_1" :src="item.src_1" alt="">
                <upload class="choiceImg_base_poa" @uploadOk='uploadOk2($event, index)' :image='item.src_1'
                  :height="'19px'" :width='"auto"' />
              </div>
            </div>

          </div>
        </div>

        <div class="add_typeMR">
          <div>默认选中</div><a-switch size='small' v-model="item.checkTab" @change="is_sign_in_onChange(index,$event)" />
        </div>

        <div class="add_type">
          <div>添加内容</div>
          <a-radio-group name="radioGroup" @change="changeAddType(item)" v-model="item.table_data.type"
            :default-value="'second_videoList'">
            <a-radio :value="'second_videoList'">
              直播列表
            </a-radio>
            <a-radio :value="'shipinheji'">
              视频列表
            </a-radio>
            <a-radio :value="'second_articleList'">
              图文列表
            </a-radio>
            <a-radio :value="'articleBox'">
              图文
            </a-radio>
          </a-radio-group>
        </div>

        <!-- 图文盒子 -->
        <div class="articleBox_item" v-if="item.table_data.type=='articleBox'">
          <Editor :form='{ data: { desc: item.table_data.options.data.desc } }' @input='input($event,item)'
            :key="'Aeditor'+index"></Editor>
        </div>
        <!-- 直播列表盒子 -->
        <div class="second_videoList_item" v-if="item.table_data.type=='second_videoList'">
          <div class="list_name_title">
            <div class="list_name_radio">排序方式</div>
            <div class="list_name_titleDate">
              <a-radio-group v-model="item.table_data.options.data.sort_type" @change="onChange">
                <a-radio :value="1">
                  自动排序
                </a-radio>
                <a-radio :value="2">
                  手动排序
                </a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="list_name_data">
            <div class="list_name_status">状态标签</div>
            <div class="list_name_data">

              <div class="list_name_dataItem2">
                <div>未开始：</div>
                <div class="list_name_ImgUP">
                  <div @click="closeStatus(item,2)" class="choiceImg_base_close2 pointer">
                    <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
                  </div>
                  <span v-if="!item.table_data.options.data.beginImg" class="choiceImg_base_Noimg">
                    <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                    添加图片
                  </span>
                  <img class="choiceImg_base_Show" v-if="item.table_data.options.data.beginImg"
                    :src="item.table_data.options.data.beginImg" alt="">
                  <upload class="choiceImg_base_poa" @uploadOk='uploaStatusk($event,item)'
                    :image='item.table_data.options.data.beginImg' :height="'19px'" :width='"auto"' />
                </div>
              </div>

              <div class="list_name_dataItem2">
                <div>直播中：</div>
                <div class="list_name_ImgUP">
                  <div @click="closeStatus(item,3)" class="choiceImg_base_close2 pointer">
                    <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
                  </div>
                  <span v-if="!item.table_data.options.data.doingImg" class="choiceImg_base_Noimg">
                    <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                    添加图片
                  </span>
                  <img class="choiceImg_base_Show" v-if="item.table_data.options.data.doingImg"
                    :src="item.table_data.options.data.doingImg" alt="">
                  <upload class="choiceImg_base_poa" @uploadOk='uploadStatus2($event,item)'
                    :image='item.table_data.options.data.doingImg' :height="'19px'" :width='"auto"' />
                </div>
              </div>

              <div class="list_name_dataItem2">
                <div>已结束：</div>
                <div class="list_name_ImgUP">
                  <div @click="closeStatus(item,4)" class="choiceImg_base_close2 pointer">
                    <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
                  </div>
                  <span v-if="!item.table_data.options.data.endImg" class="choiceImg_base_Noimg">
                    <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                    添加图片
                  </span>
                  <img class="choiceImg_base_Show" v-if="item.table_data.options.data.endImg"
                    :src="item.table_data.options.data.endImg" alt="">
                  <upload class="choiceImg_base_poa" @uploadOk='uploadStatus3($event,item)'
                    :image='item.table_data.options.data.endImg' :height="'19px'" :width='"auto"' />
                </div>
              </div>

              <div class="list_name_dataItem2">
                <div>详情：</div>
                <div class="list_name_ImgUP">
                  <div @click="closeStatus(item,5)" class="choiceImg_base_close2 pointer">
                    <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
                  </div>
                  <span v-if="!item.table_data.options.data.detailsImg" class="choiceImg_base_Noimg">
                    <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                    添加图片
                  </span>
                  <img class="choiceImg_base_Show" v-if="item.table_data.options.data.detailsImg"
                    :src="item.table_data.options.data.detailsImg" alt="">
                  <upload class="choiceImg_base_poa" @uploadOk='uploadStatus5($event,item)'
                    :image='item.table_data.options.data.detailsImg' :height="'19px'" :width='"auto"' />
                </div>
              </div>

            </div>
          </div>

          <a-button size="large" class="addBanner" @click="showgiveLive(item,index)" block type="link">
            点击选择
          </a-button>

          <div v-if="item.table_data.options.data.menuArr[0]" class="list_name_title">
            <div class="list_name_radio">已选直播</div>
            <div class="list_name_titleDate">

              <draggable class="draggableBox" animation="1000" @end="endEventFN"
                v-model="item.table_data.options.data.menuArr">
                <div v-for="(j,index) in item.table_data.options.data.menuArr" :key="j.id" class="video_S_list">
                  <div class="video_S_title text3">{{j.title}}</div>
                  <div class="video_S_dataBox">
                    <!-- <div class="video_S_time">{{title.begin_time|time('YYYY-MM-DD HH:mm')}}</div> -->
                    <div @click="goSet(j)" class="video_S_PZ pointer">配置</div>
                    <div class="video_S_Img pointer">
                      <img @click="delateItem(item,index)" src="@/assets/base_img/video_delate.png" alt="">
                    </div>
                    <div class="video_S_Img pointer">
                      <img src="@/assets/base_img/移动@2x.png" alt="">
                    </div>
                  </div>
                </div>
              </draggable>

            </div>
          </div>

        </div>
        <!-- 视频列表盒子 -->
        <div class="shipinheji_item" v-if="item.table_data.type=='shipinheji'">
          <a-button size="large" class="addBanner" @click="showgiveVideo(item,index)" block type="link">
            点击选择
          </a-button>

          <div v-if="item.table_data.options.data.menuArr[0]" class="list_name_title">
            <div class="list_name_radio">已选视频</div>
            <div class="list_name_titleDate">

              <draggable class="draggableBox" animation="1000" @end="endEventFN"
                v-model="item.table_data.options.data.menuArr">
                <div v-for="(j,index) in item.table_data.options.data.menuArr" :key="j.id" class="video_S_list">
                  <div class="video_S_title text3">{{j.title}}</div>
                  <div class="video_S_dataBox">
                    <!-- <div class="video_S_time">{{title.begin_time|time('YYYY-MM-DD HH:mm')}}</div> -->
                    <div @click="goSet(j)" class="video_S_PZ pointer">配置</div>
                    <div class="video_S_Img pointer">
                      <img @click="delateItem(item,index)" src="@/assets/base_img/video_delate.png" alt="">
                    </div>
                    <div class="video_S_Img pointer">
                      <img src="@/assets/base_img/移动@2x.png" alt="">
                    </div>
                  </div>
                </div>
              </draggable>

            </div>
          </div>
        </div>
        <!-- 图文列表盒子 -->
        <div class="second_articleList_item" v-if="item.table_data.type=='second_articleList'">
          <a-button size="large" class="addBanner" @click="showarticle(item,index)" block type="link">
            选择图文
          </a-button>

          <div v-if="item.table_data.options.data.menuArr[0]" class="list_name_title">
            <div class="list_name_radio">已选图文</div>
            <div class="list_name_titleDate">

              <draggable class="draggableBox" animation="1000" @end="endEventFN"
                v-model="item.table_data.options.data.menuArr">
                <div v-for="(j,index) in item.table_data.options.data.menuArr" :key="j.id" class="video_S_list">
                  <div class="video_S_title text3">{{j.title}}</div>
                  <div class="video_S_dataBox">
                    <!-- <div class="video_S_time">{{title.begin_time|time('YYYY-MM-DD HH:mm')}}</div> -->
                    <div @click="goSetArt(j)" class="video_S_PZ pointer">配置</div>
                    <div class="video_S_Img pointer">
                      <img @click="delateItem(item,index)" src="@/assets/base_img/video_delate.png" alt="">
                    </div>
                    <div class="video_S_Img pointer">
                      <img src="@/assets/base_img/移动@2x.png" alt="">
                    </div>
                  </div>
                </div>
              </draggable>

            </div>
          </div>
        </div>

      </div>

      <a-button size="large" class="addBanner" @click="addList" block type="link">
        添加Tab
      </a-button>

    </div>

    <div v-show="btnTypeNum==2" class="cssChange">

      <div class="tab_class_base">
        <div @click='chnageTanClassFn(1)' :class="{checkTabClass: chnageTanClass==1}" class="tab_class_item">Tab按钮样式
        </div>
        <div @click='chnageTanClassFn(2)' :class="{checkTabClass: chnageTanClass==2}" class="tab_class_item">内容样式</div>
      </div>

      <div v-show="chnageTanClass==1" class="">
        <p class="page_tap_css">
          <span class="pageCss_title">Tab高度：<span style="opacity:0">巨</span></span>
          <a-button-group>
            <a-input-number @change='onChange' v-model="form.data.pageCss.tabHeight" :min="40" :max="100" />
          </a-button-group>
        </p>
        <pageCss :propCss="form.data.pageCss" @giveCss='giveCss'></pageCss>
      </div>
      <div v-show="chnageTanClass==2" class="">
        <!-- tab 内容样式 因为tab最低保留两个。所以此处取第一个tab的pageCss作为所有的公用样式-->
        <!-- 目前最佳解决方式 -->
        <pageCss :propCss="form.data.menuArr[0].table_data.options.data.pageCss" @giveCss='giveCssT'></pageCss>
      </div>

    </div>

    <setVideo ref="setVideo" @giveVideo='giveVideo' />
    <set_s_article ref="set_s_article" @giveVideo='article' />
  </div>
</template>

<script>
import pageCss from './pageCss.vue';
import Editor from "@/components/Editor.vue";
import upload from "@/components/upload.vue";
import setVideo from './setVideo.vue'
import set_s_article from './set_s_article.vue'
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { pageCss, upload, Editor, setVideo, set_s_article, draggable },
  name: "tuwendaohang",
  data() {
    return {
      selectBum: '',
      setJumpNum: '',
      num: 3,
      btnTypeNum: 1,
      chnageTanClass: 1,
      form: {}
    };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageTanClassFn(num) {
      this.chnageTanClass = num
    },
    goSet(item) {
      window.open(`/#/videoSet?id=${item.live_id}`);
    },
    goSetArt(item) {
      window.open(`/#/articleSet?id=${item.document_id}`);

    },
    delateItem(item, index) {
      item.table_data.options.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    giveVideo(data) {
      this.form.data.menuArr[this.selectBum].table_data.options.data.menuArr = data
      console.log(this.form)
      this.chnageVuex();
    },
    showgiveVideo(item, index) {
      this.selectBum = index
      // 1普通直播，2，视频，3议程 4外链
      this.$refs.setVideo.showModal(item.table_data.options.data.menuArr, 2);
    },
    giveLive(data) {
      this.form.data.menuArr[this.selectBum].table_data.options.data.menuArr = data
      console.log(this.form)
      this.chnageVuex();
    },
    showgiveLive(item, index) {
      this.selectBum = index
      // 1普通直播，2，视频，3议程 4外链
      this.$refs.setVideo.showModal(item.table_data.options.data.menuArr, 1);
    },
    onChange() {
      this.chnageVuex();
    },
    endEventFN(argument) {
      this.chnageVuex();
    },
    article(data) {
      this.form.data.menuArr[this.selectBum].table_data.options.data.menuArr = data
      console.log(this.form)
      this.chnageVuex();
    },
    showarticle(item, index) {
      this.selectBum = index
      this.$refs.set_s_article.showModal(item.table_data.options.data.menuArr, 1);
    },
    input(str, item) {
      item.table_data.options.data.desc = str
      this.chnageVuex();
    },
    changeBtn(num) {
      this.form.data.pageCss.showType = num;
      console.log(this.form);
      this.chnageVuex();
    },
    addList() {
      let pustData = {
        type: "articleBox",
        name: "图文",
        icon: "icon-article",
        img: require('@/assets/base_img/icon_图文@2x.png'),
        options: JSON.parse(JSON.stringify(this.$store.state.workPlace.articleBox))
      }
      this.form.data.menuArr.push({
        blank: true,
        checkTab: false,
        name: '导航',
        is_outside_url: 1,
        table_data: pustData
      },);
      this.chnageVuex();
    },
    is_sign_in_onChange(index, event) {
      this.form.data.menuArr.forEach((i, num) => {
        if (index != num) {
          i.checkTab = false
        }
      })
      this.chnageVuex();
    },
    changeAddType(item) {
      console.log(this.okForm)
      let obj = {}
      if (item.table_data.type == 'articleBox') {
        obj = {
          type: "articleBox",
          name: "图文",
          icon: "icon-article",
          img: require('@/assets/base_img/icon_图文@2x.png'),
          options: JSON.parse(JSON.stringify(this.$store.state.workPlace.articleBox))

        }
        item.table_data = obj
      }
      if (item.table_data.type == 'second_videoList') {
        obj = {
          type: "second_videoList",
          name: "直播列表",
          icon: "icon-erji-wangluotuopu",
          img: require('@/assets/base_img/icon_视频列表@2x.png'),
          options: JSON.parse(JSON.stringify(this.$store.state.workPlace.second_videoList))
        }
        item.table_data = obj
      }
      if (item.table_data.type == 'shipinheji') {
        obj = {
          type: "shipinheji",
          name: "视频列表",
          icon: "icon-shipinheji",
          img: require('@/assets/base_img/icon_二级视频@2x.png'),
          options: JSON.parse(JSON.stringify(this.$store.state.workPlace.shipinheji))
        }
        item.table_data = obj
      }
      if (item.table_data.type == 'second_articleList') {
        obj = {
          type: "second_articleList",
          name: "图文列表",
          icon: "icon-erji-ziyuanji",
          img: require('@/assets/base_img/icon_二级日程@2x.png'),
          options: JSON.parse(JSON.stringify(this.$store.state.workPlace.second_articleList))
        }
        item.table_data = obj
      }
      this.chnageVuex();
    },
    btnTypeFn(num) {
      this.btnTypeNum = num;
      this.chnageVuex();
    },
    close(item, index) {
      if (this.form.data.menuArr.length <= 2) {
        return this.$message.error('Tab少保留两个')
      }
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
      this.$emit("changeWindow");
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },
    giveCssT(data) {
      // this.form.data.menuArr[0].table_data.options.data.pageCss = data;
      this.form.data.menuArr.forEach(element => {
        element.table_data.options.data.pageCss = data
      });
      this.chnageVuex();
    },
    uploadOk(data, index) {
      console.log(data);
      console.log(index);
      console.log(this.form.data.menuArr[index]);
      // 删除未选中按钮
      this.form.data.menuArr[index].src = data.payload.path
      this.form.data.menuArr[index].image_id = data.payload.id;
      this.chnageVuex();
    },
    closeUpload(index) {
      // 上传未选中按钮
      this.form.data.menuArr[index].image_id = ''
      this.form.data.menuArr[index].src = ''
      console.log(this.form.data.menuArr[index])
      this.chnageVuex();
    },
    uploadOk2(data, index) {
      // 删除选中按钮
      this.form.data.menuArr[index].src_1 = data.payload.path
      this.form.data.menuArr[index].image_1_id = data.payload.id;
      this.chnageVuex();
    },
    closeUpload2(index) {
      // 上传选中按钮
      this.form.data.menuArr[index].image_1_id = ''
      this.form.data.menuArr[index].src_1 = ''
      console.log(this.form.data.menuArr[index])
      this.chnageVuex();
    },
    uploaStatusk(data, item) {
      item.table_data.options.data.beginImg = data.payload.path

      console.log(item);
      this.chnageVuex();
    },
    uploadStatus2(data, item) {
      item.table_data.options.data.doingImg = data.payload.path
      this.chnageVuex();
    },
    uploadStatus3(data, item) {
      item.table_data.options.data.endImg = data.payload.path
      this.chnageVuex();
    },
    uploadStatus5(data, item) {
      item.table_data.options.data.detailsImg = data.payload.path
      this.chnageVuex();
    },
    closeStatus(item, index) {
      if (index == 2) {
        item.table_data.options.data.beginImg = ''
      }
      if (index == 3) {
        item.table_data.options.data.doingImg = ''
      }
      if (index == 4) {
        item.table_data.options.data.endImg = ''
      }
      if (index == 5) {
        item.table_data.options.data.detailsImg = ''
      }
      this.chnageVuex();
    }
  },
};
</script>

<style lang="less" scoped>
.video_S_list {
  margin-bottom: 12px;
  background: #fff;
  border-radius: 2px;
  padding: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  .video_S_title {
    margin-bottom: 8px;
  }
  .video_S_dataBox {
    display: flex;
    height: 20px;
    div {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  }
  .video_S_time {
    font-size: 12px;
    color: #86909d;
    margin-right: 8px;
  }
  .video_S_PZ {
    flex: 1;
    color: #1890ff;
  }
  .video_S_Img {
    margin-right: 12px;
    img {
      width: 16px;
    }
  }
  .video_S_Img {
    img {
      width: 16px;
    }
  }
}
.list_name_radio {
  margin-top: 12px;
  margin-bottom: 6px;
}
.articleBox_item {
  margin-top: 12px;
  height: 500px;
}
.second_videoList_item {
}
.shipinheji_item {
}
.second_articleList_item {
}
.list_name_data {
  background: #f9fafc;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
}
.list_name_dataItem {
  flex: 1;
  margin-right: 12px;
}
.list_name_dataItem2 {
  width: calc(50% - 6px);
}
.list_name_dataItem2:nth-child(2n) {
  margin-left: 12px;
}
.list_name_status {
  margin-top: 12px;
  margin-bottom: 6px;
}
.list_name_dataItem:last-child {
  margin-right: 0;
}
.list_name_ImgUP {
  position: relative;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e5e6eb;
  margin-top: 8px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choiceImg_base_top {
  height: 36px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
}
.choiceImg_base_up {
  width: 96px;
  margin-right: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.choiceImg_base_Noimg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #86909d;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  img {
    margin-right: 4px;
  }
}
.choiceImg_base_Show {
  width: auto;
  max-width: 90px;
  height: 30px;
}
.choiceImg_base_poa {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  min-width: 100%;
  min-height: 40px;
  opacity: 0;
  overflow: hidden;
}

.choiceImg_base_txi {
  flex: 1;
}

.DF_Base {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.DF_item {
  width: 84px;
  margin-right: 14px;
}
.DF_item:nth-of-type(3n) {
  margin-right: 0;
}
.DF_item_IMG {
  position: relative;
  border-radius: 4px;
}
.DF_item_check {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.DF_item_title {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  margin: 8px 0 16px 0;
  text-align: center;
}
.check2 {
  border: 1px solid #1890ff !important;
  color: #1890ff;
  i {
    color: #1890ff !important;
  }
}
.choiceImg_base {
  position: relative;
  background: #f9fafc;
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 20px;
}
.choiceImg_base_top {
  height: 36px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
}
.choiceImg_base_up {
  width: 96px;
  margin-right: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.choiceImg_base_Noimg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #86909d;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  img {
    margin-right: 4px;
  }
}
.tab_class_base {
  display: flex;
  cursor: pointer;
  height: 32px;
  text-align: center;
  justify-content: center;
}
.tab_class_item {
  flex: 1;
  border: 1px solid #e5e6eb;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.checkTabClass {
  color: #1890ff !important;
  border: 1px solid #1890ff !important ;
}
.choiceImg_base_Show {
  width: auto;
  max-width: 90px;
  height: 30px;
}
.choiceImg_base_txi {
  flex: 1;
  height: 36px;
  margin-bottom: 12px;
}
.set_jump_my {
  position: relative;
  textarea {
    padding-right: 20px;
    text-align: justify;
  }
  img {
    z-index: 2;
    position: absolute;
    width: 16px;
    right: 8px;
    bottom: 0;
    top: 0;
    margin: auto;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }
}
.choiceImg_base_poa {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.choiceImg_base_close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  display: none;
}
.choiceImg_base_up:hover {
  .choiceImg_base_imgDelate {
    display: block;
  }
}

.choiceImg_base_close2 {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  display: none;
  z-index: 9;
}
.list_name_ImgUP:hover {
  .choiceImg_base_imgDelate2 {
    display: block;
  }
}

.choiceImg_base_imgDelate {
  position: absolute;
  top: -8px;
  width: 16px;
  height: 16px;
  display: none;
  left: 83px;
  z-index: 2;
  display: none;
}

.choiceImg_base:hover {
  .choiceImg_base_close {
    display: block;
  }
}

.list_name_ImgUP:hover {
  .choiceImg_base_close2 {
    display: block;
  }
}

.choiceImg_base_top:hover {
  .choiceImg_base_imgDelate {
    display: block;
  }
}
.set_jump {
  display: flex;
  justify-content: space-between;
  .ant-radio-group {
    position: relative;
    right: -12px;
  }
}
.set_jump_form {
  margin-top: 10px;
}
.set_jump_btn {
  height: 36px;
  background: #f8f9fc;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DF_Base {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.DF_item {
  width: 84px;
  margin-right: 14px;
}
.DF_item:nth-of-type(3n) {
  margin-right: 0;
}
.DF_item_IMG {
  position: relative;
  border-radius: 4px;
}
.DF_item_check {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.DF_item_title {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  margin: 8px 0 16px 0;
  text-align: center;
}
.check2 {
  border: 1px solid #1890ff !important;
  color: #1890ff;
}
.tuwen_title {
  padding: 21px;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  padding-bottom: 0;
}
.lineBtn {
  width: 100%;
  button {
    font-weight: 800;
    margin-left: 0 !important;
  }
}
.imgList {
  padding: 21px;
}
.choiceImg:first-child {
  margin-top: 0;
}
.choiceImg {
  padding: 12px;
  margin-top: 20px;
  min-height: 100px;
  // border: 1px solid #ccc;
  position: relative;
  background: #f9fafc;
  border-radius: 2px;
  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: #ccc;
    display: none;
  }
  p {
    margin-top: 10px !important;
  }
}
.choiceImg:hover {
  .close {
    display: block;
  }
}
.addBanner {
  margin-top: 20px;
  border: 1px dashed #1890ff;
}
.cssChange {
  padding: 24px;
  p {
    margin-bottom: 15px !important;
    span {
      // margin-right: 15px;
    }
  }
}
.cssChange_check {
  background-color: #1890ff !important;
  color: white;
}
.choiceImg_up {
  display: flex;
}
.choiceImg_up_left {
  width: 96px;
  height: 36px;
}
.choiceImg_up_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 0 !important;
    display: flex;
    button {
      padding-left: 0;
    }
    input {
      flex: 1;
    }
    span {
      margin-left: 12px;
      width: 42px;
      line-height: 32px;
    }
  }
}
.attcion {
  display: block;
  font-size: 12px;
  color: #ccc;
}
.inp_color {
  width: 50px;
}
.add_typeMR {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
</style> 
<style>
.add_type .ant-radio-wrapper {
  margin-top: 6px;
}
</style>
 

<style>
.page_tap_css p {
  margin-bottom: 12px;
}
.page_tap_css .attcion {
  display: block;
  font-size: 12px;
  color: #ccc;
}
.page_tap_css .inp_color {
  width: 50px;
}

.page_tap_css .ant-input-number {
  width: 50px;
  margin-right: 10px;
}
.page_tap_css .ant-slider {
  width: 100%;
}
.page_tap_css .ant-btn-group {
  width: 100%;
}
.page_tap_css ::placeholder {
  color: #ccc;
}

.pageCss_title {
  display: block;
  margin: 12px 0;
}
#color {
  border-radius: 0;
}
</style>
<style>
.page_tap_css .ant-input-number {
  width: 60px;
  margin-right: 10px;
}

.page_tap_css .ant-input-number-handler-wrap {
  opacity: 1;
  background: #f5f7fa;
}
.page_tap_css .ant-input-number {
  border-radius: 0;
}
textarea.ant-input {
  height: auto !important;
}
</style>