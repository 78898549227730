<template>
  <a-upload v-if="reload" name="file" :customRequest='uploadFn' list-type="picture-card" class="avatar-uploader"
    :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
    <img class="upImg" :width='width' :height="height" v-if="imageUrl" :src="imageUrl" alt="file" />
    <div class="up_icon" v-else>
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">
        上传图片
      </div>
      <div v-if="action" class="ant-upload-action">
        {{action}}
      </div>
    </div>
    <!-- <span class="loding"><a-progress :percent="50" :show-info="false" /></span> -->
    <div @click.stop="delate" v-if="imageUrl&&showDelate" class="delate"><a-icon type="close-circle" /></div>
  </a-upload>
</template>

<script>
import { uploadFn } from "@/api/upload";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  props: {
    image: { type: String, default: "" },
    width: { type: String, default: "auto" },
    height: { type: String, default: "auto" },
    showDelate: { type: Boolean, default: false },
    action: { type: String, default: "" },
  },
  data() {
    return {
      reload: true,
      loading: false,
      imageUrl: "",
      file: {},
    };
  },
  created() {
    this.imageUrl = this.image;
    console.log(this.image);
  },
  methods: {
    handleChange(info) {
      this.file = info;
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("仅支持jpeg或png图片！");
      }
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isLt2M) {
        this.$message.error("图片大小不超过8M！");
      }
      return isJpgOrPng && isLt2M;
    },
    async uploadFn(data) {
      let str = await uploadFn({ file: data.file });
      this.file.file.status = "done";
      this.handleChange(this.file);
      this.$emit("uploadOk", str);
    },
    delate() {
      this.imageUrl = ''
      this.$emit('delateDate')
    },
    reloadFn() {
      this.imageUrl = ''
      this.reload = false
      let _this = this
      setTimeout(() => {
        this.imageUrl = this.image;
        this.reload = true
      }, 100)
    }
  },
};
</script>

<style>
.upImg {
  max-width: 100%;
  max-height: calc(100% - 20px);
}
.avatar-uploader {
  width: 100%;
  height: 100%;
  min-width: 75px;
  min-height: 75px;
  position: relative;
}
.loding {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.avatar-uploader > .ant-upload {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #585858;
  line-height: 20px;
  text-align: center;
  font-style: normal;
}
.ant-upload-action {
  font-weight: 400;
  font-size: 12px;
  color: #585858;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  margin-top: 4px;
}
.up_icon {
  font-size: 12px;
}
.avatar-uploader > .ant-upload {
  min-height: 75px;
}
.delate {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #999;
  opacity: 0;
}

.delate i {
  font-size: 18px;
}
.delate i:hover {
  color: black;
}
</style>
<style lang="less" scoped>
.avatar-uploader:hover {
  .delate {
    opacity: 1 !important;
  }
}
</style>  