<template>
  <div class="layout">
    <layoutLeft />
    <div class="app"> 
      <layoutTop />
      <router-view />
    </div>
  </div>
</template>

<script>
import layoutLeft from "./components/left.vue";
import layoutTop from "./components/top.vue";
export default {
  name: "Layout",
  components: {
    layoutTop,
    layoutLeft,
  },
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
  background-color: #f4f5f6;
  overflow: auto;
}
.app {
  // height: 100%;
  min-width: 100%;
  min-height: 100%;
  padding: 0px 0px 1px 180px;
  padding-top: 62px;
  background-color: #f4f5f6;
}
</style> 