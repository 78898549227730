<template>
  <div>
    <a-row type='flex'>
      <a-col class="iconBox" v-for="(item,index) in options.data.menuArr" :key="index" flex='33.3333%'>
        <span v-if='navStyle==1&&borderStyle==1' class="round"><img height="100%" width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==2' class="round2"><img height="100%" width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==3' class="round3"><img height="100%" width="100%" :src="item.src"
            alt=""></span>
        <span class="iconBox_name">{{item.name}}</span>
      </a-col>
    </a-row>

    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "jiugonggeBox",
  props: ["index"],
  data() {
    return {
      borderStyle: "",
      navStyle: "",
    };
  },
  created() {
    this.borderStyle = this.options.data.borderStyle;
    this.navStyle = this.options.data.navStyle;
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() { 
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.jiugongge.data };
    },
  },
  watch: {
    okForm: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.borderStyle = this.options.data.borderStyle;
          this.navStyle = this.options.data.navStyle;
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  max-width: 100%;
  min-height: 100px;
  background-color: #fff;
}
.article_html {
  width: 100%;
  overflow: auto;
  img {
    width: 100%;
  }
}
.iconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px;
}
.round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
}
.round2 {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 5px;
  overflow: hidden;
}
.round3 {
  width: 50px;
  margin-bottom: 5px;
  height: 50px;
}
</style>