<template>
  <div class="login_R_Form">
        <div class="login_R_FormItem flex_must">
      <div class="flex_must_1">日期</div>
      必填&nbsp;<a-switch size="small"  v-model="options.require" />
    </div>
    <div class="login_R_FormItem">
      <div class="R_FormItem_name">组件名称</div>
      <div class="R_FormItem_data">
        <a-input v-model="options.label" placeholder="请输入" :maxLength='20'
          :suffix="options.label?options.label.length+'/20':'0/20'" />
      </div>
    </div>
    <div class="login_R_FormItem">
      <div class="R_FormItem_name">提示文字</div>
      <div class="R_FormItem_data">
        <a-input v-model="options.placeholder" placeholder="请输入" :maxLength='20'
          :suffix="options.placeholder?options.placeholder.length+'/20':'0/20'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["checkTypeIndex"],
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() {
      return this.formwork[this.checkTypeIndex] ? this.formwork[this.checkTypeIndex].options : { options: this.formwork.timeOptions };
    },
  },
};
</script>

<style lang="less" scoped>
.login_R_Form {
  margin: 10px 24px;
  border-top: 1px solid #eff0f2;
  padding-top: 20px;
  .login_R_FormItem {
    margin-bottom: 20px;
  }
  .R_FormItem_name {
    font-weight: 500;
    font-size: 12px;
    color: #222222;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    margin-bottom: 12px;
  }
}
.flex_must {
  font-size: 14px;
  color: #222222;
  display: flex;
  align-items: center;
  .R_FormItem_name {
    flex: 1;
  }
}
.flex_must_1 {
  flex: 1;
}
</style> 