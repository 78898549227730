<template>
  <div :key="index">
    <div v-if="!options.data.pageCss.img_scroll" :key="index" :style="
    'text-align: '+options.data.pageCss.alignment+';padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"
      class="jiugonggeBox scroll-container">
      <img v-if='options.data.pageCss.img_w==1' width="100%" :src="options.data.logo" alt="">
      <img v-if='options.data.pageCss.img_w==2' :src="options.data.logo" alt="">
      <img v-if='options.data.pageCss.img_w==3' :width="options.data.pageCss.img_w_det+'px'" :src="options.data.logo"
        alt="">
      <slot></slot>
    </div>

    <div :style="
    'text-align: '+options.data.pageCss.alignment+';padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"
      v-if="options.data.pageCss.img_scroll">
      <div v-if="options.data.pageCss.img_w==1" ref="thanks" class="thanks"
        :style="'background:'+'url(' + options.data.logo + ') repeat-x 0 0;'+'background-size:'+'100% auto'">
        <img style="opacity:0" :src="options.data.logo" width="100%" alt="">
      </div>
      <div v-if="options.data.pageCss.img_w==2" ref="thanks" class="thanks"
        :style="'background:'+'url(' + options.data.logo + ') repeat-x 0 0;'+'background-size:'+'auto auto'">
        <img style="opacity:0" :src="options.data.logo" alt="">
      </div>
      <div v-if="options.data.pageCss.img_w==3" ref="thanks" class="thanks"
        :style="'background:'+'url(' + options.data.logo + ') repeat-x 0 0;'+'background-size:'+options.data.pageCss.img_w_det+'px'+' auto'">
        <img style="opacity:0" :width="options.data.pageCss.img_w_det+'px'" :src="options.data.logo" alt="">
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "jiugonggeBox",
  props: ["index"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.pageTitle.data };
    },
  },
  created() {
    console.log(this.okForm);
  },
  mounted() { },
  methods: {},
};
</script>

<style lang="less" scoped>
.jiugonggeBox {
  overflow: hidden;
}
.thanks {
  padding-top: 2px;
  padding-bottom: 2px;
  // height: 34px;
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  text-align: center;
  background-color: #d6e3f8;
  animation: suhbar 16s infinite linear;
  // background: url("../../assets/logo.png") repeat-x 0 0;
  // background-size: auto 34px;
  // margin-bottom: 2px;
}
@keyframes suhbar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 730px 0;
  }
}
</style>