<template>
  <div>
    <a-row v-if="options.data.showType==1"  :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"  type='flex'>
      <a-col class="iconBox" v-for="(item,index) in options.data.menuArr" :key="index" flex='100%'>
        <span v-if='navStyle==1&&borderStyle==1' class="round"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==2' class="round2"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==3' class="round3"><img  width="100%" :src="item.src"
            alt=""></span>
        <span class="iconBox_name">{{item.name}}</span>
      </a-col>
    </a-row>
    <a-row v-if="options.data.showType==2"  :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"  type='flex'>
      <a-col class="iconBox" v-for="(item,index) in options.data.menuArr" :key="index" flex='50%'>
        <span v-if='navStyle==1&&borderStyle==1' class="round"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==2' class="round2"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==3' class="round3"><img  width="100%" :src="item.src"
            alt=""></span>
        <span class="iconBox_name">{{item.name}}</span>
      </a-col>
    </a-row>
    <a-row v-if="options.data.showType==3"  :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"  type='flex'>
      <a-col class="iconBox" v-for="(item,index) in options.data.menuArr" :key="index" flex='33.3333%'>
        <span v-if='navStyle==1&&borderStyle==1' class="round"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==2' class="round2"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==3' class="round3"><img  width="100%" :src="item.src"
            alt=""></span>
        <span class="iconBox_name">{{item.name}}</span>
      </a-col>
    </a-row>
    <a-row v-if="options.data.showType==4"  :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"  type='flex'>
      <a-col class="iconBox" v-for="(item,index) in options.data.menuArr" :key="index" flex='25%'>
        <span v-if='navStyle==1&&borderStyle==1' class="round"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==2' class="round2"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==3' class="round3"><img  width="100%" :src="item.src"
            alt=""></span>
        <span class="iconBox_name">{{item.name}}</span>
      </a-col>
    </a-row>
    <a-row v-if="options.data.showType==5"  :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss"  type='flex'>
      <a-col class="iconBox" v-for="(item,index) in options.data.menuArr" :key="index" flex='20%'>
        <span v-if='navStyle==1&&borderStyle==1' class="round"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==2' class="round2"><img  width="100%" :src="item.src"
            alt=""></span>
        <span v-if='navStyle==1&&borderStyle==3' class="round3"><img  width="100%" :src="item.src"
            alt=""></span>
        <span class="iconBox_name">{{item.name}}</span>
      </a-col>
    </a-row>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "tuwendaohangBox",
  props: ["index"],
  data() {
    return {
      borderStyle: "",
      navStyle: "",
    };
  },
  created() {
    this.borderStyle = this.options.data.borderStyle;
    this.navStyle = this.options.data.navStyle;
  },
  computed: {
    ...mapGetters(["workPlace"]),
    ...mapGetters(["okForm"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.tuwendaohang.data };
    },
  },
  watch: {
    okForm: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.borderStyle = this.options.data.borderStyle;
          this.navStyle = this.options.data.navStyle;
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  max-width: 100%;
  min-height: 100px;
  background-color: #fff;
}
.article_html {
  width: 100%;
  overflow: auto;
  img {
    width: 100%;
  }
}
.iconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px;
}
.round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
}
.round2 {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 5px;
  overflow: hidden;
}
.round3 {
  width: 50px;
  margin-bottom: 5px;
  height: 50px;
}
</style>