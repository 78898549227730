var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.index},[(!_vm.options.data.pageCss.img_scroll)?_c('div',{key:_vm.index,staticClass:"jiugonggeBox scroll-container",style:('text-align: '+_vm.options.data.pageCss.alignment+';padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss)},[(_vm.options.data.pageCss.img_w==1)?_c('img',{attrs:{"width":"100%","src":_vm.options.data.logo,"alt":""}}):_vm._e(),(_vm.options.data.pageCss.img_w==2)?_c('img',{attrs:{"src":_vm.options.data.logo,"alt":""}}):_vm._e(),(_vm.options.data.pageCss.img_w==3)?_c('img',{attrs:{"width":_vm.options.data.pageCss.img_w_det+'px',"src":_vm.options.data.logo,"alt":""}}):_vm._e(),_vm._t("default")],2):_vm._e(),(_vm.options.data.pageCss.img_scroll)?_c('div',{style:('text-align: '+_vm.options.data.pageCss.alignment+';padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss)},[(_vm.options.data.pageCss.img_w==1)?_c('div',{ref:"thanks",staticClass:"thanks",style:('background:'+'url(' + _vm.options.data.logo + ') repeat-x 0 0;'+'background-size:'+'100% auto')},[_c('img',{staticStyle:{"opacity":"0"},attrs:{"src":_vm.options.data.logo,"width":"100%","alt":""}})]):_vm._e(),(_vm.options.data.pageCss.img_w==2)?_c('div',{ref:"thanks",staticClass:"thanks",style:('background:'+'url(' + _vm.options.data.logo + ') repeat-x 0 0;'+'background-size:'+'auto auto')},[_c('img',{staticStyle:{"opacity":"0"},attrs:{"src":_vm.options.data.logo,"alt":""}})]):_vm._e(),(_vm.options.data.pageCss.img_w==3)?_c('div',{ref:"thanks",staticClass:"thanks",style:('background:'+'url(' + _vm.options.data.logo + ') repeat-x 0 0;'+'background-size:'+_vm.options.data.pageCss.img_w_det+'px'+' auto')},[_c('img',{staticStyle:{"opacity":"0"},attrs:{"width":_vm.options.data.pageCss.img_w_det+'px',"src":_vm.options.data.logo,"alt":""}})]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }