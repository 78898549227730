<template>
  <div ref="masklayer">
    <div :style=" 'height:' + options.data.pageCss.tabHeight+'px;border-radius:' + options.data.pageCss.page_css_radius + 
    'px;padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" class="home_Tab">
      <div @click="chengeCheck(item)" :class="{checkTab: item.checkTab}" class="home_Tab_item"
        v-for="(item,index) in options.data.menuArr" :key="index">
        <span v-if="item.src&&item.src_1">
          <img class="home_Tab_itemBg" v-if="item.src&&!item.checkTab" :src="item.src" alt="">
          <img class="home_Tab_itemBg" v-if="item.src_1&&item.checkTab" :src="item.src_1" alt="">
        </span>
        <span v-if="!item.src||!item.src_1">
          <span>{{item.name}}</span>
          <img v-if="item.checkTab" class="home_Tab_itemImg" src="@/assets/auto_img/指向@2x.png" alt="">
        </span>
      </div>
    </div>

    <div v-for="(item,index) in options.data.menuArr" :key="index" class="home_Tab_list">

      <!-- 图文盒子 -->
      <div class="articleBox_item" v-if="item.checkTab&&item.table_data.type=='articleBox'">
        <div v-if="item.table_data.options.data.desc!=''" :style="'border-radius:' + options.data.menuArr[0].table_data.options.data.pageCss.page_css_radius + 
    'px;padding:' + options.data.menuArr[0].table_data.options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.menuArr[0].table_data.options.data.pageCss.page_css_margin + 
    ';background-color:' + ''+';'+options.data.menuArr[0].table_data.options.data.pageCss.myCss"
          class="articleBox_item_richText" v-html="item.table_data.options.data.desc"></div>
      </div>
      <!-- 直播列表盒子 -->
      <div v-if="item.checkTab&&item.table_data.type=='second_videoList'">
        <div class="second_videoList_list">
          <home_Tab_live :ListshowType='options.data.pageCss.showType' :basePageCss='options.data.menuArr[0].table_data.options.data.pageCss'
            :data='item.table_data.options.data'></home_Tab_live>
        </div>
      </div>
      <!-- 视频列表盒子 -->
      <div v-if="item.checkTab&&item.table_data.type=='shipinheji'">
        <div class="shipinheji_list">
          <home_Tab_video :ListshowType='options.data.pageCss.showType' :basePageCss='options.data.menuArr[0].table_data.options.data.pageCss'
            :data='item.table_data.options.data'>
          </home_Tab_video>
        </div>
      </div>
      <!-- 图文列表盒子 -->
      <div v-if="item.checkTab&&item.table_data.type=='second_articleList'">
        <div class="article_list">
          <home_Tab_article :ListshowType='options.data.pageCss.showType' :basePageCss='options.data.menuArr[0].table_data.options.data.pageCss'
            :data='item.table_data.options.data'>
          </home_Tab_article>
        </div>
      </div>

    </div>

    <slot></slot>
  </div>
</template>

<script>
import home_Tab_video from './home_Tab_video.vue';
import home_Tab_live from './home_Tab_live.vue';
import home_Tab_article from './home_Tab_article.vue';
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    home_Tab_video,
    home_Tab_live,
    home_Tab_article
  },
  name: "tuwendaohangBox",
  props: ["index"],
  data() {
    return {
      borderStyle: "",
      navStyle: "",
    };
  },
  created() {
    this.borderStyle = this.options.data.borderStyle;
    this.navStyle = this.options.data.navStyle;
  },
  computed: {
    ...mapGetters(["workPlace"]),
    ...mapGetters(["okForm"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.tuwendaohang.data };
    },
  },
  mounted() {
  },
  watch: {
    okForm: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.borderStyle = this.options.data.borderStyle;
          this.navStyle = this.options.data.navStyle;
        });
      },
      deep: true,
    },
  },
  methods: {
    chengeCheck(item) {
      this.options.data.menuArr.forEach((j) => {
        j.checkTab = false;
      });
      item.checkTab = true;
    }
  }
};
</script>

<style lang="less" scoped>
.article {
  max-width: 100%;
  min-height: 100px;
  background-color: #fff;
}
.home_Tab {
  display: flex;
}
.home_Tab_item {
  flex: 1;
  min-height: 40px;
  font-size: 15px;
  color: #97999c;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // border-bottom: 1px solid #dee6ea;
}
.home_Tab_itemBg {
  position: absolute;
  // width: 100%;
  height: 100%;
  right: 0;
  margin: auto;
  top: 0;
  left: 0;
}
.home_Tab_itemImg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
}
.checkTab {
  color: #4988fd;
}
.articleBox_item {
  width: 100%;
  // padding: 8px;
  // background-color: #fff;
}
.article_list {
  width: 100%;
  // padding: 8px;
  // background-color: #fff;
}
.second_videoList_list {
  width: 100%;
  // padding: 8px;
  // background-color: #fff;
}
.shipinheji_list {
  width: 100%;
  // padding: 8px;
  // background-color: #fff;
}
.articleBox_item_richText {
  background-color: #fff;
  overflow: hidden;
}
</style>
<style >
.articleBox_item_richText img {
  max-width: 100% !important;
  height: auto !important;
}
</style>