const state = {
  home_dengfen: {
    type: 'home_dengfen',
    data: {
      menuArr: [{
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
      ],
      showType: 3,
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "2px 2px 2px 2px",
        page_css_interval: 4,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  home_Tab: {
    type: 'home_Tab',
    data: {
      menuArr: [{
          blank: true,
          checkTab: true,
          name: '导航',
          src: '',
          src_1: '',
          is_outside_url: 1,
          table_data: {
            type: "articleBox",
            name: "图文",
            icon: "icon-article",
            img: require('@/assets/base_img/icon_图文@2x.png'),
            options: {
              type: 'articleBox',
              data: {
                richText: '请输入内容...',
                desc: '请输入内容...',
                partNumber: '',
                round: 1,
                pageCss: {
                  page_css_margin: "0px 0px 0px 0px",
                  page_css_padding: "0px 0px 0px 0px",
                  page_css_interval: 0,
                  page_css_radius: 0,
                  page_css_bgc: "#ffffff",
                  textBgc: "rgba(255,255,255,1)",
                  myCss: '',
                }
              },
            }

          }
        },
        {
          checkTab: false,
          blank: true,
          name: '导航',
          src_1: '',
          src: '',
          table_data: {
            type: "second_videoList",
            name: "直播列表",
            icon: "icon-erji-wangluotuopu",
            img: require('@/assets/base_img/icon_视频列表@2x.png'),
            options: {
              type: 'second_videoList',
              data: {
                beginImg: '',
                doingImg: '',
                endImg: '',
                detailsImg: '',
                partNumber: 6,
                showType: '1',
                showUV: true,
                showTag: 'content',
                showPrice: true,
                sort_type: 1,
                menuArr: [],
                pageCss: {
                  showType: 2,
                  page_css_margin: "0px 0px 0px 0px",
                  page_css_padding: "12px 12px 0px 12px",
                  page_css_interval: 8,
                  page_css_radius: 0,
                  page_css_bgc: "#ffffff",
                  textBgc: "rgba(255,255,255,1)",
                  showDateName: false,
                  myCss: '',
                }
              },
            }
          },
          is_outside_url: 1,
        }
      ],
      showType: 3,
      pageCss: {
        tabHeight: '40',
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 4,
        showType: 2,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",
        myCss: '',
      }
    },
  },
  pageTitle: {
    type: 'pageTitle',
    data: {
      show: true,
      logo: 'https://www.cndmcloud.com/storage/setmeet/2024-05/JrWAFEagSBHIQQ6PzGNZRudVSZnxC7JeZhCaAX3V.png',
      desc: '',
      position: '',
      is_outside_url: 1,
      pageCss: {
        textBgc: "rgba(255,255,255,0)",
        alignment: "center",
        img_w: 1,
        img_w_det: 60,
        img_scroll: false,
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",
        myCss: '',
      }
    },
  },
  videoBox: {
    type: 'videoBox',
    data: {
      show: true,
      logo: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
      desc: '',
      is_outside_url: 1,
      position: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  roll: {
    type: 'roll',
    data: {
      show: true,
      logo: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
      desc: '',
      is_outside_url: 1,
      position: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  shipinheji: {
    type: 'shipinheji',
    data: {
      partNumber: 6,
      showType: '1',
      showUV: true,
      showTag: 'content',
      showPrice: true,
      menuArr: [],
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "12px 12px 0px 12px",
        page_css_interval: 8,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",
        showType: 2,
        myCss: '',
      }
    },
  },
  second_videoList: {
    type: 'second_videoList',
    data: {
      beginImg: '',
      doingImg: '',
      endImg: '',
      detailsImg: '',
      partNumber: 6,
      showType: '1',
      showUV: true,
      showTag: 'content',
      showPrice: true,
      sort_type: 1,
      menuArr: [],
      pageCss: {
        showType: 2,
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "12px 12px 0px 12px",
        page_css_interval: 8,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",
        showDateName: false,
        myCss: '',
      }
    },
  },
  second_articleList: {
    type: 'second_articleList',
    data: {
      partNumber: 6,
      showType: '1',
      showUV: true,
      showTag: 'content',
      showPrice: true,
      menuArr: [],
      pageCss: {
        showType: 2,
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 12px 0px 12px",
        page_css_interval: 8,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  second_cloudBooth: {
    type: 'second_cloudBooth',
    data: {
      partNumber: 6,
      showType: '1',
      showUV: true,
      showTag: 'content',
      showPrice: true,
      menuArr: [],
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 12px 0px 12px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  dingbudaohang: {
    type: 'dingbudaohang',
    visible: true,
    data: {
      logo: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
      menuArr: [{
          name: '导航一',
          link: '',
          is_outside_url: 1,
          target: true,
        },
        {
          name: '导航二',
          link: '',
          is_outside_url: 1,
          target: true,
        },
        {
          name: '导航三',
          is_outside_url: 1,
          link: '',
          target: true,
        },
        {
          name: '导航四',
          is_outside_url: 1,
          link: '',
          target: true,
        },
      ],
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  dibudaohang: {
    type: 'dibudaohang',
    data: {
      show: true,
      logo: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
      is_outside_url: 1,
      desc: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  jiugongge: {
    type: 'jiugongge',
    data: {
      menuArr: [{
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
      ],
      navStyle: '1',
      borderStyle: '1',
      showType: '2',
      partNumber: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  tuwendaohang: {
    type: 'tuwendaohang',
    data: {
      menuArr: [{
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
        {
          blank: true,
          name: '导航',
          src: 'https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp',
          next_meeting_view_title: "",
          is_outside_url: 1,
          url: '',
        },
      ],
      navStyle: '1',
      borderStyle: '1',
      showType: '3',
      partNumber: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  articleBox: {
    type: 'articleBox',
    data: {
      richText: '',
      desc: '',
      partNumber: '',
      round: 1,
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  upload_file: {
    type: 'upload_file',
    data: {
      show: true,
      logo: 'https://www.cndmcloud.com/storage/setmeet/2024-05/JrWAFEagSBHIQQ6PzGNZRudVSZnxC7JeZhCaAX3V.png',
      desc: '',
      position: '',
      is_outside_url: 1,
      pageCss: {
        textBgc: "rgba(255,255,255,0)",
        alignment: "center",
        img_w: 1,
        img_w_det: 60,
        img_scroll: false,
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",
        myCss: '',
      }
    },
  },
  doctor_taskBox: {
    type: 'doctor_taskBox',
    data: {
      richText: '',
      partNumber: '',
      round: 1,
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  banner: {
    type: 'banner',
    data: {
      menuArr: [{
        image: 'https://www.cndmcloud.com/storage/setmeet/2024-05/JrWAFEagSBHIQQ6PzGNZRudVSZnxC7JeZhCaAX3V.png',
        title: '企业门户标题',
        subtitle: '这里填写企业门户副标题',
        desc: '这里可以填写相关的表述，简单介绍企业门户内容',
        is_outside_url: 1,
        contentShow: false,
        link: {
          show: false,
          src: '',
          is_outside_url: 1,
          text: '查看',
        },
        imageLink: '',
        imageHeight: 140,
        imageWidth: 375,
        center: false,
      }],
      showType: 'default',
      paginationStyle: 'bullets',
      autoTimer: 3000,
      partNumber: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  swiper: {
    type: 'swiper',
    data: {
      menuArr: [{
        image: 'https://www.cndmcloud.com/storage/setmeet/2024-05/JrWAFEagSBHIQQ6PzGNZRudVSZnxC7JeZhCaAX3V.png',
        title: '企业门户标题',
        subtitle: '这里填写企业门户副标题',
        desc: '这里可以填写相关的表述，简单介绍企业门户内容',
        is_outside_url: 1,
        contentShow: false,
        link: {
          show: false,
          is_outside_url: 1,
          src: '',
          text: '查看',
        },
        imageLink: '',
        imageHeight: 140,
        imageWidth: 375,
        center: false,
      }],
      showType: 'default',
      paginationStyle: 'bullets',
      autoTimer: 3000,
      partNumber: '',
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "0px 0px 0px 0px",
        page_css_interval: 0,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    },
  },
  formBox: {
    type: "formBox",
    form_type: "1",
    data: {
      menuArr: [{
        "type": "inputWork",
        "name": "Inpute",
        "label": "input框",
        "icon": "account-book",
        "options": {
          "col": 24,
          "typeof": 'input',
          "label": "姓名",
          "labelWidth": 80,
          "value": "",
          "type": "text",
          "clearable": false,
          "disabled": false,
          "prefixIcon": null,
          "suffixIcon": null,
          "placeholder": "请输入姓名",
          "requireMsg": "",
          "require": false,
          "reg": "",
          "regMsg": ""
        }
      }, {
        "type": "inputWork",
        "name": "Inpute",
        "label": "input框",
        "icon": "account-book",
        "options": {
          "col": 24,
          "typeof": 'input',
          "label": "医院",
          "labelWidth": 80,
          "value": "",
          "type": "text",
          "clearable": false,
          "disabled": false,
          "prefixIcon": null,
          "suffixIcon": null,
          "placeholder": "请输入医院",
          "requireMsg": "",
          "require": false,
          "reg": "",
          "regMsg": ""
        }
      }, {
        "type": "inputWork",
        "name": "Inpute",
        "label": "input框",
        "icon": "account-book",
        "options": {
          "col": 24,
          "typeof": 'input',
          "label": "科室",
          "labelWidth": 80,
          "value": "",
          "type": "text",
          "clearable": false,
          "disabled": false,
          "prefixIcon": null,
          "suffixIcon": null,
          "placeholder": "请输入科室",
          "requireMsg": "",
          "require": false,
          "reg": "",
          "regMsg": ""
        }
      }, {
        "type": "selectWork",
        "name": "Selecte",
        "label": "Select框",
        "icon": "deployment-unit",
        "options": {
          "col": 24,
          "typeof": 'select',
          "label": "职称",
          "labelWidth": 80,
          "value": undefined,
          "multiple": false,
          "clearable": false,
          "filterable": false,
          "disabled": false,
          "placeholder": "请选择职称",
          "optionsType": true,
          "optionsArray": ["主任医师", "副主任医师", "主治医师", "其他"],
          "optionsZd": "",
          "requireMsg": "",
          "require": false
        }
      }, {
        "type": "inputWork",
        "name": "Inpute",
        "label": "input框",
        "icon": "account-book",
        "options": {
          "col": 24,
          "typeof": 'input',
          "label": "联系方式",
          "labelWidth": 80,
          "value": "",
          "type": "text",
          "clearable": false,
          "disabled": false,
          "prefixIcon": null,
          "suffixIcon": null,
          "placeholder": "请输入联系方式",
          "requireMsg": "",
          "require": false,
          "reg": "",
          "regMsg": ""
        }
      }],
      pageCss: {
        page_css_margin: "0px 0px 0px 0px",
        page_css_padding: "24px 24px 24px 24px",
        page_css_interval: 16,
        page_css_radius: 0,
        page_css_bgc: "#ffffff",
        textBgc: "rgba(255,255,255,1)",

        myCss: '',
      }
    }
  },
  okForm: [],
};
const mutations = {
  okForm: (state, params) => {
    params.arr ? state.okForm.splice(params.index, params.num, params.arr) : state.okForm.splice(params.index, params.num);
  },
  change_okForm(state, params) {
    state.okForm[params.index].options = params.value;
  },
};

const actions = {
  set_workPlace({
    commit
  }, params) {
    commit('okForm', params);
  },
  change_workPlace({
    commit
  }, params) {
    commit('change_okForm', params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};