<template>
  <a-form :form="form" v-bind="layout" labelAlign="left" style="padding: 20px">
    <a-form-item label="标题">
      <a-input v-model="form.label" @change="changeForm(form.label, 'label')" />
    </a-form-item>
    <a-form-item label="标签宽度">
      <a-input placeholder="请输入数字" v-model="form.labelWidth" type='number'
        @change="changeForm(form.labelWidth, 'labelWidth')" />
    </a-form-item>
    <a-form-item label="Input类型">
      <a-select v-model="form.type" @change="value => changeForm(value, 'type')">
        <a-select-option value="text">单行文本框</a-select-option>
        <a-select-option value="textarea">多行文本框</a-select-option>
        <a-select-option value="password">密码框</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Input前图标">
      <a-input v-model="form.prefixIcon" @change="changeForm(form.prefixIcon, 'prefixIcon')" />
    </a-form-item>
    <a-form-item label="Input后图标">
      <a-input v-model="form.suffixIcon" @change="changeForm(form.suffixIcon, 'suffixIcon')" />
    </a-form-item>
    <a-form-item label="提示文字">
      <a-input v-model="form.placeholder" @change="changeForm(form.placeholder, 'placeholder')" />
    </a-form-item>
    <a-form-item label="是否需要验证">
      <a-switch v-model="form.require" @change="value => changeForm(value, 'require')" />
    </a-form-item>
    <a-form-item label="判空提示文字" v-if="form.require">
      <a-input v-model="form.requireMsg" @change="changeForm(form.requireMsg, 'requireMsg')" />
    </a-form-item>
  </a-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      layout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
      }
    };
  },
  created() {
    this.form = {
      ...(this.formwork[this.index]
        ? JSON.parse(JSON.stringify(this.formwork[this.index].options))
        : {})
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"])
  },
  watch: {
    index(newval) {
      this.form = {
        ...(this.formwork[newval]
          ? JSON.parse(JSON.stringify(this.formwork[newval].options))
          : {})
      };
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"]),
    changeForm(value, label) {
      if (label === "require" && !value) {
        this.form.requireMsg = "";
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "requireMsg",
          value: ""
        });
      }
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: label,
        value: value
      });
    }
  }
};
</script>

<style scoped></style>
