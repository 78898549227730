<template>
  <a-form :form="form" v-bind="layout" labelAlign="left" style="padding: 20px">
    <a-form-item label="标题">
      <a-input v-model="form.label" @change="changeForm(form.label, 'label')" />
    </a-form-item>
    <a-form-item label="标签宽度">
      <a-input v-model="form.labelWidth" placeholder="请输入数字" @change="changeForm(form.labelWidth, 'labelWidth')" />
    </a-form-item>
    <a-form-item label="提示文字">
      <a-input v-model="form.placeholder" @blur="changeForm(form.placeholder, 'placeholder')" />
    </a-form-item>
    <a-form-item label="是否需要验证">
      <a-switch v-model="form.require" @change="value => changeForm(value, 'require')" />
    </a-form-item>
    <a-form-item label="判空提示文字" v-if="form.require">
      <a-input v-model="form.requireMsg" @change="changeForm(form.requireMsg, 'requireMsg')" />
    </a-form-item>

    <a-form-item class="delate_home" label="添加选项">
    </a-form-item>

    <a-form-item class="delate_home" v-for="(k, index) in form.optionsArray" :key="'item'+index" :label="'选项'+(index+1)">
      <a-input @change="changeForm(form.optionsArray, 'optionsArray')" v-model="form.optionsArray[index]"
        placeholder="请输入" />
      <a-icon class="delate_item" type="minus-circle-o" @click="remove(index)" />
    </a-form-item>

    <a-form-item v-bind="formItemLayoutWithOutLabel">
      <a-button type="dashed" style="width: 60%" @click="add">
        <a-icon type="plus" /> 添加选项
      </a-button>
    </a-form-item>

  </a-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      visible: false,
      layout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 7 },
        },
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },

    };
  },
  created() {
    this.form = {
      ...(this.formwork[this.index]
        ? JSON.parse(JSON.stringify(this.formwork[this.index].options))
        : {})
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"])
  },
  watch: {
    index(newval) {
      this.form = {
        ...(this.formwork[newval]
          ? JSON.parse(JSON.stringify(this.formwork[newval].options))
          : {})
      };
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"]),
    onClose() {
      this.visible = false;
    },
    changeFormJSON(value, label) {
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: label,
        value: value
      });
      this.visible = false;
    },
    changeForm(value, label) {
      console.log(value);
      if (label === "require" && !value) {
        this.form.requireMsg = "";
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "requireMsg",
          value: ""
        });
      }
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: label,
        value: value
      });
    },
    remove(index) {
      this.form.optionsArray.splice(index, 1)
    },

    add() {
      this.form.optionsArray.push('选项' + (this.form.optionsArray.length + 1))
      this.changeForm(this.form.optionsArray, 'optionsArray')
    },
    inpChange(value) {
      console.log(this.form.optionsArray)
    }

  }
};
</script>

<style>
.delate_home {
  position: relative;
  padding-right: 20px;
  margin-bottom: 12px;
}
.delate_item {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  right: -28px;
  display: flex;
}
.delate_item:hover {
  color: #777;
}
.delate_item[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>