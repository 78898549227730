<template>
  <div class="login base_login">
    <img class="login_bg" src="@/assets/auto_img/login.png" alt="">
    <img class="login_logo" src="@/assets/logo/未标题-2.png" alt="">
    <div class="bottom_txt">沪ICP备2022033592号-2</div>
    <div v-if="resect==1" class="login_form">
      <div class="login_form_tab">
        <div @click="changeLogintype(1)" :class="{login_form_itemED :loginType==1}" class="login_form_item pointer">
          密码登录
          <span v-if="loginType==1" class="login_form_itemL"></span>
        </div>
        <div @click="changeLogintype(2)" :class="{login_form_itemED :loginType==2}" class="login_form_item pointer">
          验证码登录
          <span v-if="loginType==2" class="login_form_itemL"></span>
        </div>
      </div>

      <div v-if="loginType==1" class="login_formBox">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input size="large" v-model="form.username" placeholder="请输入账户">
              <img slot="prefix" width="16px" src="@/assets/logo/编组 6@2x (2).png" alt="">
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input size="large" v-model="form.password" :type="showPassNum==1?'password':'text'" placeholder="请输入密码">
              <img slot="prefix" width="16px" src="@/assets/logo/矩形 2@2x (1).png" alt="">
              <i @click="showPass(2)" v-if="showPassNum==1" slot="suffix"
                class="iconfont icon-yanjing-biyan pointer"></i>
              <i @click="showPass(1)" v-if="showPassNum==2" slot="suffix" class="iconfont icon-yanjing3 pointer"></i>
            </a-input>
          </a-form-item>

          <div class="lost_password">
            <span @click="reSetLogin(2)" class="pointer">忘记密码</span>
          </div>

          <a-form-item>
            <a-button class="login_btn" block size="large" type="primary" html-type="submit">
              立即登录
            </a-button>
          </a-form-item>

        </a-form>
      </div>

      <div v-if="loginType==2" class="login_formBox">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input size="large" placeholder="请输入手机号码">
              <img slot="prefix" width="16px" src="@/assets/logo/编组 6@2x (3).png" alt="">
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input size="large" type="password" placeholder="请输入验证码">
              <img slot="prefix" width="16px" src="@/assets/logo/矩形 2@2x (3).png" alt="">
            </a-input>
            <span class="getYZM pointer"><span class="getYZMline"></span>获取验证码</span>
          </a-form-item>

          <div class="lost_password">
            <span style="opacity:0">1</span>
          </div>

          <a-form-item>
            <a-button class="login_btn" block size="large" type="primary" html-type="submit">
              立即登录
            </a-button>
          </a-form-item>

        </a-form>
      </div>

      <div class="get_password">
        没有账号？点击<span @click="reSetLogin(3)" class="pointer">立即注册</span>
      </div>

    </div>
    <div v-if="resect==2" class="login_form login_form_resect">

      <div class="toLogin"><span @click="reSetLogin(1)" class="pointer"><img height="12px"
            src="@/assets/logo/矩形 3@2x.png" alt="">&nbsp;返回登录</span></div>
      <div class="login_form_rT">重置密码</div>
      <div class="login_formBox">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input size="large" placeholder="请输入手机号码">
              <img slot="prefix" width="16px" src="@/assets/logo/编组 6@2x (3).png" alt="">
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input size="large" type="password" placeholder="请输入验证码">
              <img slot="prefix" width="16px" src="@/assets/logo/矩形 2@2x (3).png" alt="">
            </a-input>
            <span class="getYZM pointer"><span class="getYZMline "></span>获取验证码</span>
          </a-form-item>

          <a-form-item>
            <a-input size="large" v-model="form.password" :type="showPassNum==1?'password':'text'" placeholder="请输入新密码">
              <img slot="prefix" width="16px" src="@/assets/logo/矩形 2@2x (1).png" alt="">
              <i @click="showPass(2)" v-if="showPassNum==1" slot="suffix"
                class="iconfont icon-yanjing-biyan pointer"></i>
              <i @click="showPass(1)" v-if="showPassNum==2" slot="suffix" class="iconfont icon-yanjing3 pointer"></i>
            </a-input>
          </a-form-item>

          <div class="password_ant">
            <span>* 密码最少8位，至少包含数字、字母、特殊字符两种</span>
          </div>

          <a-form-item>
            <a-button class="login_btn" block size="large" type="primary" html-type="submit">
              立即登录
            </a-button>
          </a-form-item>

        </a-form>
      </div>
    </div>
    <div v-if="resect==3" class="login_form login_form_resect">

      <div class="toLogin"><span @click="reSetLogin(1)" class="pointer"><img height="12px"
            src="@/assets/logo/矩形 3@2x.png" alt="">&nbsp;返回登录</span></div>
      <div class="login_form_rT">注册账号</div>
      <div class="login_formBox">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input size="large" placeholder="请输入手机号码">
              <img slot="prefix" width="16px" src="@/assets/logo/编组 6@2x (3).png" alt="">
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input size="large" type="password" placeholder="请输入验证码">
              <img slot="prefix" width="16px" src="@/assets/logo/矩形 2@2x (3).png" alt="">
            </a-input>
            <span class="getYZM pointer"><span class="getYZMline "></span>获取验证码</span>
          </a-form-item>

          <a-form-item>
            <a-input size="large" v-model="form.password" :type="showPassNum==1?'password':'text'" placeholder="请输入密码">
              <img slot="prefix" width="16px" src="@/assets/logo/矩形 2@2x (1).png" alt="">
              <i @click="showPass(2)" v-if="showPassNum==1" slot="suffix"
                class="iconfont icon-yanjing-biyan pointer"></i>
              <i @click="showPass(1)" v-if="showPassNum==2" slot="suffix" class="iconfont icon-yanjing3 pointer"></i>
            </a-input>
          </a-form-item>

          <div class="password_ant">
            <span>* 密码最少8位，至少包含数字、字母、特殊字符两种</span>
          </div>

          <a-form-item>
            <a-button class="login_btn" block size="large" type="primary" html-type="submit">
              立即登录
            </a-button>
          </a-form-item>

        </a-form>
      </div>
    </div>
    <!-- <div class="loginForm">
      <h2>账户登录</h2>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input size="large" 
            placeholder="请输入账户">
            <a-icon slot="prefix" type="username" style="color: rgba(0, 0, 0, 0.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input size="large" 
            type="password" placeholder="请输入密码">
            <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-checkbox class="lineChange" :checked="checkNick" @change="handleChange">我承诺，不会将账号信息泄露给与无关的人员，以保护账户安全！
          </a-checkbox>
        </a-form-item>

        <a-form-item>
          <a-button block size="large" type="primary" html-type="submit" >
            立即登录
          </a-button>
        </a-form-item>
      </a-form>
    </div> -->
  </div>
</template>

<script> 
export default {
  name: "Login",
  data() {
    return {
      showPassNum: 1,
      loginType: 1,
      resect: 1,
      checkNick: false,
      formLayout: "horizontal",
      form: {
        username: '',
        password: ''
      },
    };
  },
  methods: {
    showPass(num) {
      this.showPassNum = num;
    },
    async handleSubmit(e) {
      e.preventDefault();
      await this.$store.dispatch("loginInfo/login", this.form);
      this.$router.push("/");
    },
    changeLogintype(num) {
      this.loginType = num;
    },
    reSetLogin(num) {
      this.resect = num;
    },
    handleChange(e) {
      this.checkNick = e.target.checked;
    },
  },
};
</script>

<style lang="less" scoped>
.login_form {
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
  // bottom: 0;
  right: 240px;
  // transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(86, 116, 143, 0.06);
  border-radius: 8px;
  width: 400px;
  min-height: 345px;
  margin: auto;
  .login_form_tab {
    display: flex;
    height: 73px;
    border-bottom: 1px solid #eff0f2;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    font-style: normal;
    .login_form_item {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      .login_form_itemL {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        width: 20px;
        height: 3px;
        background: #1890ff;
        border-radius: 2px;
      }
    }
    .login_form_itemED {
      color: #222222;
      font-weight: 600;
    }
  }
  .login_formBox {
    padding: 48px 24px;
    padding-bottom: 40px;
  }
  .lost_password {
    margin-bottom: 24px;
    text-align: right;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #1890ff;
    line-height: 17px;
  }
  .login_btn {
    height: 52px !important;
    font-weight: 500;
    font-size: 16px;
    // color: #ffffff;
    line-height: 22px;
  }
  .get_password {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 48px;
    border-top: 1px solid #eff0f2;
    font-size: 12px;
    color: #222222;
    span {
      color: #1890ff;
    }
  }
  .getYZM {
    position: absolute;
    right: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #1890ff;
    .getYZMline {
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -16px;
      margin: auto;
      width: 10px;
      border-left: 1px solid #eff0f2;
      height: 1em;
      border-left: 1px solid #eff0f2;
    }
  }
}
.login_form_resect {
  .login_formBox {
    padding-top: 24px;
  }
  .password_ant {
    font-weight: 400;
    position: relative;
    top: -18px;
    font-size: 12px;
    color: #606266;
  }
  .login_btn {
    margin-top: 6px;
  }
  .toLogin {
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: 400;
    font-size: 12px;
    color: #8a94a3;
    margin: 24px;
    margin-bottom: 0;
  }
  .login_form_rT {
    margin-top: 24px;
    font-weight: 600;
    font-size: 18px;
    color: #222222;
    text-align: center;
  }
}
.login {
  width: 100%;
  height: 100%;
  // background: url("../../assets/auto_img/login.png") no-repeat center center;
  // background-size: cover;
  // background-attachment: fixed;
  background-color: #f0f7fd;
  .login_bg {
    height: 100%;
    max-width: 100%;
    width: auto;
    position: absolute;
  }
  .login_logo {
    position: fixed;
    top: 160px;
    left: 240px;
    height: 42px;
  }
  .bottom_txt {
    position: fixed;
    bottom: 24px;
    width: 100%;
    left: 0;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    color: #8a94a3;
  }
}
.loginForm {
  position: fixed;
  top: 20%;
  // bottom: 0;
  left: 50%;
  transform: translate(-50%);
  // background-color: #fff;
  // box-shadow: 0 0 10px #999;
  padding: 25px;
  width: 400px;
  height: 345px;
  margin: auto;
  h2 {
    text-align: center;
    font-size: 26px;
    color: #eee;
    margin-bottom: 20px;
  }
}
.lineChange {
  line-height: 1.5 !important;
  font-size: 14px;
  color: #eee;
}
// :deep(.ant-input) {
//   border: 1px solid rgba(255, 255, 255, 0.1);
//   background: #fff;
//   border-radius: 5px;
//   color: #eee;
// }
// :deep(.anticon) {
//   color: #889aa4 !important;
// }
</style>
<style>
.base_login .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 36px !important;
}
</style>