// import Vue from "vue";

const state = {
  inputOptions: {
    typeof: 'input',
    col: 24,
    label: "Input框",
    labelWidth: 200,
    value: "",
    type: "text",
    clearable: false,
    disabled: false,
    prefixIcon: null,
    suffixIcon: null,
    placeholder: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  cityOptions: {
    typeof: 'city',
    col: 24,
    label: "city框",
    labelWidth: 200,
    value: "",
    type: "city",
    clearable: false,
    disabled: false,
    prefixIcon: null,
    suffixIcon: null,
    placeholder: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  articleOptions: {
    typeof: 'article',
    col: 24,
    label: "Article",
    labelWidth: 200,
    value: "请在右侧编辑文本...",
    type: "text",
    clearable: false,
    disabled: false,
    prefixIcon: null,
    suffixIcon: null,
    placeholder: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  textareaOptions: {
    typeof: 'input',
    col: 24,
    label: "Input框",
    labelWidth: 200,
    value: "",
    type: "textarea",
    clearable: false,
    disabled: false,
    prefixIcon: null,
    suffixIcon: null,
    placeholder: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  selectOptions: {
    typeof: 'select',
    col: 24,
    label: "Select框",
    labelWidth: 200,
    // value: null,select如果给value会不显示placeholder
    multiple: false,
    clearable: false,
    filterable: false,
    disabled: false,
    placeholder: "",
    optionsType: true,
    optionsArray: ['选项1', '选项2', '选项3'],
    optionsZd: "",
    requireMsg: "",
    require: false
  },
  sliderOptions: {
    typeof: 'slider',
    col: 24,
    label: "Slider滑块",
    labelWidth: 200,
    value: 0,
    min: 0,
    max: 24,
    step: 1,
    showInput: false,
    showInputControls: true,
    inputSize: "default",
    showStops: false,
    showTooltip: true,
    range: false,
    vertical: false,
    height: "120",
    disabled: false,
  },
  radioOptions: {
    typeof: '单选框',
    col: 24,
    label: "单选框",
    labelWidth: 200,
    value: '',
    multiple: false,
    clearable: false,
    filterable: false,
    disabled: false,
    placeholder: "",
    optionsType: true,
    optionsArray: ['请于表单工作区完善表单选项'],
    optionsZd: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  timeOptions: {
    typeof: '日期时间选择器',
    col: 24,
    label: "日期时间选择器",
    labelWidth: 200,
    value: null,
    multiple: false,
    clearable: false,
    filterable: false,
    disabled: false,
    placeholder: "",
    optionsType: true,
    require: false,
    timeType: "date",
    valueType: ""
  },
  upload_fileOptions: {
    typeof: '文件上传',
    col: 24,
    label: "文件上传",
    labelWidth: 200,
    value: null,
    multiple: false,
    clearable: false,
    filterable: false,
    disabled: false,
    placeholder: "",
    optionsType: true,
    require: false,
    fileType: "1",
    fileSize:'5',
    fileNumber: '5',
    valueType: ""
  },
  switchOptions: {
    typeof: '开关',
    col: 24,
    label: "开关",
    labelWidth: 200,
    value: "",
    width: 40,
    activeIconClass: "",
    inactiveIconClass: "",
    activeText: "",
    inactiveText: "",
    activeValue: true,
    inactiveValue: false,
    activeColor: "#409EFF",
    inactiveColor: "#C0CCDA",
    name: "",
    validateEvent: true,
    optionsZd: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  checkBoxOptions: {
    typeof: '多选框',
    col: 24,
    label: "多选框",
    labelWidth: 200,
    value: ['选项1', '选项2'],
    multiple: false,
    clearable: false,
    filterable: false,
    disabled: false,
    placeholder: "",
    optionsType: true,
    optionsArray: ['选项1', '选项2', '选项3'],
    optionsZd: "",
    requireMsg: "",
    require: false,
    reg: "",
    regMsg: ""
  },
  formwork: []

};

const mutations = {
  SET_FORMWORK(state, params) {
    params.arr ?
      state.formwork.splice(params.index, params.num, params.arr) :
      state.formwork.splice(params.index, params.num);
  },
  SET_FORMWORKOBH(state, params) {
    state.formwork[params.index].options[params.label] = params.value;
  }
};

const actions = {
  SET_FORMWORKFUN({
    commit
  }, params) {
    commit("SET_FORMWORK", params);
  },
  SET_FORMWORKOBHFUN({
    commit
  }, params) {
    commit("SET_FORMWORKOBH", params);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};