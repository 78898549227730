<template>
  <div class="jiugonggeBox">
    <span class="jiugonggeBox_logo"><img :src="options.data.logo" alt=""></span>
    <span class="jiugonggeBox_txt">
      <a-icon @click="closeBg(1)" class="pointer" type="unordered-list" />
      <a-button @click="clearItem2()" class="solt" :type="activeIndex === index ? 'primary' : 'Default'">
        顶部导航
        <a-icon style="margin-left:10px !important;" type="close" />
      </a-button>
    </span>

    <div v-if="closeNum" class="nav">
      <div class="close">
        <a-icon class="pointer" @click="closeBg(0)" type="close" />
      </div>
      <p v-for="(item,index) in options.data.menuArr" :key="index">{{item.name}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "dingbudaohang",
  props: ["index", "activeIndex"],
  data() {
    return { closeNum: 0 };
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.dingbudaohang.data };
    },
  },
  methods: {
    closeBg(num) {
      this.closeNum = num;
    },
    clearItem2() {
      this.$emit("clearItem", this.index, this.index);
    },
  },
};
</script>

<style lang="less" scoped>
.jiugonggeBox {
  position: static;
  display: flex;
  height: 50px;
  padding: 5px 10px;
  font-size: 18px;
  justify-content: space-between;
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.jiugonggeBox_logo {
  img {
    height: 100%;
  }
}
.jiugonggeBox_txt {
  position: relative;
}
.solt {
  position: absolute;
  top: -5px;
  left: 45px;
}
.nav {
  position: absolute;
  width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.nav p {
  text-align: center;
  line-height: 42px;
  color: white;
}
.nav p:hover {
  background-color: #fff;
  color: black;
}
.close {
  margin-top: 20px !important;
  height: 32px;
  color: white;
  padding-right: 20px;
  i {
    float: right;
  }
}
</style>