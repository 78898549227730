import { render, staticRenderFns } from "./shipinhejiForm.vue?vue&type=template&id=620411d2&scoped=true"
import script from "./shipinhejiForm.vue?vue&type=script&lang=js"
export * from "./shipinhejiForm.vue?vue&type=script&lang=js"
import style0 from "./shipinhejiForm.vue?vue&type=style&index=0&id=620411d2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620411d2",
  null
  
)

export default component.exports