var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second_articleList shipinheji"},[(_vm.ListshowType==1)?_c('div',{staticClass:"dataBox",style:('padding:' + _vm.basePageCss.page_css_padding + 
  ';margin:' + _vm.basePageCss.page_css_margin + 
  ';border-radius:' + _vm.basePageCss.page_css_radius + 
  'px;background-color:' + ''+';'+_vm.basePageCss.myCss)},[_c('div',{staticClass:"list"},[(_vm.data.dateImg)?_c('div',{staticClass:"date_img_title"},[_c('img',{attrs:{"width":"60px","src":_vm.data.dateImg,"alt":""}})]):_vm._e(),(!_vm.data.menuArr||!_vm.data.menuArr[0])?_c('div',{staticClass:"noData"},[_c('img',{attrs:{"width":"100%","src":require("@/assets/auto_img/videolist (3).png"),"alt":""}})]):_vm._e(),_vm._l((_vm.data.menuArr),function(item){return _c('div',{key:item.id,staticClass:"type imgBr_flex",style:('padding:'+_vm.basePageCss.page_css_interval+'px 0')},[_c('div',{staticClass:"imgBr imgBox"},[(item.list_cover_path)?_c('img',{attrs:{"width":"100%","height":"100%","src":item.list_cover_path,"alt":""}}):_vm._e(),(!item.list_cover_path)?_c('img',{attrs:{"width":"100%","height":"100%","src":require("@/assets/ex_nav.webp"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"msg_border"},[_c('p',{staticClass:"video_title"},[(_vm.data.beginImg&&item.status==0)?_c('img',{attrs:{"height":"18px","src":_vm.data.beginImg,"alt":""}}):_vm._e(),(_vm.data.doingImg&&item.status==1)?_c('img',{attrs:{"height":"18px","src":_vm.data.doingImg,"alt":""}}):_vm._e(),(_vm.data.endImg&&item.status==2)?_c('img',{attrs:{"height":"18px","src":_vm.data.endImg,"alt":""}}):_vm._e(),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))])]),_c('p',{staticStyle:{"flex":"1"}}),_c('p',{staticClass:"video_message"},[_c('span',[_vm._v(_vm._s(_vm._f("time")(item.begin_time,'YYYY-MM-DD HH:mm')))])])])])})],2)]):_vm._e(),(_vm.ListshowType==2)?_c('div',{staticClass:"dataBox",style:('padding:' + _vm.basePageCss.page_css_padding + 
  ';margin:' + _vm.basePageCss.page_css_margin + 
  ';border-radius:' + _vm.basePageCss.page_css_radius + 
  'px;background-color:' + ''+';'+_vm.basePageCss.myCss)},[_c('div',{staticClass:"list"},[(_vm.basePageCss.showDateName&&_vm.data.dateImg)?_c('div',{staticClass:"date_img_title"},[_c('img',{attrs:{"width":"60px","src":_vm.data.dateImg,"alt":""}})]):_vm._e(),(!_vm.data.menuArr||!_vm.data.menuArr[0])?_c('div',{staticClass:"noData"},[_c('img',{attrs:{"width":"100%","src":require("@/assets/auto_img/videolist (1).png"),"alt":""}})]):_vm._e(),_vm._l((_vm.data.menuArr),function(i){return _c('div',{key:i.id,staticClass:"item_s",style:('padding:'+_vm.basePageCss.page_css_interval+'px 0')},[_c('div',{staticClass:"item_title"},[_c('span',{staticClass:"item_a"},[_c('span',[(i.status==0)?_c('img',{attrs:{"height":"18px","src":_vm.data.beginImg,"alt":""}}):_vm._e(),(i.status==1)?_c('img',{attrs:{"height":"18px","src":_vm.data.doingImg,"alt":""}}):_vm._e(),(i.status==2)?_c('img',{attrs:{"height":"18px","src":_vm.data.endImg,"alt":""}}):_vm._e()]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(i.title))])]),_c('span',{staticClass:"item_b"},[_c('img',{attrs:{"height":"18px","src":_vm.data.detailsImg,"alt":""}})])]),_c('div',{staticClass:"time"},[_c('span',{staticClass:"mr10"},[_c('span',{staticClass:"iconfont icon-31shijian"}),_c('span',[_vm._v(_vm._s(_vm._f("time")(i.begin_time,'YYYY-MM-DD HH:mm'))+"-"+_vm._s(_vm._f("time")(i.end_time,'HH:mm')))])]),(i.hospital)?_c('span',{staticClass:"mr10"},[_c('span',{staticClass:"iconfont icon-yiyuanjieshao_xianxing"}),_c('span',[_vm._v(_vm._s(i.hospital))])]):_vm._e(),(i.speaker)?_c('span',{staticClass:"mr10"},[_c('span',{staticClass:"iconfont icon-gerenyonghutouxiang"}),_c('span',[_vm._v(_vm._s(i.speaker))])]):_vm._e()])])})],2)]):_vm._e(),(_vm.ListshowType==3)?_c('div',{staticClass:"dataBox",style:('padding:' + _vm.basePageCss.page_css_padding + 
  ';margin:' + _vm.basePageCss.page_css_margin + 
  ';border-radius:' + _vm.basePageCss.page_css_radius + 
  'px;background-color:' + ''+';'+_vm.basePageCss.myCss)},[_c('div',{staticClass:"list"},[(_vm.basePageCss.showDateName&&_vm.data.dateImg)?_c('div',{staticClass:"date_img_title"},[_c('img',{attrs:{"width":"60px","src":_vm.data.dateImg,"alt":""}})]):_vm._e(),(!_vm.data.menuArr||!_vm.data.menuArr[0])?_c('div',{staticClass:"noData"},[_c('img',{attrs:{"width":"100%","src":require("@/assets/auto_img/videolist (2).png"),"alt":""}})]):_vm._e(),_vm._l((_vm.data.menuArr),function(i){return _c('div',{key:i.id,staticClass:"type pb12",style:('padding:'+_vm.basePageCss.page_css_interval+'px 0')},[(i.list_cover_path)?_c('img',{staticClass:"imgBr",attrs:{"width":"100%","src":i.list_cover_path,"alt":""}}):_vm._e(),(!i.list_cover_path)?_c('img',{staticClass:"imgBr",attrs:{"width":"100%","src":require("@/assets/bg.png"),"alt":""}}):_vm._e(),_c('p',{staticClass:"video_title video_title_01 p12"},[(_vm.data.beginImg&&i.status==0)?_c('img',{attrs:{"height":"18px","src":_vm.data.beginImg,"alt":""}}):_vm._e(),(_vm.data.doingImg&&i.status==1)?_c('img',{attrs:{"height":"18px","src":_vm.data.doingImg,"alt":""}}):_vm._e(),(_vm.data.endImg&&i.status==2)?_c('img',{attrs:{"height":"18px","src":_vm.data.endImg,"alt":""}}):_vm._e(),_c('span',{staticClass:"title"},[_vm._v(_vm._s(i.title))])]),_c('p',{staticClass:"video_message p12"},[_c('span',[_vm._v(_vm._s(_vm._f("time")(i.begin_time,'YYYY-MM-DD HH:mm')))])])])})],2)]):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }