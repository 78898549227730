<template>
  <div v-if="showModal" class="login">

    <div class="form">
      <div class="form_item">
        <p class="title_login">签到</p>
        <component class="form_item_zj" v-for="(item, index) in formwork" :key="index" :is="item.type" :index="index">
        </component>
        <p>
          <span @click="join" class="qd">签到</span>
        </p>
      </div>

      <div @click="show" class="close">
        <img src="@/assets/关闭@2x.png" width="100%" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ['videoForm'],
  data() {
    return {
      showModal: false,
      form: {},
    }
  },
  created() {
    const requireComponent1 = require.context(
      "@/components/formwork",
      false,
      /\.vue$/
    );
    requireComponent1.keys().forEach(fileName => {
      let config1 = requireComponent1(fileName);
      let fimeNameChange1 = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName1 =
        fimeNameChange1.charAt(0).toLowerCase() +
        fimeNameChange1.slice(1) +
        "Work";
      Vue.component(componentName1, config1.default || config1);
    });
  },
  computed: {
    ...mapGetters(["formwork"]),
  },
  methods: {
    show() {
      this.showModal = !this.showModal
    },
    join() {
      console.log(this.formwork)
      try {
        this.formwork.forEach(item => {
          if (item.options.require && !item.options.value) {
            let alertTxt = item.options.requireMsg ? item.options.requireMsg : '请填写全部内容！';
            throw new Error(alertTxt);
          }
        })

        this.$message.success('签到成功！');
      } catch (e) {
        this.$message.error(e.message)
      }


    },
  },

}
</script>

<style lang="less" scoped>
.login {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: #00000073;
  filter: alpha(opacity=50);
}

.form {
  width: 70%;
  max-width: 400px;
  max-height: 70%;
  margin: auto;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  position: relative;
  display: flex;
  margin-bottom: 50px;
  top: 20%;
}

.form_item {
  flex: 1;
  overflow: auto;
}
.qd {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  width: 100%;
  background-color: #4988fd;
  border-radius: 24px;
  text-align: center;
  color: white;
  margin-top: 5px;
}
.close {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
}
</style>
<style>
.login .ant-form-item-control-wrapper {
  padding-bottom: 8px;
}
.login .ant-form-horizontal {
  margin-bottom: 8px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
.form_item_zj {
  margin-bottom: 12px;
}

.title_login {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}
</style>