import Vue from 'vue'
import Vuex from 'vuex'
import loginInfo from './modules/loginInfo' 
import workPlace from './modules/workPlace' 
import formwork from './modules/formwork' 
import getters from './getters'
import web_router from './modules/web_router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loginInfo,
    workPlace,
    formwork,
    web_router
  },
  getters
})
