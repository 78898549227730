import request from '@/utils/request';

/**
 * 上传接口
 * @param {*} data 请求对象 需要 mobile 和 password
 * @returns Promise
 */
export function uploadFn(data) {
  return request({
    method: 'post',
    url: '/meeting/upload/file',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}


/**
 * 导入专家任务接口
 * @param {*} data 请求对象 需要 mobile 和 password
 * @returns Promise
 */
export function uploadExcel(data) {
  return request({
    method: 'post',
    url: '/meeting/task/import/excel',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
