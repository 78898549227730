import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './style/main.css'; //全局css
import './permission' //路由守卫

import './iconfont/iconfont'
import './iconfont/iconfont.css'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import './filter/index' // 过滤器文件
import './utils/vue_cookies' // cookies操作工具
import './utils/prototype' // 挂载原型方法

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);


// 页面打开时获取localStorage版本号，不一致时清空localStorage
import packageJson from '../package.json'
const version = packageJson.version
const localVersion = localStorage.getItem('projectVersion')
if (!localVersion || version !== localVersion) {
  localStorage.clear()
  localStorage.setItem('projectVersion', version)
}


if (process.env.NODE_ENV !== 'development') {
  console.log = function () {}
  // console.error = function () {}
  console.dir = function () {}
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')