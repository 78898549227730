<template>
  <div class="tuwen">
    <div>
      <p>视频封面</p>
      <div class="tuwen_upload">
        <upload :image="form.data.logo" @uploadOk='uploadOk' :height='"75px"' :width='"75px"'></upload>
      </div>
      <p class="mt20"> 播放链接 </p>
      <div> 
        <a-input placeholder="请填写播放链接" />
      </div>
    </div> 
  </div>
</template>

<script>
import setJump from "./setJump.vue";
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, setJump },
  name: "home_dengfen",
  data() {
    return { form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
         
      });
    }, 
    uploadOk(data) {
      this.form.data.logo = data.payload.path
      this.form.data.image_id = data.payload.id;
      this.chnageVuex();
    },
  },
};
</script>

<style lang="less" scoped>
.tuwen {
  padding: 24px;          
}
.tuwen_upload {
  width: 92px;
  min-height: 85px;
  margin: 10px 0;
}
.tuwen_textarea {
  width: 80%;
  margin-top: 10px;
}
.cssChange {
  display: flex;
  line-height: 36px;
  span {
    flex: 1;
  }
}
.mt20{
  margin-top: 20px;
}
</style> 

