<template>
  <div class="tuwen">
    <div>
      <p>LOGO</p>
      <div class="tuwen_upload">
        <upload :image="form.data.logo" @uploadOk='uploadOk'></upload>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload },
  name: "rollForm",
  data() {
    return { form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
    },
    uploadOk(data) {
      this.form.data.logo = data.payload.path
      this.form.data.image_id = data.payload.id;
      this.chnageVuex();
    },
  },
};
</script>

<style lang="less" scoped>
.tuwen {
  padding: 24px;
}
.tuwen_upload {
  width: 92px;
  min-height: 85px;
  margin: 10px 0;
}
.tuwen_textarea {
  width: 80%;
  margin-top: 10px;
}
</style> 