import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/home.vue';
import Login from '../views/login';
import workPlace from '../views/workPlace';
import Layout from '@/layout';

Vue.use(VueRouter);

export const routes = [{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      name: '登录'
    },
  },
  {
    path: '',
    component: Layout,
    redirect: 'home',
    children: [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          name: '首页',
          icon: require('@/assets/base_img/icon_首页@2x.png')
        },
      },
      {
        path: '/menu',
        name: 'Menu',
        component: resolve => require(['@/views/menu/index'], resolve),
        meta: {
          name: '门户',
          icon: require('@/assets/base_img/icon_大会列表@2x.png')
        },
      },
      {
        path: '/videoList',
        name: 'videoList',
        redirect: 'videoList',
        component: resolve => require(['@/views/baseList/index'], resolve),
        meta: {
          name: '素材',
          icon: require('@/assets/base_img/icon_直播列表@2x.png')
        },
        children: [{
            path: '/videoList',
            name: 'videoList',
            component: resolve => require(['@/views/videoList/index'], resolve),
            meta: {
              name: '视频',
              icon: require('@/assets/base_img/icon_大会列表@2x.png')
            },
          },
          {
            path: '/articleList',
            name: 'articleList',
            component: resolve => require(['@/views/articleList/index'], resolve),
            meta: {
              name: '图文',
              icon: require('@/assets/base_img/icon_大会列表@2x.png')
            },
          },
        ]
      },
      // {
      //   path: '/addView',
      //   name: 'addView',
      //   component: resolve => require(['@/views/addView/index'], resolve),
      //   meta: {
      //     name: '刷新',
      //     icon: require('@/assets/base_img/浏览量 (2).png')
      //   },
      // },
      {
        path: '/doctorAll',
        name: 'doctorAll',
        component: resolve => require(['@/views/doctorAll/index'], resolve),
        meta: {
          name: '专家库',
          icon: require('@/assets/base_img/icon_专家库@2x.png')
        },
      },
      {
        path: '/user',
        name: 'User',
        component: resolve => require(['@/views/user/index'], resolve),
        meta: {
          name: '个人中心',
          icon: require('@/assets/base_img/icon_个人中心@2x.png')
        },
      },
    ],
  },
  {
    path: '/menuList',
    component: Layout,
    redirect: 'MenuList',
    children: [{
      path: '',
      name: 'MenuList',
      component: resolve => require(['@/views/menuList/index'], resolve),
      meta: {
        name: '页面列表',
        icon: require('@/assets/base_img/icon_大会列表@2x.png')
      },
    }, ],
  },
  {
    path: '/videoSet',
    component: Layout,
    redirect: 'videoSet',
    children: [{
      path: '',
      name: 'videoSet',
      component: resolve => require(['@/views/videoSet/index'], resolve),
      meta: {
        name: '直播设置',
        icon: require('@/assets/base_img/icon_大会列表@2x.png')
      },
    }, ],
  },
  {
    path: '/articleSet',
    component: Layout,
    redirect: 'articleSet',
    children: [{
      path: '',
      name: 'articleSet',
      component: resolve => require(['@/views/articleSet/index'], resolve),
      meta: {
        name: '文章设置',
        icon: require('@/assets/base_img/icon_大会列表@2x.png')
      },
    }, ],
  },
  {
    path: '/workPlace',
    name: 'workPlace',
    component: workPlace,
    meta: {
      name: '工作台'
    },
  },{
    path: '/help',
    name: 'help',
    component: resolve => require(['@/views/helpPage/index'], resolve),
    meta: {
      name: '帮助中心',
      icon: require('@/assets/base_img/icon_大会列表@2x.png')
    },
  }
];

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});


export default router;