<template>
  <div class="tuwen">

    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">样式调整</span></span>
    </p>

    <div v-show="btnTypeNum==1" class="imgList">

      <div v-for="(item,index) in form.data.menuArr" :key="index" class="choiceImg">
        <a-button class="close" @click="close(index)" shape="circle" icon="close" />
        <div class="choiceImg_up">
          <div class="choiceImg_up_left">
            <upload @uploadOk='uploadOk(index,$event)' :image='item.src' />
          </div>
          <div class="choiceImg_up_right">
            <p><span>标题</span>
              <a-input @change="chnageVuex" v-model="item.name" placeholder="请输入标题" />
            </p>
            <p><span>链接</span>
              <a-button @click="setJumpFn(index)" type="link">自定义链接 </a-button>
              <jumpTo :item='item' />
            </p>
          </div>
        </div>
      </div>

      <a-button size="large" class="addBanner" @click="addList" block type="link">
        添加图文导航
      </a-button>

    </div>

    <div v-show="btnTypeNum==2" class="cssChange">
      <p><span>导航样式</span>
        <a-button-group>
          <a-button @click="paginationStyleFn(1)" :class="{cssChange_check: form.data.navStyle ==1}">
            图文
          </a-button>
          <a-button @click="paginationStyleFn(2)" :class="{cssChange_check: form.data.navStyle ==2}">
            文字
          </a-button>
        </a-button-group>
      </p>
      <p><span>边框样式</span>
        <a-button-group>
          <a-button :class="{cssChange_check: form.data.borderStyle ==1}" @click="autoTimerFn(1)">圆形</a-button>
          <a-button :class="{cssChange_check: form.data.borderStyle ==2}" @click="autoTimerFn(2)">圆角矩形</a-button>
          <a-button :class="{cssChange_check: form.data.borderStyle ==3}" @click="autoTimerFn(3)">正方形</a-button>
        </a-button-group>
      </p>
    </div>

    <setJump @jumpForm='jumpForm' ref="setJump" />
  </div>
</template>

<script>
import setJump from "./setJump.vue";
import upload from "@/components/upload.vue";
import jumpTo from "./jumpTo.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, setJump, jumpTo },
  name: "jiugonggeForm",
  data() {
    return { setJumpNum: '', num: 3, btnTypeNum: 1, form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),

    addList() {
      this.form.data.menuArr.push({
        blank: true,
        name: "导航",
        next_meeting_view_title: "",
        src: "https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp",
        url: "",
      });
      this.chnageVuex();
    },
    btnTypeFn(num) {
      this.btnTypeNum = num;
      this.chnageVuex();
    },
    paginationStyleFn(num) {
      this.form.data.navStyle = num;
      this.chnageVuex();
    },
    autoTimerFn(num) {
      this.form.data.borderStyle = num;
      this.chnageVuex();
    },
    close(index) {
      console.log(index)
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
      this.$emit("changeWindow");
    },
    uploadOk(index, value) {
      console.log(this.form);
      this.form.data.menuArr[index].image_id = value.payload.id;
      this.form.data.menuArr[index].src = value.payload.path;
      console.log(this.form.data.menuArr[index])
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.menuArr[Number(this.setJumpNum)].meeting_view_id = data.meeting_view_id
      this.form.data.menuArr[Number(this.setJumpNum)].url = data.url
      if (data.next_meeting_view_title) {
        this.form.data.menuArr[Number(this.setJumpNum)].next_meeting_view_title = data.next_meeting_view_title
      }

      console.log(this.form.data.menuArr[Number(this.setJumpNum)]);
      this.setJumpNum = ''
      this.chnageVuex();
    },
    setJumpFn(index) {
      this.setJumpNum = index
      this.$refs.setJump.showModal(this.form.data.menuArr[Number(this.setJumpNum)]);
    },
  },
};
</script>

<style lang="less" scoped>
.check2 {
  border: 1px solid #1890ff !important;
  color: #1890ff;
}
.tuwen_title {
  padding: 24px;
}
.lineBtn {
  width: 100%;
  button {
    font-weight: 800;
    margin-left: 0 !important;
  }
}
.imgList {
  padding: 24px;
}
.choiceImg {
  padding: 12px;
  margin-top: 12px;
  min-height: 100px;
  border: 1px solid #ccc;
  position: relative;
  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: #ccc;
    display: none;
  }
  p {
    margin-top: 10px !important;
  }
}
.choiceImg:hover {
  .close {
    display: block;
  }
}
.addBanner {
  margin-top: 20px;
  border: 1px dashed #1890ff;
}
.cssChange {
  padding: 24px;
  p {
    margin-bottom: 15px !important;
    span {
      margin-right: 15px;
    }
  }
}
.cssChange_check {
  background-color: #1890ff !important;
  color: white;
}
.choiceImg_up {
  display: flex;
}
.choiceImg_up_left {
  min-width: 76px;
  min-height: 76px;
}
.choiceImg_up_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 0 !important;
    display: flex;
    button {
      padding-left: 0;
    }
    input {
      flex: 1;
    }
    span {
      margin-left: 12px;
      width: 42px;
      line-height: 32px;
    }
  }
}
</style> 