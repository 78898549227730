 const getters = {
  loginInfo: (state) => state.loginInfo.info,
  userinfo: (state) => state.loginInfo.userinfo,
  web_router: (state) => state.web_router.web_router,
  okForm: (state) => state.workPlace.okForm,
  workPlace: (state) => state.workPlace,
  formwork: (state) => state.formwork.formwork,
  inputOptions: state => state.formwork.inputOptions,
  textareaOptions: state => state.formwork.textareaOptions,
  selectOptions: state => state.formwork.selectOptions,
  sliderOptions: state => state.formwork.sliderOptions,
  radioOptions: state => state.formwork.radioOptions,
  timeOptions: state => state.formwork.timeOptions,
  checkBoxOptions: state => state.formwork.checkBoxOptions,
  articleOptions: state => state.formwork.articleOptions,
  cityOptions: state => state.formwork.cityOptions,
  formwork: state => state.formwork.formwork,
  switchOptions: state => state.formwork.switchOptions,
  inputNumberOptions: state => state.formwork.inputNumberOptions ,
  upload_fileOptions: state => state.formwork.upload_fileOptions ,
}
export default getters