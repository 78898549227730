var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.options.data.showType==1)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox",attrs:{"flex":"100%"}},[(_vm.navStyle==1&&_vm.borderStyle==1)?_c('span',{staticClass:"round"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==2)?_c('span',{staticClass:"round2"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==3)?_c('span',{staticClass:"round3"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),_c('span',{staticClass:"iconBox_name"},[_vm._v(_vm._s(item.name))])])}),1):_vm._e(),(_vm.options.data.showType==2)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox",attrs:{"flex":"50%"}},[(_vm.navStyle==1&&_vm.borderStyle==1)?_c('span',{staticClass:"round"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==2)?_c('span',{staticClass:"round2"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==3)?_c('span',{staticClass:"round3"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),_c('span',{staticClass:"iconBox_name"},[_vm._v(_vm._s(item.name))])])}),1):_vm._e(),(_vm.options.data.showType==3)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox",attrs:{"flex":"33.3333%"}},[(_vm.navStyle==1&&_vm.borderStyle==1)?_c('span',{staticClass:"round"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==2)?_c('span',{staticClass:"round2"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==3)?_c('span',{staticClass:"round3"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),_c('span',{staticClass:"iconBox_name"},[_vm._v(_vm._s(item.name))])])}),1):_vm._e(),(_vm.options.data.showType==4)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox",attrs:{"flex":"25%"}},[(_vm.navStyle==1&&_vm.borderStyle==1)?_c('span',{staticClass:"round"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==2)?_c('span',{staticClass:"round2"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==3)?_c('span',{staticClass:"round3"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),_c('span',{staticClass:"iconBox_name"},[_vm._v(_vm._s(item.name))])])}),1):_vm._e(),(_vm.options.data.showType==5)?_c('a-row',{style:('padding:' + _vm.options.data.pageCss.page_css_padding + 
  ';margin:' + _vm.options.data.pageCss.page_css_margin + 
  ';border-radius:' + _vm.options.data.pageCss.page_css_radius + 
  'px;background-color:' + _vm.options.data.pageCss.textBgc+';'+_vm.options.data.pageCss.myCss),attrs:{"type":"flex"}},_vm._l((_vm.options.data.menuArr),function(item,index){return _c('a-col',{key:index,staticClass:"iconBox",attrs:{"flex":"20%"}},[(_vm.navStyle==1&&_vm.borderStyle==1)?_c('span',{staticClass:"round"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==2)?_c('span',{staticClass:"round2"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),(_vm.navStyle==1&&_vm.borderStyle==3)?_c('span',{staticClass:"round3"},[_c('img',{attrs:{"width":"100%","src":item.src,"alt":""}})]):_vm._e(),_c('span',{staticClass:"iconBox_name"},[_vm._v(_vm._s(item.name))])])}),1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }