<template>
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
<!-- 此组件暂未使用 -->
  <div class="tuwen">
    <div>
      <p>LOGO</p>
      <div class="tuwen_upload">
        <upload :image="form.data.logo" @uploadOk='uploadOk'></upload>
      </div>
    </div>
    <p>内容编辑</p>
    <div class="imgList">

      <div v-for="(item,index) in form.data.menuArr" :key="index" class="choiceImg">
        <a-button class="close" @click="close(index)" shape="circle" icon="close" />
        <p><span>标题</span>
          <a-input @change="chnageVuex" v-model="item.name" placeholder="Basic usage" />
        </p>
        <p><span>链接</span>
          <a-button @click="setJumpFn" type="link">
            自定义链接
          </a-button>
        </p>
      </div>

      <a-button size="large" class="addBanner" @click="addList" block type="link">
        添加图文导航
      </a-button>

    </div>
    <setJump @jumpForm='jumpForm' ref="setJump" />
  </div>
</template>

<script>
import setJump from './setJump.vue';
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, setJump },
  name: "dingbudaohangForm",
  data() {
    return { form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
    },
    jumpForm(data) {
      console.log(data)
    },
    uploadOk(data) {
      this.form.data.logo = data.payload.path
      this.form.data.image_id = data.payload.id;
      this.chnageVuex();
    },
    close(item, index) {
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    addList() {
      this.form.data.menuArr.push({
        name: "导航一",
        link: "",
        target: true,
      });
      this.chnageVuex();
    },
    uploadIma() {
      this.form.data.logo = require("../../../assets/001.png");
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.meeting_view_id = data.meeting_view_id
      this.form.data.url = data.url
      this.chnageVuex();
    },
    setJumpFn() {
      return console.log(this.form.data)
      this.$refs.setJump.showModal(this.form.data)
    }
  },
};
</script>

<style lang="less" scoped>
.tuwen {
  padding: 24px;
}
.tuwen_upload {
  width: 85px;
  min-height: 85px;
  margin: 10px 0;
}
.tuwen_textarea {
  width: 80%;
  margin-top: 10px;
}

.choiceImg {
  border-radius: 2px;
  padding: 12px 18px;
  margin-top: 12px;
  min-height: 100px;
  border: 1px solid #ccc;
  position: relative;
  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: #ccc;
    display: none;
  }
  p {
    margin-top: 10px !important;
    display: flex;
    line-height: 32px;
    input {
      flex: 1;
    }
    button {
      padding-left: 0;
    }
    span {
      width: 40px;
    }
  }
}
.choiceImg:hover {
  .close {
    display: block;
  }
}
.addBanner {
  margin-top: 20px;
  border: 1px dashed #1890ff;
}
</style> 