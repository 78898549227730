<template>
  <div class="setJump_page">
    <div v-if="visible" class="modal_2">
      <div class="add add_pof">
        <div class="add_form add_pof">

          <div class="add_form_title">
            选择图文
            <a-icon @click="handleCancel" class="add_form_close" type="close" />
          </div>

          <div class="searchBox">
            <a-input-search placeholder="请输入名称" v-model="keywordStr" enter-button @search="onSearch" />
            <div style="flex:1"></div>
            <a-button class="showModal2" @click="showModal2">新增图文</a-button>
            <a-button class="showModal2_reload" type="link" @click="reload_showModal"><img width="14px"
                src="@/assets/auto_img/icon_刷新@2x.png" alt="">刷新</a-button>
          </div>

          <div class="list_box">
            <a-table :scroll="{ x: 600, y: 255 }" :loading="loading" :pagination="ipagination"
              :row-selection="rowSelection" @row-click="columnsClick" @change="handlePageChange"
              :rowKey="record=>record.document_id" :columns="columns" :data-source="dataList">

              <span class="menu_name_box" slot="video_title" slot-scope="text, record">
                <div class="list_img_title">
                  <img v-if="!record.cover_img_path" width="150px" height="85px" src="@/assets/novideo.png" alt="">
                  <img v-if="record.cover_img_path" width="150px" height="85px" :src="record.cover_img_path" alt="">
                  <span class="menu_name">{{record.title}}</span>
                </div>
              </span>

              <span slot="publish_time" slot-scope="text, record">
                <span v-if="!record.publish_time">未填</span>
                <span v-if="record.publish_time">{{ record.publish_time|time }} </span>
              </span>

            </a-table>
          </div>

          <div class="share_bot">
            <a-button @click="handleCancel">取消</a-button>
            <a-button @click='handleOk' type="primary">
              确定
            </a-button>
          </div>

        </div>
      </div>
    </div>

    <addModal @addList='reload_showModal' ref="addModal"></addModal>
  </div>
</template>

<script>
import { setViewList, getViewList, getModularDocumentList } from '@/api/meetApi.js';

import addModal from '@/views/articleList/components/Modal.vue';

const columns = [
  {
    title: "图文标题",
    dataIndex: "video_title",
    scopedSlots: { customRender: "video_title" },
    key: "video_title",
    // width: 350,
    align: "left",
  },
  {
    title: "发布时间",
    key: "publish_time",
    scopedSlots: { customRender: "publish_time" },
    align: "left", width: 350,
  },
];

export default {
  components: { addModal },
  name: "setJump",
  data() {
    return {
      document_id: this.$route.query.id,
      type: '',
      itemList: [],
      visible: false,
      confirmLoading: false,
      visible2: false,
      confirmLoading2: false,
      jumpForm: {
        next_meeting_view_title: '',
        meeting_view_id: '',
        url: ''
      },
      selectDed: [],
      selectNew: [],
      // 
      // 
      // 
      loading: false,
      dataList: [],
      columns,
      keywordStr: '',
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    };
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // this.selectNew处理页数切换时会导致上一页数据丢失
          this.selectNew = [...this.selectNew, ...selectedRows]
          // this.selectNew根据document_id去重
          this.selectNew = this.selectNew.filter((item, index) => {
            return this.selectNew.findIndex(item2 => item2.document_id == item.document_id) === index;
          });
        },
        getCheckboxProps: function (record) {
          console.log(record, this.document_id)
          return {
            props: {
              disabled: record.disabled,
              defaultChecked: record.selectDedFN == 1, // Column configuration not to be checked
              name: record.name,
            }
          }
        },
      };
    },
  },
  methods: {
    //表格改变时触发
    handlePageChange(page) {
      console.log(page);
      this.ipagination = page;
      this.getPageList({ type: this.type, page: page.current, per_page: page.pageSize, keyword: this.keywordStr, meeting_id: this.$route.query.meeting_id });
    },
    columnsClick(record) {
      this.jumpForm.next_meeting_view_title = record.title
      this.jumpForm.meeting_view_id = record.id;
    },
    close(e) {
      this.checkMenu_click = false
      this.showNum = false
      this.share_F = 1
    },
    onSearch() {
      this.getPageList({ type: this.type, page: 1, per_page: this.ipagination.pageSize, keyword: this.keywordStr, meeting_id: this.$route.query.meeting_id });
    },
    async getPageList(sendData) {
      let data = await getModularDocumentList(sendData)
      this.itemList = data.payload.data
      this.dataList = data.payload.data
      console.log(this.dataList)
      console.log(this.selectDed)
      let _this = this
      _this.dataList.forEach(element => {
        if (element.document_id == _this.document_id) {
          element.disabled = true
        }
        _this.selectDed.forEach(element2 => {
          if (element.document_id == element2.document_id) {
            element.selectDedFN = 1
            element.id = element2.id
          }
        })
      });
      console.log(this.dataList)

    },
    reload_showModal() {
      this.keywordStr = ''
      this.getPageList({ meeting_id: this.$route.query.meeting_id, type: this.type })
    },
    showModal(data, type) {
      this.selectDed = data
      this.selectNew = data
      console.log(data)
      this.keywordStr = ''
      this.type = type
      this.getPageList({ meeting_id: this.$route.query.meeting_id, type: this.type })
      this.visible = true;
    },
    handleOk(e) {
      console.log(this.selectNew)
      this.$emit('giveVideo', this.selectNew)
      this.visible = false;
      this.confirmLoading = false;
      this.$message.success('设置成功！')
      this.clear()
    },
    handleCancel(e) {
      var _this = this;
      _this.visible = false;

    },

    showModal2() {
      this.$refs.addModal.showModal();
    },
    clear() {
      this.jumpForm = {
        next_meeting_view_title: '',
        meeting_view_id: '',
        url: ''
      }
    },
    handleCancel2(e) {
      var _this = this;
      _this.visible2 = false;
      _this.clear();
      this.visible2 = false;
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.ant-table-row td) {
  padding: 8px 8px;
}
.list_img_title {
  display: flex;
  width: 100%;
  align-items: center;
  .menu_name_box {
    display: flex;
  }

  .menu_name {
    display: inline-block;
    width: 130px;
    text-align: left;
    height: 100%;
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
  }
  img {
    width: 106px;
    height: 60px;
    border-radius: 4px;
  }
}
.jumpWhere {
  display: flex;
  line-height: 32px;
  margin-bottom: 12px;
  span {
    // margin-right: 10px;
  }
  input {
    flex: 1;
  }
}
.searchBox {
  height: 32px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  // justify-content: space-between;
}
.showModal2 {
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  color: #1890ff;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  border: 1px solid rgba(25, 144, 255, 0.5);
}
.showModal2_reload {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  img {
    margin-right: 5px;
  }
}
.searchBox .ant-input-search {
  width: 240px;
}
.list_box {
  padding: 0 20px;
  min-height: 365px;
  max-height: 365px;
}
:deep(.ant-calendar-picker) {
  width: 100%;
}

.jumpWhere {
  input {
    border-radius: 4px 0 0 4px;
  }
  button {
    border-radius: 0 4px 4px 0;
  }
}
.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 1000px;
  height: 550px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}

.h625 {
  height: 625px;
}
.add_form_title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px 0px 20px 0px;
  margin: 0 20px;
  flex: 1;
}

.add_formBoxTwo {
  padding: 21px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.add_formBox_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f2;
  padding-bottom: 20px;
}
.add_formBox_name {
  text-align: right;
  padding-right: 16px;
  width: 100px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.add_formBox_inp {
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  flex: 1;
  margin: 0 10px 0 0px;
}
.begin_time,
.meeting_name {
  max-width: 400px;
  width: 60%;
}
.add_formBox_btn {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  margin-right: 16px;
  color: #1890ff;
}
.add_formBox_btn:last-child {
  margin-right: 20px;
}
.share_bot button {
  margin-left: 12px;
}
.share_bot {
  border-top: 1px solid #e3e4e8;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
:deep(.ant-table-thead) {
  height: 48px;
  tr {
    th {
      background: #f3f6f9;
      height: 48px;
      padding: 0 16px;
    }
  }
}
:deep(.ant-table-tbody) {
  height: 48px;
  tr {
    td {
      height: 59px;
    }
  }
}
.setJump_page :deep(.title) {
  display: none;
}
.status {
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.status_wks {
  border: 1px solid #2bb840;
  color: #2bb840;
}
.status_zbz {
  border: 1px solid #1890ff;
  color: #1890ff;
}
.status_yjs {
  border: 1px solid #eb5d44;
  color: #eb5d44;
}
</style>