<template>
  <div key="second_videoListForm" class="tuwen">

    <div class="tuwen_title">
      <p>
        <span>选择模板</span>
      </p>
      <div class="DF_Base">
        <div class="DF_item">
          <div :class="{check2: form.data.pageCss.showType==1}" @click="changeBtn(1)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/videolist (3).png" alt="">
            <img class="DF_item_check" v-if="form.data.pageCss.showType==1" width="20px"
              src="@/assets/auto_img/等分 (4).png" alt="">
          </div>
          <div class="DF_item_title">缩略图列表</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.pageCss.showType==2}" @click="changeBtn(2)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/videolist (1).png" alt="">
            <img class="DF_item_check" v-if="form.data.pageCss.showType==2" width="20px"
              src="@/assets/auto_img/等分 (4).png" alt="">
          </div>
          <div class="DF_item_title">仅标题列表</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.pageCss.showType==3}" @click="changeBtn(3)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/videolist (2).png" alt="">
            <img class="DF_item_check" v-if="form.data.pageCss.showType==3" width="20px"
              src="@/assets/auto_img/等分 (4).png" alt="">
          </div>
          <div class="DF_item_title">大图列表</div>
        </div>
      </div>
    </div>

    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">样式调整</span></span>
    </p>

    <div v-show="btnTypeNum==1" class="imgList">

      <div class="list_name list_name_title">
        <div>列表名称</div>
        <a-switch @change="onChange" v-model="form.data.pageCss.showDateName" size="small" default-checked />
      </div>

      <div v-if="form.data.pageCss.showDateName" class="list_name_ImgUP mb16">
        <div @click="close(1)" class="choiceImg_base_close pointer">
          <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
        </div>
        <span v-if="!form.data.dateImg" class="choiceImg_base_Noimg">
          <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
          添加图片
        </span>
        <img class="choiceImg_base_Show" v-if="form.data.dateImg" :src="form.data.dateImg" alt="">
        <upload class="choiceImg_base_poa" @uploadOk='uploadOk4' :image='form.data.dateImg' :height="'19px'"
          :width='"auto"' />
      </div>

      <div class="list_name_title">
        <div>状态标签</div>
        <div class="list_name_data">

          <div class="list_name_dataItem">
            <div>未开始：</div>
            <div class="list_name_ImgUP">
              <div @click="close(2)" class="choiceImg_base_close pointer">
                <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
              </div>
              <span v-if="!form.data.beginImg" class="choiceImg_base_Noimg">
                <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                添加图片
              </span>
              <img class="choiceImg_base_Show" v-if="form.data.beginImg" :src="form.data.beginImg" alt="">
              <upload class="choiceImg_base_poa" @uploadOk='uploadOk' :image='form.data.beginImg' :height="'19px'"
                :width='"auto"' />
            </div>
          </div>

          <div class="list_name_dataItem">
            <div>直播中：</div>
            <div class="list_name_ImgUP">
              <div @click="close(3)" class="choiceImg_base_close pointer">
                <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
              </div>
              <span v-if="!form.data.doingImg" class="choiceImg_base_Noimg">
                <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                添加图片
              </span>
              <img class="choiceImg_base_Show" v-if="form.data.doingImg" :src="form.data.doingImg" alt="">
              <upload class="choiceImg_base_poa" @uploadOk='uploadOk2' :image='form.data.doingImg' :height="'19px'"
                :width='"auto"' />
            </div>
          </div>

          <div class="list_name_dataItem">
            <div>已结束：</div>
            <div class="list_name_ImgUP">
              <div @click="close(4)" class="choiceImg_base_close pointer">
                <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
              </div>
              <span v-if="!form.data.endImg" class="choiceImg_base_Noimg">
                <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                添加图片
              </span>
              <img class="choiceImg_base_Show" v-if="form.data.endImg" :src="form.data.endImg" alt="">
              <upload class="choiceImg_base_poa" @uploadOk='uploadOk3' :image='form.data.endImg' :height="'19px'"
                :width='"auto"' />
            </div>
          </div>

          <div class="list_name_dataItem">
            <div>详情：</div>
            <div class="list_name_ImgUP">
              <div @click="close(5)" class="choiceImg_base_close pointer">
                <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
              </div>
              <span v-if="!form.data.detailsImg" class="choiceImg_base_Noimg">
                <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
                添加图片
              </span>
              <img class="choiceImg_base_Show" v-if="form.data.detailsImg" :src="form.data.detailsImg" alt="">
              <upload class="choiceImg_base_poa" @uploadOk='uploadOk5' :image='form.data.detailsImg' :height="'19px'"
                :width='"auto"' />
            </div>
          </div>

        </div>
      </div>

      <div class="list_name_title">
        <div class="list_name_radio">排序方式</div>
        <div class="list_name_titleDate">
          <a-radio-group v-model="form.data.sort_type" @change="onChange">
            <a-radio :value="1">
              自动排序
            </a-radio>
            <a-radio :value="2">
              手动排序
            </a-radio>
          </a-radio-group>
        </div>
      </div>

      <div class="list_name_title">
        <div class="list_name_radio">内容</div>
        <div @click="showVideoList" class="list_name_titleDate pointer">
          <div class="set_video">
            <span class="choiceImg_base_Noimg">
              <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
              选择直播
            </span>
          </div>
        </div>
      </div>

      <div v-if="form.data.menuArr[0]" class="list_name_title">
        <div class="list_name_radio">已选图文</div>
        <div class="list_name_titleDate">

          <draggable class="draggableBox" animation="1000" @end="endEventFN" v-model="form.data.menuArr">
            <div v-for="(item,index) in form.data.menuArr" :key="item.id" class="video_S_list">
              <div class="video_S_title text3">{{item.title}}</div>
              <div class="video_S_dataBox">
                <!-- <div class="video_S_time">{{title.begin_time|time('YYYY-MM-DD HH:mm')}}</div> -->
                <div @click="goSet(item)" class="video_S_PZ pointer">配置</div>
                <div class="video_S_Img pointer">
                  <img @click="delateItem(index)" src="@/assets/base_img/video_delate.png" alt="">
                </div>
                <div class="video_S_Img pointer">
                  <img src="@/assets/base_img/移动@2x.png" alt="">
                </div>
              </div>
            </div>
          </draggable>

        </div>
      </div>
    </div>

    <div v-show="btnTypeNum==2" class="imgList">
      <p>
        <pageCss :propCss="form.data.pageCss" @giveCss='giveCss' />
      </p>

    </div>

    <setVideo ref="setVideo" @giveVideo='giveVideo' />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import pageCss from './pageCss.vue'
import setVideo from './setVideo.vue'
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { upload, pageCss, setVideo, draggable },
  name: "shipinhejiForm",
  data() {
    return { btnTypeNum: 1, form: {} };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });

      this.$emit("changeWindow");
    },
    endEventFN(argument) {
      this.chnageVuex();
    },
    changeBtn(num) {
      this.form.data.pageCss.showType = num;
      console.log(this.form);
      this.chnageVuex();
    },
    onChange() {
      this.chnageVuex();
    },
    btnTypeFn(num) {
      console.log(this.form);
      this.btnTypeNum = num;
      this.chnageVuex();
    },
    autoTimerFn(num) {
      this.form.data.borderStyle = num;
      this.chnageVuex();
    },
    uploadIma() {
      this.form.data.logo = require("../../../assets/001.png");
      this.chnageVuex();
    },
    uploadOk(data) {
      this.form.data.beginImg = data.payload.path
      this.chnageVuex();
    },
    uploadOk2(data) {
      this.form.data.doingImg = data.payload.path
      this.chnageVuex();
    },
    uploadOk3(data) {
      this.form.data.endImg = data.payload.path
      this.chnageVuex();
    },
    uploadOk4(data) {
      this.form.data.dateImg = data.payload.path
      this.chnageVuex();
    },
    uploadOk5(data) {
      this.form.data.detailsImg = data.payload.path
      this.chnageVuex();
    },

    uploadOkList(data, index) {
      this.form.data.menuArr[index].image_id = data.payload.id
      this.form.data.menuArr[index].src = data.payload.path
      this.form.data.aaaa = data.payload.id + 'a'
      this.chnageVuex();
    },
    goSet(item) {
      window.open(`/#/videoSet?id=${item.live_id}`);
    },
    delateItem(index) {
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    closeAll(index) {
      this.video_list.splice(index, 1);
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    close(index) {
      if (index == 2) {
        this.form.data.beginImg = ''
      }
      if (index == 3) {
        this.form.data.doingImg = ''
      }
      if (index == 4) {
        this.form.data.endImg = ''
      }
      if (index == 1) {
        this.form.data.dateImg = ''
      }
      if (index == 5) {
        this.form.data.detailsImg = ''
      }
      this.chnageVuex();
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },
    giveVideo(data) {
      this.form.data.menuArr = data
      this.chnageVuex();

    },
    showVideoList() {
      // 1普通直播，2，视频，3议程 4外链
      this.$refs.setVideo.showModal(this.form.data.menuArr, 1);

    }
  },

};
</script>

<style lang="less" scoped>
.DF_Base {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.DF_item {
  width: 84px;
  margin-right: 14px;
}
.DF_item:nth-of-type(3n) {
  margin-right: 0;
}
.DF_item_IMG {
  position: relative;
  border-radius: 4px;
}
.DF_item_check {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.DF_item_title {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  margin: 8px 0 16px 0;
  text-align: center;
}
.tuwen_title {
  padding: 21px;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  padding-bottom: 0;
}
.lineBtn {
  width: 100%;
  button {
    font-weight: 800;
    margin-left: 0 !important;
  }
}
.choiceImg:first-child {
  margin-top: 0;
}
.choiceImg {
  padding: 12px;
  margin-top: 20px;
  min-height: 100px;
  // border: 1px solid #ccc;
  position: relative;
  background: #f9fafc;
  border-radius: 2px;
  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: #ccc;
    display: none;
  }
  p {
    margin-top: 10px !important;
  }
}
.choiceImg:hover {
  .close {
    display: block;
  }
}
.w70 {
  width: 70px;
}
.padding {
  padding: 24px;
}
.check2 {
  border: 1px solid #1890ff !important;
  color: #1890ff;
  i {
    color: #1890ff !important;
  }
}
.lineBtn {
  width: 100%;
  button {
    font-weight: 800;
    margin-left: 0 !important;
    i {
      color: #ccc;
    }
  }
}
.imgList {
  padding: 21px;
  position: relative;
}
.imgList_p {
  display: flex;
  margin-top: 15px;
  line-height: 32px;
  margin-bottom: 25px;
}
.imgList_p span:nth-child(4) {
  margin-right: 2px;
}
.toBig {
  transform: scale(1.2, 1.2);
}
.imgList_title {
  margin-right: 15px;
}
.video_list {
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  margin: 10px 0;
  border: 1px solid #ccc;
  padding: 8px;
  .ietm {
    border: 1px solid #ccc;
    padding: 0 8px;
    margin-bottom: 10px;
    position: relative;
  }
  .ietm:last-child {
    margin-bottom: 0;
  }
}
.list_p {
  display: flex;
  margin-bottom: 12px;
}
.imgBox {
  width: 90px;
}
.list_p span:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.inp_color {
  width: 50px;
}
.video_list:hover {
  .close {
    display: block;
  }
  .close2 {
    display: none;
  }
}
.ietm:hover {
  .close2 {
    display: block;
  }
  .close {
    display: none;
  }
}
.close,
.close2 {
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: #ccc;
  display: none;
}
.list_name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.list_name_title {
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.set_video {
  height: 40px;
  border-radius: 6px;
  border: 1px solid #e5e6eb;
}
.list_name_radio {
  margin: 20px 0 12px 0;
}
.mb16 {
  margin-bottom: 16px;
}
.list_name_data {
  background: #f9fafc;
  border-radius: 2px;
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  padding-bottom: 12px;
}
.list_name_dataItem {
  width: 50%;
  padding: 12px 12px 0 12px;
}
.list_name_ImgUP {
  position: relative;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e5e6eb;
  margin-top: 8px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choiceImg_base_top {
  height: 36px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
}
.choiceImg_base_up {
  width: 96px;
  margin-right: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.choiceImg_base_Noimg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #86909d;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  img {
    margin-right: 4px;
  }
}
.choiceImg_base_Show {
  width: auto;
  max-width: 90px;
  height: 30px;
}
.choiceImg_base_poa {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  min-width: 100%;
  min-height: 40px;
  opacity: 0;
  overflow: hidden;
}

.choiceImg_base_txi {
  flex: 1;
}

.video_S_list {
  margin-bottom: 12px;
  background: #f9fafc;
  border-radius: 2px;
  padding: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  .video_S_title {
    margin-bottom: 8px;
  }
  .video_S_dataBox {
    display: flex;
    height: 20px;
    div {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  }
  .video_S_time {
    font-size: 12px;
    color: #86909d;
    margin-right: 8px;
  }
  .video_S_PZ {
    flex: 1;
    color: #1890ff;
  }
  .video_S_Img {
    margin-right: 12px;
    img {
      width: 16px;
    }
  }
  .video_S_Img {
    img {
      width: 16px;
    }
  }
}
.choiceImg_base_close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  display: none;
  z-index: 4;
}
.list_name_ImgUP:hover {
  .choiceImg_base_close {
    display: block;
  }
}
</style> 