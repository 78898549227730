<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default {
  name: "App",
  data() {
    return { locale: zhCN };
  },
  metaInfo() {
    return {
      title: this.$route.meta.name || "welcome",
      meta: [
        {
          charset: "utf-8",
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },
      ],
    };
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app .ant-input-search-button,
#app .ant-select-selection--single,
#app .ant-input {
  height: 36px;
  font-weight: 400;
  font-size: 14px;
}

#app .ant-select-selection__rendered {
  line-height: 36px;
  font-weight: 400;
  font-size: 14px;
}

#app .ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
  font-weight: 400;
  font-size: 14px;
}

#app .ant-select-lg .ant-select-selection--single,
#app .ant-input-lg {
  height: 40px;
  font-weight: 400;
  font-size: 14px;
}
#app .ant-btn {
  height: 36px;
}

.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 500px;
  height: 510px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}
.add_form_title {
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    cursor: pointer;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px;
  padding-bottom: 0;
}
.add_formBox_name {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  margin-bottom: 10px;
}
.add_formBox_require {
  color: red;
  margin-right: 5px;
}
.add_formBox_item {
  margin-bottom: 20px;
}
.add_formBox_sendBox {
  flex: 1;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.add_formBox_time {
  width: 100%;
}
.add_formBox_inp {
  position: relative;
  .iconfont {
    position: absolute;
    color: #d9d9d9;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}

.add_form_close:hover {
  color: #000;
  font-weight: 400;
}

.add_formBox_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.add_formBox_radio {
  position: relative;
  right: -16px;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
#app .ant-table-align-left {
  padding: 16px 8px;
}
#app .ant-table-thead > tr > th,
#app .ant-table-tbody > tr > td {
  padding: 16px 8px;
}
</style>