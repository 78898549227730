<template>
  <div class="form-work">

    <save-form @cancleMode="cancleMode" :modelFormDialog="modelFormDialog"></save-form>

    <a-row>
      <a-col :span="4" class="form-work-left">
        <div class="form_title">表单组件</div>
        <div class="form_item">
          <draggable v-model="leftChoose" v-bind="dragOptions" :options="{
                sort: false,
                group: { name: 'field', pull: 'clone', put: false }
              }">
            <a-button @click="clickAdd(item)" v-for="item in leftChoose" type="solid" :icon="item.icon" :block="true"
              :key="item.name" style="margin-bottom: 10px">
              {{ item.label }}
            </a-button>
          </draggable>
        </div>
      </a-col>
      <a-col :span="14" class="form-work-center">
        <p class="work_title">
          <a-button class="noShow" @click="showtext(3)" type="link">
            示例
          </a-button>
          <a-button class="noShow" @click="showModel" type="link" icon="eye">
            预览
          </a-button>
          <a-button class="noShow" @click="closeForm" type="link" icon="check">
            保存
          </a-button>
          <!-- <a-button @click="delateAll" type="link" icon="delete">
            清空
          </a-button>  -->

          <!-- 后边按钮用于页面装修表单配置 -->
          <a-button class="closePage" @click="closeNo" type="link" icon="close">
            关闭
          </a-button>
        </p>

        <div class="model_phone">
          <draggable class="model_phone_f" group="field" :list="rightShow" v-bind="dragOptions" @choose="chooseEvent"
            @end="endEvent" @add="addEvent">
            <component v-for="(item, index) in rightShow" :key="index" :is="item.type" :index="index"
              :class="activeIndex === index ? 'component active' : 'component'">

              <a-button @click="delEvent" :defaultOptions="item.options" class="solt delete"
                :type="activeIndex === index ? 'primary' : 'Default'">
                {{item.label}}
                <a-icon style="margin-left:10px !important;" type="close" />
              </a-button>

              <!-- <a-button v-if="activeIndex === index" type="danger" class="delete" :defaultOptions="item.options"
                circle>{{item.label}}</a-button> -->
            </component>
          </draggable>
        </div>

      </a-col>
      <a-col class="form-work-right" :span="6">
        <div class="form_title">组件属性</div>
        <div class="form_item">
          <inpute :index="activeIndex" v-if="type === 'inpute'"></inpute>
          <selecte :index="activeIndex" v-if="type === 'selecte'"></selecte>
          <slider :index="activeIndex" v-if="type === 'slider'"></slider>
          <radio :index="activeIndex" v-if="type === 'radio'"></radio>
          <switche :index="activeIndex" v-if="type === 'switche'"></switche>
          <timePicker :index="activeIndex" v-if="type === 'timepicker'"></timePicker>
          <checkBox :index="activeIndex" v-if="type === 'checkbox'"></checkBox>
          <input-number :index="activeIndex" v-if="type === 'inputnumber'"></input-number>
        </div>
      </a-col>
    </a-row>
    <login ref="showModel"></login>
  </div>
</template>

<script>
import Vue from "vue";
import { geiFormModular } from '@/api/meetApi.js';
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
import saveForm from "@/views/form/SaveForm";
export default {
  props: ['index'],
  components: {
    draggable,
    saveForm
  },
  data() {
    return {
      modelFormDialog: false,
      arrList: [],
      activeTab: "first",
      activeTabLeft: "first",
      dragOptions: { animation: 500 },
      leftChoose: [],
      rightShow: [],
      activeIndex: null,
      type: ""
    };
  },
  created() {
    if (this.$store.state.formwork.formwork[0]) {
      this.rightShow = JSON.parse(JSON.stringify(this.$store.state.formwork.formwork));
    }

    const requireComponent = require.context(
      "@/views/form/formwork",
      false,
      /\.vue$/
    );
    requireComponent.keys().forEach(fileName => {
      let config = requireComponent(fileName);
      let fimeNameChange = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName =
        fimeNameChange.charAt(0).toLowerCase() + fimeNameChange.slice(1);
      Vue.component(componentName, config.default || config);
    });
    const requireComponent1 = require.context(
      "@/components/formwork",
      false,
      /\.vue$/
    );
    requireComponent1.keys().forEach(fileName => {
      let config1 = requireComponent1(fileName);
      let fimeNameChange1 = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName1 =
        fimeNameChange1.charAt(0).toLowerCase() +
        fimeNameChange1.slice(1) +
        "Work";
      Vue.component(componentName1, config1.default || config1);
    });

    this.getMenuList()
  },
  methods: {
    async getMenuList() {
      let dataList = await geiFormModular()
      this.leftChoose = dataList.payload.data 
      // 组件列表全部按钮，暂不开放，使用后端控制
      // {
      //   type: "inputWork",
      //   name: "Inpute",
      //   label: "input框",
      //   icon: "account-book"
      // },
      // {
      //   type: "selectWork",
      //   name: "Selecte",
      //   label: "Select框",
      //   icon: "deployment-unit"
      // },
      // {
      //   type: "sliderWork",
      //   name: "Slider",
      //   label: "Slider滑块",
      //   icon: "setting"
      // },
      // {
      //   type: "radioWork",
      //   name: "Radio",
      //   label: "单选框",
      //   icon: "dashboard"
      // },
      // {
      //   type: "checkBoxWork",
      //   name: "CheckBox",
      //   label: "多选框",
      //   icon: "dashboard"
      // },
      // {
      //   type: "switchWork",
      //   name: "Switche",
      //   label: "开关",
      //   icon: "dashboard"
      // },
      // {
      //   type: "timePickerWork",
      //   name: "TimePicker",
      //   label: "时间选择器",
      //   icon: "calendar"
      // },
      console.log(dataList)
    },
    ...mapActions("formwork", ["SET_FORMWORKFUN"]),
    showOwnProject() {
      this.$router.push("/form/project");
    },
    // 删除组件
    chooseEvent(argument) {
      this.activeIndex = argument.oldIndex;
      this.type = this.rightShow[argument.oldIndex].name.toLowerCase();
    },
    endEvent(argument) {
      this.activeIndex = argument.newIndex;
      let changeObj = JSON.parse(
        JSON.stringify(this.formwork[argument.oldIndex])
      );
      this.SET_FORMWORKFUN({ index: argument.oldIndex, num: 1 });
      let index = argument.newIndex;
      this.SET_FORMWORKFUN({
        index: index,
        num: 0,
        arr: changeObj
      });
    },
    delEvent() {
      this.SET_FORMWORKFUN({ index: this.activeIndex, num: 1 });
      this.rightShow.splice(this.activeIndex, 1);
      if (this.rightShow && this.rightShow[this.activeIndex]) {
        this.type = this.rightShow[this.activeIndex].name.toLowerCase();
      } else {
        this.type = ''
      }
    },
    cancleMode(value) {
      this.modelFormDialog = value;
    },
    addEvent(argument) {
      console.log('addEvent')
      this.activeIndex = argument.newIndex;
      this.type = this.rightShow[argument.newIndex].name.toLowerCase();
      let newObj = JSON.parse(
        JSON.stringify(this.rightShow[argument.newIndex])
      );
      let newOption = {};

      switch (this.type) {
        case "inpute":
          newOption = JSON.parse(JSON.stringify(this.inputOptions));
          break;
        case "selecte":
          newOption = JSON.parse(JSON.stringify(this.selectOptions));
          break;
        case "slider":
          newOption = JSON.parse(JSON.stringify(this.sliderOptions));
          break;
        case "radio":
          newOption = JSON.parse(JSON.stringify(this.radioOptions));
          break;
        case "timepicker":
          newOption = JSON.parse(JSON.stringify(this.timeOptions));
          break;
        case "switche":
          newOption = JSON.parse(JSON.stringify(this.switchOptions));
          break;
        case "checkbox":
          newOption = JSON.parse(JSON.stringify(this.checkBoxOptions));
          break;
      }
      this.$set(newObj, "options", newOption);
      this.SET_FORMWORKFUN({ index: argument.newIndex, num: 0, arr: newObj });
    },
    clickAdd(data) {
      console.log('clickAdd')

      this.activeIndex = this.rightShow.length;
      let newOption = {};
      switch (data.name) {
        case "Inpute":
          this.type = 'inpute'
          newOption = JSON.parse(JSON.stringify(this.inputOptions));
          break;
        case "Selecte":
          this.type = 'selecte'
          newOption = JSON.parse(JSON.stringify(this.selectOptions));
          break;
        case "Slider":
          this.type = 'slider'
          newOption = JSON.parse(JSON.stringify(this.sliderOptions));
          break;
        case "Radio":
          this.type = 'radio'
          newOption = JSON.parse(JSON.stringify(this.radioOptions));
          break;
        case "TimePicker":
          this.type = 'timepicker'
          newOption = JSON.parse(JSON.stringify(this.timeOptions));
          break;
        case "Switche":
          this.type = 'switche'
          newOption = JSON.parse(JSON.stringify(this.switchOptions));
          break;
        case "CheckBox":
          this.type = 'checkbox'
          newOption = JSON.parse(JSON.stringify(this.checkBoxOptions));
          break;
      }

      let adddata = JSON.parse(JSON.stringify(data))

      this.$set(adddata, "options", newOption);
      this.SET_FORMWORKFUN({ index: this.activeIndex, num: 0, arr: adddata });
      this.rightShow.push(adddata)

    },
    showtext() {
      this.$store.state.formwork.formwork = JSON.parse(JSON.stringify(this.workPlace.formBox.data.menuArr))
      this.rightShow = JSON.parse(JSON.stringify(this.workPlace.formBox.data.menuArr))
      this.$refs.showModel.show()
    },
    showModel() {
      if (this.rightShow[0]) {
        return this.$refs.showModel.show()
      }
      this.$message.success('请先选择组件内容！')
    },
    delateAll() {
      this.$store.state.formwork.formwork = []
      this.rightShow = []
      this.type = ''
      this.activeIndex = ''
    },
    closeForm() {
      this.$emit('closeForm')
    },
    closeNo() {
      this.$emit('closeNo')
    }
  },
  computed: {
    ...mapGetters([
      "workPlace",
      "inputOptions",
      "selectOptions",
      "radioOptions",
      "sliderOptions",
      "checkBoxOptions",
      "timeOptions",
      "formwork",
      "switchOptions",
      "inputNumberOptions"
    ])
  }
};
</script>

<style scoped>
.form-work {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.form-work-left,
.form-work-center {
  height: 100%;
}
.form_title {
  padding-top: 20px;
  /* padding-left: 20px; */
  text-align: center;
  margin-bottom: 20px;
}
.form_item {
  height: calc(100% - 61px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.form-work-left {
  padding-right: 20px;
}
.form-work-center {
  padding: 20px;
  min-height: 66vh;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  padding-top: 40px;
  position: relative;
  padding-bottom: 40px;
  /* margin-bottom: 100px; */
}
.form-work-center > div {
  min-height: 55vh;
}
.component {
  margin-bottom: 10px;
  position: relative;
  padding: 20px;
  border: 3px solid transparent !important;
}
.active {
  border: 3px solid #1890ff !important;
  /* background-color: #d3adf7; */
  border-radius: 4px;
  position: relative;
}
:deep(.active .a-form-item__label) {
  color: #ffffff;
}
.delete {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* right: -114px; */
  left: 103%;
  padding: 0 5px;
}
:deep(.el-tabs__content) {
  padding: 10px 20px;
}
.model_phone {
  /* margin: auto; */
  width: 100%;
  /* height: 667px; */
  /* background-color: rgba(0, 0, 0, 0.4); */
  height: 100%;
  min-height: unset !important;
  padding: 20px;
  padding-right: 155px;
  overflow-y: scroll;
  padding-bottom: 40px;
}
.model_phone_f {
  min-height: 99%;
}
.work_title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px !important;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 0;
  text-align: right;
  line-height: 60px;
  background-color: #fff;
  z-index: 99;
}
.closePage {
  display: none;
}
.ant-row {
  height: 100%;
}
.form-work-center {
  height: 100%;
  min-height: unset;
}
.form-work-right {
  height: 100%;
  /* overflow: auto; */
  overflow-y: auto;
}
</style>
