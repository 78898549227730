<template>
  <div class="second_cloudBooth">

    <div :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" class="dataBox">
      <div class="list">

        <div class="noData" v-if='!options.data.menuArr||!options.data.menuArr[0]'>暂无内容</div>

        <div v-for="item in options.data.menuArr" :key="item.id" class="item">
          <div class="item_img pointer">
            <span v-if="!item.icon_path">{{item.title}}</span>
            <p v-if="item.icon_path"><img width="100%" :src="item.icon_path" alt=""></p>
           </div>
        </div>

      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { getVideoList } from '@/api/meetApi.js';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "second_cloudBooth",
  props: ["index", "activeIndex"],
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      this.$forceUpdate()
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.second_cloudBooth.data };
    },
  },

  watch: {
    okForm: {
      handler(newVal, oldVal) {
        this.$forceUpdate()
      },
      deep: true,
    },
  },

  created() {
    console.log(this.options)
    // this.getList()
  },
  methods: {
    async getList() {
      let data = await getVideoList({ meeting_id: this.$route.query.meeting_id })
      this.list = data.payload.data
      console.log(data)
    }

  }
};
</script>

<style lang="less" scoped>
.noData {
  text-align: center;
  color: #888888;
}
.second_cloudBooth {
  display: flex;
  flex-direction: column;
}

.dataBox {
  flex: 1;
  position: relative;
  margin-bottom: 0px;
  .list {  
        .item:last-child {
      margin-bottom: 0;
    }
    .item_s:last-child {
      border-bottom: 0px solid #000;
    }
  }
}

.icon {
  width: 100%;
  text-align: center;
  font-size: 0px;
  margin: 10px 0;
  img {
    width: 45%;
  }
}

.item {
  background-color: #fff;
  margin-bottom: 12px;
}

.item_img {
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  overflow: hidden; 
  border: 1px solid #EECC9D; 
  display: flex;
  align-items: center;
  justify-content:  center;

}
@media screen and (max-width: 768px) {
  .second_cloudBooth {
    display: flex;
    border-radius: 0 0 8px 8px;
    // border-bottom: 1px solid #000;
    // min-height: 100vh;
  }
}

// @media (min-width: 750px) {
//   .icon {
//     top: -19px;
//     img {
//       width: 338px;
//     }
//   }
// }
</style>