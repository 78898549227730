import {
  login
} from '@/api/login';
import router from '@/router/index';
import Cookies from 'vue-cookies';

const state = {
  token: '', //页面刷新时在drag_form\src\permission.js中为其赋值。
  info: {}, //页面刷新时在drag_form\src\permission.js中为其赋值。
  userinfo: {} //页面刷新时在drag_form\src\permission.js中为其赋值。
};

const mutations = {
  set_token: (state, data) => {
    state.token = data.payload.token;
    sessionStorage.setItem('token', JSON.stringify(data.payload.token));
    Cookies.set('token', data.payload.token);
  },
  set_userinfo: (state, data) => {
    state.userinfo = data.payload.userinfo;
    sessionStorage.setItem('userinfo', JSON.stringify(data.payload.userinfo));
    Cookies.set('userinfo', data.payload.userinfo);
  },
  set_state: (state, data) => {
    for (const key in data) {
      state.info[key] = data[key];
    }
  },
  clear_state: state => {
    state.token = '';
    state.userinfo = {};
    sessionStorage.clear();
    Cookies.remove('token');
    Cookies.remove('userinfo');
    router.push('/login');
  },
};

const actions = {
  async login({
    commit
  }, payload) {
    const data = await login(payload);
    await commit('set_token', data);
    await commit('set_userinfo', data);
  },

  async setLogin({
    commit
  }, data) {
    await commit('set_state', data);
  },

  async logOut({
    commit
  }) {
    await commit('clear_state');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};