<template>
  <div>
    <div class="title">
      <span class="meet_title"><img width="24px" src="@/assets/icon_门户列表@2x.png" alt=""> 门户列表</span>
      <div style="flex:1"></div>
      <a-input-search v-model="onSearchDate" class="onSearchBox" placeholder="请输入门户名称搜索" enter-button
        @search="onSearch" />
      <a-button @click="showModal" type="primary"> 创建门户 </a-button>
    </div>

    <div v-if="visible" class="add add_pof">
      <div class="add_form add_pof">
        <div class="add_form_title">
          创建门户
          <a-icon @click="handleCancel" class="add_form_close" type="close" />
        </div>

        <div class="add_formBox">

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>门户名称</div>
            <div class="add_formBox_inp">
              <a-input class="suf_pr72" v-model="ruleForm.meeting_name" placeholder="请输入" :maxLength='100'
                :suffix="ruleForm.meeting_name.length+'/100'" />
            </div>
          </div>

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>开始时间</div>
            <div class="add_formBox_inp">
              <span class="iconfont icon-31shijian"></span>
              <a-date-picker class="add_formBox_time" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="选择开始时间"
                v-model="ruleForm.begin_time" />
            </div>
          </div>

          <div class="add_formBox_item">
            <div class="add_formBox_name"><span class="add_formBox_require">*</span>结束时间</div>
            <div class="add_formBox_inp">
              <span class="iconfont icon-31shijian"></span>
              <a-date-picker class="add_formBox_time" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="选择结束时间"
                v-model="ruleForm.end_time" />
            </div>
          </div>

        </div>

        <div class="add_formBox_sendBox">
          <a-button @click="handleCancel" class="add_formBox_sendBox_qx">取消</a-button>
          <a-button @click="handleOk" type="primary">
            创建
          </a-button>
        </div>

      </div>
    </div>

    <!-- <a-modal title="新增" ok-text="确认" cancel-text="取消" width='600px' destroyOnClose :visible="visible"
      :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="名称">
          <a-input placeholder="会议名称" v-model="ruleForm.meeting_name" type="text" />
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="首页链接">
          <a-input :addon-before="windLink" v-model="ruleForm.custom_link" placeholder="短链接" />
 
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="开始时间">
          <a-date-picker valueFormat='YYYY-MM-DD' placeholder="开始时间" v-model="ruleForm.begin_time" />
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="结束时间">
          <a-date-picker valueFormat='YYYY-MM-DD' placeholder="结束时间" v-model="ruleForm.end_time" />
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="背景图片">
          <div class="img">
            <upload :image='ruleForm.background_img_path' @uploadOk='uploadOk' :showDelate='true'
              @delateDate='delateDate' :height="'120px'">
            </upload>
          </div>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="背景颜色">
          <div class="color">
            <a-input class="inp_color" v-model="ruleForm.background_color" type="color" />
          </div>
        </a-form-model-item>
      </a-form-model> 

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="KV">
          <upload :image='ruleForm.head_img_path' @uploadOk='uploadOk2' :width="'80px'" :height="''"></upload>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="尾图">
          <upload :image='ruleForm.tail_img_path' @uploadOk='uploadOk3' :width="'80px'" :height="''"></upload>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="分享图">
          <upload :image='ruleForm.sharing_img_path' @uploadOk='uploadOk4' :width="'80px'" :height="''"></upload>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="大会简介">
          <a-input v-model="ruleForm.content" type="text" />
        </a-form-model-item>
      </a-form-model>

    </a-modal> -->
  </div>
</template>

<script> 
import moment from "moment";
import upload from '@/components/upload.vue';
import { setMeetList, setViewList } from "@/api/meetApi";
export default {
  components: { upload },
  data() {
    return {
      onSearchDate: '',
      windLink: window.location.origin + '/meeting/',
      visible: false,
      confirmLoading: false,
      ruleForm: {
        "id": "",
        "meeting_name": "",
        "begin_time": "",
        "end_time": "",
        "created_at": "",
        "updated_at": "",
        "content": "",
        "address": "",
        "head_img": "",
        "head_img_path": "",
        "background_img": "",
        "background_img_path": "",
        "background_color": "#ffffff",
        "tail_img": "",
        "tail_img_path": "",
        "custom_link": ''
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    };
  },
  methods: {
    moment,
    onSearch() {
      if (!this.onSearchDate) {
        // return this.$message.error("请输入搜索内容！");
      }
      this.$emit("keyword", this.onSearchDate);
    },
    // uploadOk(data) {
    //   this.ruleForm.background_img = data.payload.id
    //   this.ruleForm.background_img_path = data.payload.path
    //   console.log(data)
    // },
    // uploadOk2(data) {
    //   this.ruleForm.head_img = data.payload.id
    //   this.ruleForm.head_img_path = data.payload.path
    //   console.log(data)
    // },
    // uploadOk3(data) {
    //   this.ruleForm.tail_img = data.payload.id
    //   this.ruleForm.tail_img_path = data.payload.path
    //   console.log(data)
    // },
    // uploadOk4(data) {
    //   this.ruleForm.sharing_img = data.payload.id
    //   this.ruleForm.sharing_img_path = data.payload.path
    //   console.log(data)
    // },
    // async change(data) {
    //   await this.clear();
    //   this.ruleForm = JSON.parse(JSON.stringify(data));
    //   console.log(this.ruleForm)
    //   this.showModal();
    // },
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      if (!this.ruleForm.meeting_name) {
        return this.$message.error("名称必填！");
      }

      this.confirmLoading = true;
      try {
        let data = await setMeetList(this.ruleForm);
        this.$emit("getList", data);
        this.clear();
        this.visible = false;
        this.confirmLoading = false;
        return this.$message.success("创建成功！");
      } catch {
        this.confirmLoading = false;
      }
    },

    handleCancel(e) {
      this.clear();
      this.visible = false;
    },
    clear() {
      for (const key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
    },
    delateDate(data) {
      this.ruleForm.background_img = ''
      this.ruleForm.background_img_path = ''
      console.log(3456)
    },
    sliderOninput(val) {
      let rgb = this.hexToRgb(this.ruleForm.background_color);
      this.ruleForm.textBgc = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + val + ")"
      this.sliderOninputNUM()
      console.log(this.ruleForm.textBgc)
    },
    sliderOninputNUM() {
      let num = this.ruleForm.background_color.slice(-4, -1)
      console.log(num)
      return this.ruleForm.background_color
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  margin-bottom: 24px;
  .onSearchBox {
    :deep(.ant-btn-primary) {
      background: #f5f7fa;
      border: 1px solid #e5e6eb;
      color: #7e8081;
      box-shadow: unset;
    }
    width: 312px;
    margin-right: 24px;
  }
  .meet_title {
    img {
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
    display: flex;
    align-items: center;
  }
  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
    text-align: left;
    font-style: normal;
  }
}
:deep(.ant-calendar-picker) {
  width: 100%;
}
:deep(.ant-calendar-picker-icon) {
  // top: 30%;
}
.inp_color {
  height: 42px;
}
.home_base {
  display: flex;
  position: relative;
  top: 5px;
  .home_base_l {
    flex: 1;
  }
  .home_base_r {
    flex: 1;
  }
}
.windLink {
  border: 0px;
  box-shadow: unset;
}
// .img{
//   width: 120px;
// }

.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 500px;
  height: 380px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform: translate(0%, -20%);
  bottom: 0;
  top: 0;
}
.add_form_title {
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    cursor: pointer;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px;
  padding-bottom: 0;
}
.add_formBox_name {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  margin-bottom: 10px;
}
.add_formBox_require {
  color: red;
  margin-right: 5px;
}
.add_formBox_item {
  margin-bottom: 20px;
}
.add_formBox_sendBox {
  flex: 1;
  // border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.add_formBox_time {
  width: 100%;
}
.add_formBox_inp {
  position: relative;
  .iconfont {
    position: absolute;
    color: #d9d9d9;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}

.add_form_close:hover {
  color: #000;
  font-weight: 600;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
</style>