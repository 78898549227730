<template>
  <div class="layout_left">
    <div class="login">
      <!-- <a-avatar :size="64" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> -->
      <!-- <a-avatar :size="48">A</a-avatar> -->
      <!-- <span class="name">管理员</span> -->
      <img height="100%" src="@/assets/logo/未标题-2副s本.png" alt="">
    </div>
    <a-menu class="a_lw_ul" :open-keys.sync="openKeys" :default-selected-keys="['1']" :default-open-keys="['sub1']"
      mode="inline" theme="dark" :selected-keys="[current]" @click="handleClick" @openChange="onOpenChange">

      <!-- <a-menu-item class="a_lw_li" v-for="item in list" :key="item.path"> 
        <span><img width="14px" :src="item.meta.icon" alt=""></span>
        <span>{{item.meta.name}}</span>
      </a-menu-item> -->

      <template v-for="item in list">

        <a-menu-item class="a_lw_li" v-if="!item.children" :key="item.path">
          <span><img width="14px" :src="item.meta.icon" alt=""></span>
          <span>{{item.meta.name}}</span>
        </a-menu-item>

        <a-sub-menu class="a_lw_ulS" v-if="item.children" :key="item.path">

          <span slot="title">
            <span style="margin-right:16px"><img width="14px" :src="item.meta.icon" alt=""></span>
            <span>{{item.meta.name}}</span>
          </span>

          <a-menu-item class="a_lw_ulS" v-for="j in item.children" :key="j.path">
            <span style="margin-left:6px">{{j.meta.name}}</span>
          </a-menu-item>

        </a-sub-menu>

      </template>

    </a-menu>

    <div @click="showModal" class="a_lw_li logout">
      <!-- <a-icon class="mr10" type="upload" /> -->
      <span><img width="14px" src="@/assets/base_img/icon_退出登录@2x.png" alt=""></span>
      退出
    </div>

    <div style="display:none" class="web_A">
      <a href="https://xyt.xcc.cn/getpcInfo?sn=1803021824112328704&language=CN&certType=8&url=www.cndmcloud.com"
        target="_blank" style="position: relative;display: inline-block;height: 38px;">
        <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed
          src="https://program.xinchacha.com/web/1803021824112328704=www.cndmcloud.com.svg" width="103" height="38"
          type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/" />
      </a>

      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=沪ICP备2022033592号-3">沪ICP备2022033592号-3</a>
    </div>

    <a-modal title="提示" :visible="visible" cancelText='取消' okText='确认' :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel">
      <p>{{ ModalText }}</p>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "layout_left",
  data() {
    return {
      openKeys: ['/videoList'],
      list: this.$router.options.routes[1].children,
      current: this.$route.path || "/",
      ModalText: "确认退出吗？",
      visible: false,
      confirmLoading: false,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lightItem();
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    let layout_left = this.getSession('layout_left');
    if (layout_left) {
      this.current = layout_left;
    }
    this.lightItem();
  },
  methods: {
    handleClick(e) {
      console.log(e);
      this.current = e.key;
      this.setSession('layout_left', this.current)
      if (this.current == 3) return;
      this.$router.push(e.key);
    },
    onOpenChange(openKeys) {
      console.log(openKeys);
      return
    },
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      //
      //
      // 退出请求
      this.confirmLoading = true;
      await this.$store.dispatch("loginInfo/logOut");
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 页面打开时高亮当前菜单
    lightItem() {
      for (let index = 0; index < this.list.length; index++) {
        if (this.$route.path == this.list[index].path) {
          this.current = this.$route.path;
        }
      }
      if (this.$route.path == "/menuList" || this.$route.path == "/workPlace") {
        this.current = "/menu";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.web_A {
  position: absolute;
  text-align: center;
  bottom: 10px;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #8a94a3;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  display: flex;
  flex-direction: column;
  a {
    color: #8a94a3;
  }
}
.layout_left {
  position: fixed;
  left: 0;
  top: 0px;z-index: 2;
  width: 180px;
  bottom: 0;
  background-color: #101f42;
}
.login {
  padding: 18px 0;
  height: 70px;
  text-align: center;
  color: #fff;
  .name {
    position: relative;
    top: 6px;
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
  }
}
.a_lw_li {
  border-radius: 2px;
  padding-left: 24px !important;
  display: flex;
}
.a_lw_ul {
  width: 180px;
  padding: 0 6px;
  background-color: #101f42;
}
.a_lw_ulS {
  background-color: #101f42;
  border-radius: 2px !important;
}
.ant-menu-item-selected {
  // background: #465476 !important;
}
.logout {
  height: 40px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.65);
  padding-left: 30px !important;
}
.logout:hover {
  color: #fff;
}
.mr10 {
  margin-right: 10px;
}
.a_lw_li span {
  height: 100%;
  width: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 14px;
  line-height: unset;
}
</style> 
<style>
.a_lw_ulS .ant-menu {
  box-shadow: unset !important;
  background-color: #101f42 !important;
}
</style>