<template>
  <div class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}2345678765432</div>
    <div class="baseFormBox_data">
      <el-select v-model="form.value" :maxlength="options.multiple" :disabled="options.disabled"
        :clearable="options.clearable" :filterable="options.filterable" :placeholder="options.placeholder"
        :multiple="options.multiple">
        <el-option v-for="item in options.optionsArray" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getSelectOptionData } from "@/api/form.js";
export default {
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"]),
    options() { 
      return this.formwork[this.index] ? this.formwork[this.index].options : {};
    },
    optionsOptions: {
      get() {
        return this.options.optionsZd;
      }
    },
    optionsOptionsMul: {
      get() {
        return this.options.multiple;
      }
    },
    optionsSelectType: {
      get() {
        return this.options.optionsType;
      }
    },
    optionRules: {
      get() {
        let ruleArray = [];
        if (this.options.require) {
          ruleArray.splice(0, 0, {
            required: true,
            message: this.options.requireMsg
              ? this.options.requireMsg
              : "该内容不能为空",
            trigger: "change"
          });
        }
        return ruleArray;
      }
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"])
  },
  watch: {
    optionRules() {
      this.keyValue += 1;
    },
    optionsOptionsMul() {
      this.keyValue += 1;
    },
    optionsSelectType() {
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: "optionsArray",
        value: []
      });
      this.SET_FORMWORKOBHFUN({
        index: this.index,
        label: "optionsZd",
        value: ""
      });
    },
    async optionsOptions() {
      if (!this.options.optionsType && this.options.optionsArray.length === 0) {
        let optionsArr = await getSelectOptionData(this.options.optionsZd);
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "optionsArray",
          value: optionsArr
        });
      } else if (this.options.optionsType) {
        this.SET_FORMWORKOBHFUN({
          index: this.index,
          label: "optionsArray",
          value: []
        });
      }
    }
  }
};
</script> 