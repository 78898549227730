<template>
  <div class="article">
    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">样式调整</span></span>
    </p>
    <Editor v-show="btnTypeNum==1" :form='form' @input='input' :key="index" />
    <div v-show="btnTypeNum==2" class="styleBox imgList">
      <pageCss :propCss="form.data.pageCss" @giveCss='giveCss' />
    </div>
  </div>
</template>

<script>

import pageCss from './pageCss.vue'
import Editor from "@/components/Editor";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "articleForm",
  props: ["index"],
  components: {
    Editor, pageCss
  },
  data() {
    return {
      btnTypeNum: 1,
      form: {},
    };
  },
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),

    btnTypeFn(num) {
      this.btnTypeNum = num;
      this.chnageVuex();
    },
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });

      this.$emit("changeWindow");
    },
    input(newValue) {
      this.form.data.desc = newValue

      this.chnageVuex();
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  max-width: 100%;
  height: calc(100% - 50px);
  min-height: 100px;
}
.imgList {
  padding: 24px;
  position: relative;
}
.inp_color {
  width: 50px;
}
.ant-input-number {
  width: 60px;
}
</style>