<template>
  <div v-if="options&&options.typeof=='日期时间选择器'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-date-picker v-model="formwork[index].options.value"
        :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" :disabled="options.disabled" v-show="oneSelect"
        @change="changeTime" :format="options.valueType" :value-format="options.valueType" :type="oneSelect"
        :placeholder="
            oneSelect === 'year'
              ? '请选择年份'
              : oneSelect === 'month'
              ? '请选择月份'
              : '请选择时间日期'
          ">
      </a-date-picker>
      <a-date-picker v-model="formwork[index].options.value"
        :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" :disabled="options.disabled" @change="changeTime"
        v-show="rangeSelect" :type="rangeSelect" :start-placeholder="
            rangeSelect === 'daterange' ? '开始日期' : '开始月份'
          " :end-placeholder="
            rangeSelect === 'monthrange' ? '开始月份' : '结束月份'
          ">
      </a-date-picker>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      keyValue: 0,
      oneSelect: "date",
      rangeSelect: ""
    };
  },
  props: ["index"],
  methods: {
    moment,
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"]),
    changeTime(newval) {
      console.log(newval);
    }
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() {
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.timeOptions };
    },
    optionRules: {
      get() {
        let ruleArray = [];
        if (this.options.require) {
          ruleArray.splice(0, 0, {
            required: true,
            message: this.options.requireMsg
              ? this.options.requireMsg
              : "该内容不能为空",
            trigger: "change"
          });
        }
        return ruleArray;
      }
    },
    optionsSelectType: {
      get() {
        return this.options.optionsType;
      }
    },
    timeType: {
      get() {
        return this.options.timeType;
      }
    }
  },
  watch: {
    optionRules() {
      this.keyValue += 1;
    },
    timeType(newval) {
      if (this.options.timeType.indexOf("range") !== -1) {
        this.rangeSelect = newval;
        this.oneSelect = "";
      } else {
        this.rangeSelect = "";
        this.oneSelect = newval;
      }
    }
  }
};
</script> 
<style >
.ant-calendar-picker {
  width: 100%;
}
</style>