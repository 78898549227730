<template>
  <div v-if="options&&options.typeof=='input'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-input :type="options.type" v-model="formwork[index].options.value" :disabled="options.disabled"
        :prefixIcon="options.prefixIcon" :suffixIcon="options.suffixIcon" :placeholder="options.placeholder">
        <template v-if="options.slot !== ''" slot="append">
          {{ options.slot }}
        </template>
      </a-input>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["index"],
  data() {
    return {
      form: {},
      keyValue: 0
    };
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() {  
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.inputOptions };
    },
  },
};
</script> 