<template>
  <div class="tuwen">
    <div class="tuwen_title">
      <p>
        <span>选择模板</span>
      </p>
      <div class="DF_Base">
        <div class="DF_item">
          <div :class="{check2: form.data.showType==1}" @click="changeBtn(1)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/等分 (3).png" alt="">
            <img class="DF_item_check" v-if="form.data.showType==1" width="20px" src="@/assets/auto_img/等分 (4).png"
              alt="">
          </div>
          <div class="DF_item_title">一行四一个</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.showType==2}" @click="changeBtn(2)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/等分 (5).png" alt="">
            <img class="DF_item_check" v-if="form.data.showType==2" width="20px" src="@/assets/auto_img/等分 (4).png"
              alt="">
          </div>
          <div class="DF_item_title">一行二个</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.showType==3}" @click="changeBtn(3)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/等分 (6).png" alt="">
            <img class="DF_item_check" v-if="form.data.showType==3" width="20px" src="@/assets/auto_img/等分 (4).png"
              alt="">
          </div>
          <div class="DF_item_title">一行三个</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.showType==4}" @click="changeBtn(4)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/等分 (1).png" alt="">
            <img class="DF_item_check" v-if="form.data.showType==4" width="20px" src="@/assets/auto_img/等分 (4).png"
              alt="">
          </div>
          <div class="DF_item_title">一行四个</div>
        </div>
        <div class="DF_item">
          <div :class="{check2: form.data.showType==5}" @click="changeBtn(5)" class="DF_item_IMG pointer">
            <img width="100%" src="@/assets/auto_img/等分 (2).png" alt="">
            <img class="DF_item_check" v-if="form.data.showType==5" width="20px" src="@/assets/auto_img/等分 (4).png"
              alt="">
          </div>
          <div class="DF_item_title">一行五个</div>
        </div>
      </div>
    </div>

    <p class="swiperForm_menu pointer">
      <span @click="btnTypeFn(1)"><span :class="{check: btnTypeNum==1}">内容设置</span></span>
      <span @click="btnTypeFn(2)"><span :class="{check: btnTypeNum==2}">样式调整</span></span>
    </p>

    <div v-show="btnTypeNum==1" class="imgList">

      <div v-for="(item,index) in form.data.menuArr" :key="index" class="choiceImg_base">
        <div @click="close(item,index)" class="choiceImg_base_close pointer">
          <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
        </div>
        <div class="choiceImg_base_top">

          <div @click="imgDelate(index)" class="pointer choiceImg_base_imgDelate">
            <img width="100%" height="100%" src="@/assets/auto_img/icon_delete@2x.png" alt="">
          </div>

          <div class="choiceImg_base_up">

            <span v-if="!item.src" class="choiceImg_base_Noimg">
              <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">
              添加图片
            </span>

            <img class="choiceImg_base_Show" v-if="item.src" :src="item.src" alt="">

            <upload class="choiceImg_base_poa" @uploadOk='uploadOk($event, index)' :height="'19px'" :width='"auto"'
              :image='item.src' />

          </div>

          <div class="choiceImg_base_txi">
            <a-input style="height:100%" @change="chnageVuex" v-model="item.name" placeholder="输入标题（后台使用）" />
          </div>

        </div>
        
        <p class="set_jump">
          <span>链接</span>
          <a-radio-group v-model="item.is_outside_url" :default-value="1">
            <a-radio :value="1">
              自定义
            </a-radio>
            <a-radio :value="2">
              内部链接
            </a-radio>
          </a-radio-group>
        </p>

        <div class="set_jump_form">
          <div class="set_jump_my" v-show="item.is_outside_url==1">
            <img v-if="item.url" @click="clearItemUrl(item)" src="@/assets/auto_img/删除_delete@2x.png" alt="">

            <a-popover v-if="item.url" placement="topLeft" title="打开链接">
              <template slot="content">
                <div style="width:150px;" class="text"> <a :href="item.url" target="_blank"
                    rel="noopener noreferrer">{{item.url}}</a>
                </div>
              </template>
              <a-textarea @change="chnageIs_outside_url(index)" v-model="item.url" placeholder="请输入自定义链接"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
            </a-popover>

            <a-textarea v-if="!item.url" @change="chnageIs_outside_url(index)" v-model="item.url" placeholder="请输入自定义链接"
              :auto-size="{ minRows: 2, maxRows: 6 }" />

          </div>
          <div class="set_jump_other" v-show="item.is_outside_url==2">

            <a-popover v-if="item.meeting_view_id" placement="topLeft" title="打开链接">
              <template slot="content">
                <div style="width:150px;" class="text"> <a :href="item.url" target="_blank"
                    rel="noopener noreferrer">{{item.url}}</a>
                </div>
              </template>
              <div class="set_jump_btn pointer" @click="setJumpFn(index)" type="link">
                <span class="text" v-if="item.next_meeting_view_title">{{item.next_meeting_view_title||item.url}}</span>
                <span v-if="!item.next_meeting_view_title">选择链接</span>
              </div>
            </a-popover>

            <div v-if="!item.meeting_view_id" class="set_jump_btn pointer" @click="setJumpFn(index)" type="link">
              <span class="text" v-if="item.next_meeting_view_title">{{item.next_meeting_view_title||item.url}}</span>
              <span v-if="!item.next_meeting_view_title">选择链接</span>
            </div>

          </div>
        </div>

      </div>

      <a-button size="large" class="addBanner" @click="addList" block type="link">
        添加图文导航
      </a-button>

    </div>

    <div v-show="btnTypeNum==2" class="cssChange">

      <pageCss :propCss="form.data.pageCss" @giveCss='giveCss'></pageCss>

    </div>

    <setJump @jumpForm='jumpForm' ref="setJump" />
  </div>
</template>

<script>
import pageCss from './pageCss.vue';
import setJump from "./setJump.vue";
import jumpTo from "./jumpTo.vue";
import upload from "@/components/upload.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { pageCss, upload, setJump, jumpTo },
  name: "tuwendaohang",
  data() {
    return {
      setJumpNum: '',
      num: 3,
      btnTypeNum: 1,
      form: {}
    };
  },
  props: ["index"],
  created() {
    this.form = {
      ...(this.okForm[this.index]
        ? JSON.parse(JSON.stringify(this.okForm[this.index].options))
        : {}),
    };
    console.log(this.form);
  },
  computed: {
    ...mapGetters(["okForm"]),
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    changeBtn(num) {
      this.form.data.showType = num;
      this.chnageVuex();
    },
    addList() {
      this.form.data.menuArr.push({
        blank: true,
        name: "",
        is_outside_url: 1,
        next_meeting_view_title: "",
        src: "https://www.cndmcloud.com/storage/setmeet/2024-05/mlR2K38IKfZ1vrxidKdMnm1R7ezNajEVsORP3E6r.webp",
        url: "",
      });
      this.chnageVuex();
    },

    btnTypeFn(num) {
      this.btnTypeNum = num;
      this.chnageVuex();
    },
    paginationStyleFn(num) {
      this.form.data.navStyle = num;
      this.chnageVuex();
    },
    autoTimerFn(num) {
      this.form.data.borderStyle = num;
      this.chnageVuex();
    },
    close(item, index) {
      this.form.data.menuArr.splice(index, 1);
      this.chnageVuex();
    },
    imgDelate(index) {
      this.form.data.menuArr[index].image_id = ''
      this.form.data.menuArr[index].src = ''
      console.log(this.form.data.menuArr[index])
      this.chnageVuex();
    },
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });

      this.$emit("changeWindow");
    },
    uploadOk(data, index) {
      this.form.data.menuArr[index].src = data.payload.path
      this.form.data.menuArr[index].image_id = data.payload.id;
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.menuArr[Number(this.setJumpNum)].meeting_view_id = data.meeting_view_id
      this.form.data.menuArr[Number(this.setJumpNum)].url = data.url
      if (data.next_meeting_view_title) {
        this.form.data.menuArr[Number(this.setJumpNum)].next_meeting_view_title = data.next_meeting_view_title
      }
      this.setJumpNum = ''
      console.log(this.form.data.menuArr[Number(this.setJumpNum)]);
      this.chnageVuex();
    },
    setJumpFn(index) {
      this.setJumpNum = index
      this.$refs.setJump.showModal(this.form.data.menuArr[Number(this.setJumpNum)]);
    },
    sliderOninput(val) {
      let rgb = this.hexToRgb(this.form.data.bgc);
      this.form.data.textBgc = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + val + ")"
      console.log(this.form.data.textBgc)
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    giveCss(data) {
      this.form.data.pageCss = data;
      this.chnageVuex();
    },

    clearItemUrl(item) {
      item.url = ''
      item.next_meeting_view_title = ''
      item.meeting_view_id = ''
      this.chnageVuex();
    },
    jumpForm(data) {
      this.form.data.menuArr[Number(this.setJumpNum)].next_meeting_view_title = data.next_meeting_view_title
      this.form.data.menuArr[Number(this.setJumpNum)].meeting_view_id = data.meeting_view_id
      this.form.data.menuArr[Number(this.setJumpNum)].url = 'https://www.cndmcloud.com/home/#/?view_id=' + data.meeting_view_id
      this.setJumpNum = ''
      this.chnageVuex();
    },
    chnageIs_outside_url(index) {
      this.form.data.menuArr[index].next_meeting_view_title = ''
      this.form.data.menuArr[index].meeting_view_id = ''
      this.chnageVuex();
    },
    setJumpFn(index) {
      this.setJumpNum = index
      this.$refs.setJump.showModal(this.form.data.menuArr[Number(this.setJumpNum)]);
    },
  },
};
</script>

<style lang="less" scoped>
.choiceImg_base {
  position: relative;
  background: #f9fafc;
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 20px;
}
.choiceImg_base_top {
  height: 36px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
}
.choiceImg_base_up {
  width: 96px;
  margin-right: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.choiceImg_base_Noimg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #86909d;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  img {
    margin-right: 4px;
  }
}
.choiceImg_base_Show {
  width: auto;
  max-width: 90px;
  height: 30px;
}
.choiceImg_base_txi {
  flex: 1;
}
.set_jump_my {
  position: relative;
  textarea {
    padding-right: 20px;
    text-align: justify;
  }
  img {
    z-index: 2;
    position: absolute;
    width: 16px;
    right: 8px;
    bottom: 0;
    top: 0;
    margin: auto;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }
}
.choiceImg_base_poa {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.choiceImg_base_close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  display: none;
}
.choiceImg_base_up:hover {
  .choiceImg_base_imgDelate {
    display: block;
  }
}

.choiceImg_base_imgDelate {
  position: absolute;
  top: -8px;
  width: 16px;
  height: 16px;
  display: none;
  left: 83px;
  z-index: 2;
  display: none;
}

.choiceImg_base:hover {
  .choiceImg_base_close {
    display: block;
  }
}

.choiceImg_base_top:hover {
  .choiceImg_base_imgDelate {
    display: block;
  }
}
.set_jump {
  display: flex;
  justify-content: space-between;
  .ant-radio-group {
    position: relative;
    right: -12px;
  }
}
.set_jump_form {
  margin-top: 10px;
}
.set_jump_btn {
  height: 36px;
  background: #f8f9fc;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DF_Base {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.DF_item {
  width: 84px;
  margin-right: 14px;
}
.DF_item:nth-of-type(3n) {
  margin-right: 0;
}
.DF_item_IMG {
  position: relative;
  border-radius: 4px;
}
.DF_item_check {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.DF_item_title {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  margin: 8px 0 16px 0;
  text-align: center;
}
.check2 {
  border: 1px solid #1890ff !important;
  color: #1890ff;
}
.tuwen_title {
  padding: 21px;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-style: normal;
  padding-bottom: 0;
}
.lineBtn {
  width: 100%;
  button {
    font-weight: 800;
    margin-left: 0 !important;
  }
}
.imgList {
  padding: 21px;
}
.choiceImg:first-child {
  margin-top: 0;
}
.choiceImg {
  padding: 12px;
  margin-top: 20px;
  min-height: 100px;
  // border: 1px solid #ccc;
  position: relative;
  background: #f9fafc;
  border-radius: 2px;
  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: #ccc;
    display: none;
  }
  p {
    margin-top: 10px !important;
  }
}
.choiceImg:hover {
  .close {
    display: block;
  }
}
.addBanner {
  margin-top: 20px;
  border: 1px dashed #1890ff;
}
.cssChange {
  padding: 24px;
  p {
    margin-bottom: 15px !important;
    span {
      // margin-right: 15px;
    }
  }
}
.cssChange_check {
  background-color: #1890ff !important;
  color: white;
}
.choiceImg_up {
  display: flex;
}
.choiceImg_up_left {
  width: 96px;
  height: 36px;
}
.choiceImg_up_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 0 !important;
    display: flex;
    button {
      padding-left: 0;
    }
    input {
      flex: 1;
    }
    span {
      margin-left: 12px;
      width: 42px;
      line-height: 32px;
    }
  }
}
.attcion {
  display: block;
  font-size: 12px;
  color: #ccc;
}
.inp_color {
  width: 50px;
}  
</style> 
 