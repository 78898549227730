import Vue from 'vue';
import store from '@/store';
/**
 * 存入localhost
 * @param {string} name
 * @param {*} data
 */
Vue.prototype.setLocalhost = function (name, data) {
  localStorage.setItem(name, JSON.stringify(data));
};

/**
 * 取出localhost
 * @param {string} name
 */
Vue.prototype.getLocalhost = function (name) {
  return JSON.parse(localStorage.getItem(name));
};

/**
 * 存入session
 * @param {string} name
 * @param {*} data
 */
Vue.prototype.setSession = function (name, data) {
  sessionStorage.setItem(name, JSON.stringify(data));
};

/**
 * 取出session
 * @param {string} name
 */
Vue.prototype.getSession = function (name) {
  return JSON.parse(sessionStorage.getItem(name));
};

/**
 * 删除本地储存
 * @param {string} name
 */
Vue.prototype.remove = function (name) {
  localStorage.removeItem(name);
  sessionStorage.removeItem(name);
  // localStorage.clear();   // 全部清除
};

/**
 * 时间戳转日期
 * @param {string} name
 */
Vue.prototype.timeStamp = function (name) {
  return new Date(parseInt(name) * 1000);
};

/**
 * 日期转时间戳
 * @param {string} name
 */
Vue.prototype.stampTime = function (name) {
  return Date.parse(name) / 1000;
};

/**
 * 设置顶部路径导航
 * @param {string} data
 */
Vue.prototype.SET_WEB_ROUTER = function (data) {
  store.state.web_router.web_router = data
};

export function setSession(name, data) {
  sessionStorage.setItem(name, JSON.stringify(data));
}

export function getSession(name) {
  return JSON.parse(sessionStorage.getItem(name));
}