<template>
  <div :key="index" class="jiugonggeBox">
    <div v-if="v_if" ref="thanks" class="thanks">
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "jiugonggeBox",
  props: ["index"],
  data() {
    return {
      v_if: true,
    };
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.roll.data };
    },
  },
  created() { },
  mounted() {
    this.setImg();
  },
  methods: {
    setImg() {
      console.log(this.options.data.logo);
      this.$refs.thanks.style.background =
        "url(" + this.options.data.logo + ") repeat-x 0 0";
      this.$refs.thanks.style.backgroundSize = "auto 34px";
    },
  },
  //  监听okForm,处理数据变化时不刷新以及刷新异常问题
  watch: {
    okForm: {
      handler(newVal, oldVal) {
        var _this = this;
        _this.v_if = false;
        setTimeout(() => {
          _this.v_if = true;
          _this.$nextTick(() => {
            _this.setImg();
          });
        }, 100);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.thanks {
  padding-top: 2px;
  padding-bottom: 2px;
  height: 34px;
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  text-align: center;
  background-color: #d6e3f8;
  animation: suhbar 16s infinite linear;
  // background: url("../../assets/logo.png") repeat-x 0 0;
  // background-size: auto 34px;
  // margin-bottom: 2px;
}
@keyframes suhbar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 730px 0;
  }
}
</style>