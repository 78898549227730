<template>
  <div ref="masklayer">
    <a-row v-if="options.data.showType==1" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" type='flex'>
      <a-col :style="'padding-bottom:' + options.data.pageCss.page_css_interval + 'px'" class="iconBox list1"
        v-for="(item,index) in options.data.menuArr" :key="index" flex='100%'>
        <div class="list_img">
          <img :src="item.src" alt="">
        </div>
      </a-col>
    </a-row>

    <!-- 一行两个，且当列表有两项以上时 -->
    <a-row v-if="options.data.showType==2&&options.data.menuArr.length!=2" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" type='flex'>
      <a-col :style="'padding:' + options.data.pageCss.page_css_interval + 'px'" class="iconBox list2"
        v-for="(item,index) in options.data.menuArr" :key="index" flex='50%'>
        <div class="list_img">
          <img :src="item.src" alt="">
        </div>
      </a-col>
    </a-row>

    <!-- 一行两个，且当列表只有两项时，上下不设置间隔 -->
    <a-row v-if="options.data.showType==2&&options.data.menuArr.length==2" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" type='flex'>
      <a-col :style="'padding:0 ' + options.data.pageCss.page_css_interval + 'px'" class="iconBox list2"
        v-for="(item,index) in options.data.menuArr" :key="index" flex='50%'>
        <div class="list_img">
          <img :src="item.src" alt="">
        </div>
      </a-col>
    </a-row>

    <a-row v-if="options.data.showType==3" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" type='flex'>
      <a-col :style="'padding:' + options.data.pageCss.page_css_interval + 'px'" class="iconBox list3"
        v-for="(item,index) in options.data.menuArr" :key="index" flex='33.33333%'>
        <div class="list_img">
          <img :src="item.src" alt="">
        </div>
      </a-col>
    </a-row>

    <a-row v-if="options.data.showType==4" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" type='flex'>
      <a-col :style="'padding:' + options.data.pageCss.page_css_interval + 'px'" class="iconBox list4"
        v-for="(item,index) in options.data.menuArr" :key="index" flex='25%'>
        <div class="list_img">
          <img :src="item.src" alt="">
        </div>
      </a-col>
    </a-row>

    <a-row v-if="options.data.showType==5" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" type='flex'>
      <a-col :style="'padding:' + options.data.pageCss.page_css_interval + 'px'" class="iconBox list4"
        v-for="(item,index) in options.data.menuArr" :key="index" flex='20%'>
        <div class="list_img">
          <img :src="item.src" alt="">
        </div>
      </a-col>
    </a-row>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "tuwendaohangBox",
  props: ["index"],
  data() {
    return {
      borderStyle: "",
      navStyle: "",
    };
  },
  created() {
    this.borderStyle = this.options.data.borderStyle;
    this.navStyle = this.options.data.navStyle;
  },
  computed: {
    ...mapGetters(["workPlace"]),
    ...mapGetters(["okForm"]),
    options() {
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.tuwendaohang.data };
    },
  },
  mounted() {
    this.changeCSS()
  },
  watch: {
    okForm: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.borderStyle = this.options.data.borderStyle;
          this.navStyle = this.options.data.navStyle;
          this.changeCSS()
        });
      },
      deep: true,
    },
  },
  methods: {
    changeCSS() {
      return
      this.$refs.masklayer.style.cssText = `border-radius:${this.options.data.borderReduis}px;
                                    background:${this.options.data.textBgc || this.options.data.bgc};
                                    padding:${this.options.data.paddingA}px ${this.options.data.paddingB}px ${this.options.data.paddingC}px ${this.options.data.paddingD}px; 
                                    margin:${this.options.data.marginA}px ${this.options.data.marginB}px ${this.options.data.marginC}px ${this.options.data.marginD}px;`
    }
  }
};
</script>

<style lang="less" scoped>
.article {
  max-width: 100%;
  min-height: 100px;
  background-color: #fff;
}
.article_html {
  width: 100%;
  overflow: auto;
  img {
    width: 100%;
  }
}
.iconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  // padding: 10px;
}
.round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
}
.round2 {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 5px;
  overflow: hidden;
}
.round3 {
  width: 50px;
  margin-bottom: 5px;
  height: 50px;
}
.list_img {
  display: flex;
  width: 100%;
  img {
    font-size: 0px;
    height: 100%;
    width: 100%;
  }
}
.list1:last-child {
  padding-bottom: 0 !important;
}
// .list2{
//   padding-top: 0 !important;
// }
.list2:nth-of-type(even) {
  padding-right: 0 !important;
}
.list2:nth-of-type(odd) {
  padding-left: 0 !important;
}
// .iconBox:last-child{
//   padding-bottom: 0 !important;
// }
</style>