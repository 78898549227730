import Vue from 'vue';
import moment from 'moment';
// 定义时间处理过滤器
Vue.filter('time', (input, type) => {
  if (!input) {
    return false;
  }

  input=Number(input) 
  
  input = input * 1000;
  if (type) {
    return moment(input).format(type);
  }
  return moment(input).format('YYYY-MM-DD');
});
