<template>
  <div v-if="options&&options.typeof=='多选框'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">
      <a-checkbox-group v-model="formwork[index].options.value">
        <a-checkbox v-for="item in options.optionsArray" :key="'item'+item" :value="''+item">{{
            item
          }}</a-checkbox>
      </a-checkbox-group>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getSelectOptionData } from "@/api/form.js";
export default {
  data() {
    return {
      form: {},
      keyValue: 0,
      checkedCities: []
    };
  },
  props: ["index"],
  computed: {
    ...mapGetters(["formwork"]),
    options() {
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.checkBoxOptions };
    },
    optionRules: {
      get() {
        let ruleArray = [];
        if (this.options.require) {
          ruleArray.splice(0, 0, {
            required: true,
            message: this.options.requireMsg
              ? this.options.requireMsg
              : "该内容不能为空",
            trigger: "change"
          });
        }
        return ruleArray;
      }
    }
  },
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"])
  },
  watch: {

  }
};
</script> 